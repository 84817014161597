import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss']
})
export class LoggingComponent implements OnInit {

  userLDapResonseRoles: any;
  permissions= [];
  constructor(private location: Location,private commonService: CommonService,private httpService: HttpService, private spinner: NgxSpinnerService,private router : Router) {
    /*
      this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
      if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
       ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
      ) 
      {
      }else{
        if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
        ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
        ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
        ){  
          this.router.navigate(['/repair']);
        }else{
          this.router.navigate(['/common']);
        }
      }
    */
      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['logs'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.location.back();
      }
      
      if (this.httpService.loginCheckSession()) { }
      else {
          this.httpService.showSessionError();
          
      }
  }
  
  currentPage:number = 1;
  size:number = 10;
  logs:any =[];
  searchText:string='';
  filteredLogs:any = [];
  stopIncrement:number = 0;

  ngOnInit() {
    this.fetchLogs();
  }

  fetchLogs(){
    this.searchText = '';
    this.spinner.show();
    let offset = (this.currentPage - 1) * this.size;
    this.httpService.getRequest('logging/v1/fetch?offset='+ offset +"&total="+ this.size).subscribe(
      res => {
        this.logs = res['items'];
        this.filteredLogs = this.logs;
        this.stopIncrement = 0;
        if(this.logs.length == 0 && this.currentPage > 1){
          this.stopIncrement = 1;
          this.currentPage--;
        }
        this.spinner.hide();
      },
      err => {
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }

  setPage(page){
    this.currentPage = page;
    this.fetchLogs();
  }

  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }


  filterLogs(){
    if(this.searchText == undefined || this.searchText == ''){
      this.filteredLogs = this.logs;
    }
    var self= this;
    this.filteredLogs = this.logs.filter(function(obj : any[]){
      return self.filterObject(obj, self.searchText, self.filterObject);
    });
  }

  filterObject(object, searchText, func){
    return Object.keys(object).some(function(k){
        //bypass keys if not applicable for filter
        if(k == 'id' || k == 'quantity' || k == 'requestNo'){
          return false;
        }
        if(typeof object[k] == 'string'){
          return object[k].toLowerCase().includes(searchText.toLowerCase());
        } else if(typeof object[k] == 'number'){
          return  object[k].toString().toLowerCase().includes(searchText.toLowerCase());
        } else if(typeof object[k] == 'object'){
          return func(object[k], searchText, func);
        }
    });
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    // this.dataSource.filter = filterValue;
  }
}
