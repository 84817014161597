import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'app/shared/services/http.service';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {
  userLDapResonseRoles: any;

  constructor(private router: Router, private httpService: HttpService) { 
    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));     
    // if(this.userLDapResonseRoles.rolesMap.code.includes('SP')) 
    // {
    // }else{
      
    //   if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
    //   )
    //   {  
    //     this.router.navigate(['/repair']);
    //   }else{
    //       localStorage.clear();
    //       this.router.navigate(['/login']);
    //     }
    //     }
    
    if (this.httpService.loginCheckSession()) { }
    else {
      this.httpService.showSessionError();
    }
  }

  ngOnInit() {
  }

}
