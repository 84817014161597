import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { APPCONFIG } from '../config';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { CompanyDialog } from 'app/dialog/dialog.component';
import { PermissionService } from 'app/shared/services/permission.service';

declare var TimelineMax: any;
declare var Linear: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public unlockUrl = APPCONFIG.ESRP_URL + '/' + '#/unlock-account';
  public forgetUrl = APPCONFIG.ESRP_URL + '/' + '#/forgot-password';


  [x: string]: any;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  user: any = {};
  showErr: boolean = false;
  loginSuccess: boolean = false;
  durationInSeconds = 5;
  loader: boolean = false;
  hide = true;
  loginData:any;
  loginResponse:any;
  constructor(private _snackBar: MatSnackBar, public httpService: HttpService, public router: Router, public dialog: MatDialog,
    private permissionsService:PermissionService) {
      console.log( APPCONFIG.Region)
    if (localStorage.getItem('loginSessionExit') && localStorage.getItem('UserRole')) {
        if (localStorage.getItem('UserRole') == 'TECH' || localStorage.getItem('UserRole') == 'BA') {
          this.router.navigate(['repair']);
        }
        if (localStorage.getItem('UserRole') == 'SA' && localStorage.getItem('companyId')) {   //condition for SA 
          this.router.navigate(['dashboard']);
        }
        //  else if(localStorage.getItem('UserRole')=='SA'||localStorage.getItem('UserRole')=='BA'){
        //   this.router.navigate(['trays']);
        //  }
        else if (localStorage.getItem('UserRole') == 'SP' ) {
          this.router.navigate(['checkin']);
        } else {
          // this.router.navigate(['/common']);
        }
    } else {
      localStorage.clear();
    }

  }

  ngOnInit() {
    setTimeout(() => {
      this.scriptInit();
    }, 10);
    this.user.language = "EN";

  }

  // ($("#isa-allready-slider") as any).on
  scriptInit() {
    ($(document) as any).ready(function () {
      function spinGlobe() {
        var tmax_tl = new TimelineMax({
          delay: 0.1675,
          repeat: -1
        });
        var globe_continents = [
          $('#globe #middle g path'),
          $('#globe #left g path')
        ];
        var globe_speed = 2;
        var map_from = {
          x: 0
        };
        var map_to = {
          x: 150,
          ease: Linear.easeOut
        };
        tmax_tl.fromTo(globe_continents, globe_speed, map_from, map_to, 0);
        return tmax_tl;
      }
      spinGlobe();

    });

    ($('.language') as any).selectpicker();

    ($('.inputTrig') as any).on('keyup', function (e) {
      e.preventDefault();
      $('.user-icon').hide();
      $('.input-clear-trig').bind('click', function () {
        $('.user-icon').fadeIn('fast');
        $('input.isa-login-textbox').removeClass('active');
      });
    });

  }

  login() {

    var self = this;
    this.loader = true;
    if (this.user && (!this.user.language || !this.user.name || !this.user.password)) {
      if (!this.user.language) {
        self.openSnackBar("Requried Language", "Close")
      }
      this.showErr = true;
      this.loader = false;
      self.openSnackBar("Requried username or password ", "Close")
      return;
    }
    let postData = {
      "userId": this.user.name, "password": this.user.password
    };

    this.httpService.loginpostRequest('loginOperationApi/v1/ldap_login', postData).subscribe(
      res => {
        // window.location.href = '#/repair';    
        var json = JSON.parse(res['response']);
        this.loginResponse = res;
        this.loginData = json;
        this.loader = false;
        console.log(json)
        //set userNAme ,id or language 
        //APPCONFIG.Language=this.user.language;
        if (json.rolesMap != undefined || json.rolesMap != null) {
          if (json.rolesMap.code === undefined || json.rolesMap.code === null || json.rolesMap.code.length == 0) {
            self.openSnackBar("No role assigned to this user, please contact admin", "Close")
            return;
          }
        }


        if (json.rolesMap.code.includes("SA")){
          localStorage.setItem('UserRole', "SA");
        }else if (json.rolesMap.code[0] == "SP" && json.rolesMap.code[1]) {
            localStorage.setItem('UserRole', json.rolesMap.code[1]);
          } else {
            localStorage.setItem('UserRole', json.rolesMap.code[0]);
          }
        



        var userRole = localStorage.getItem('UserRole');
        if(localStorage.getItem('UserRole') !='SA'){
          localStorage.setItem('userName', json.userName);
          localStorage.setItem('userId', json.userId);
          localStorage.setItem('userToken', json.userToken);
          
          localStorage.setItem('language', this.user.language);
          localStorage.setItem('userId', json.userId);
          localStorage.setItem('loggedUserResponse', res['response']);
          localStorage.setItem('loggedUserRoles', res['response']);
          localStorage.setItem('loginSessionExit', "true");
          localStorage.setItem('sysRoleIds', json.sysRoleIds);
        }
       
        localStorage.setItem("timestamp", Math.round(new Date().getTime() / 1000.0).toString());
        
        if (userRole == 'TECH' || userRole == 'BA') { 
          this.permissionsService.getCompanyId();
          this.router.navigate(['repair']);
        }
        else if (userRole == 'SA') { 
          let data=json;
          data['language']=this.user.language
          this.openCompanyDialog(data)
        }
        else if (userRole == 'SP') {  
          this.permissionsService.getCompanyId();
          this.router.navigate(['/checkin']); 
        }
        else {
          self.openSnackBar("No role assigned to this user, please contact admin", "Close")
          return;
        }
      },
      err => {
        this.showErr = true;
        this.loader = false;
        var error = err['error']
        switch (error.message) {
          case "INVLD_CRDNTLS":
            self.openSnackBar("Invalid username or password", "Close")
            break;
          case "WPASS_LCK":
            self.openSnackBar("Please unlock your Account", "Close")
            break;
          case "WPASS_DWN":
            self.openSnackBar("WPASS Service temporarily down. Please try again later", "Close")
            break;
          case "INVLD_PASS_CRIT":
            self.openSnackBar("Password does not match criteria.", "Close")
            break;
          case "WPASS_USR_INACT":
            self.openSnackBar("WPASS User not Active.", "Close")
            break;
          case "WPASS_USR_INACT":
            self.openSnackBar("Could not connect to WPASS Service.", "Close")
            break;
            case "com.mediaagility.whr.exception.DatabaseException: No data found for given wpass Id":
            self.openSnackBar("User not available  in ISA. Please register the new user", "Close")
            break;
         default:
            self.openSnackBar("Some issue please try again or contact to admin", "Close")
            break;
        }
      }
    );
  }
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 10000,
      data: {
        icon: 'close'
      },
      panelClass: ['isa-snackbar']
    });
  }

  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.login();
      //this.getTrayDetail();
    }
  }

  openCompanyDialog(data) {
    this.dialogref = this.dialog.open(CompanyDialog, { disableClose: true,data })
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
      this.dialogref.afterClosed().subscribe((result: any) => {
        if (result) {
          localStorage.setItem('userName', this.loginData.userName);
          localStorage.setItem('userId', this.loginData.userId);
          localStorage.setItem('userToken', this.loginData.userToken);
          
          localStorage.setItem('language', this.user.language);
          localStorage.setItem('userId', this.loginData.userId);
          localStorage.setItem('loggedUserResponse', this.loginResponse['response']);
          localStorage.setItem('loggedUserRoles', this.loginResponse['response']);
          localStorage.setItem('loginSessionExit', "true");
          localStorage.setItem('sysRoleIds', this.loginData.sysRoleIds);

          let countryFromstorage = localStorage.getItem('companyId');
          countryFromstorage !==''? this.router.navigate(['dashboard']): ''; 
        }

      });
      this.dialogref.afterClosed().subscribe(result => {
        this.permissionsService.getCompanyId();
      });
    }

}