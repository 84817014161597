import { Component, OnInit,ViewChild ,Inject,Input} from '@angular/core';
import { MatDialog } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { APPCONFIG } from 'app/config';
import { ConfirmationDialogComponent } from 'app/dialog/dialog.component';
import { HelperService } from 'app/helper.service';
import { HttpService } from 'app/shared/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {PageEvent} from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { FilterDialogComponent } from 'app/dashboard/dashboard.component'; 
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manager-report',
  templateUrl: './manager-report.component.html',
  styleUrls: ['./manager-report.component.scss'],
  providers:[DatePipe]

})
export class ManagerReportComponent implements OnInit {
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  
  searchVal = '';
  displayedColumns: string[] = ['socNo','requestNo','createdOn','receivedBy', 'externalReference','flow','country','name','address','productId','model','family','serialNumber','status','closedOn','history','timeDifference','pexNumber','component','defect','comment','missingSpare','d2c','holder','repairedBy','packing','packedBy', 'totalPackingTime',
  'trayNmuber','weekNumber','monthNumber','socCreationDate','socAccountingIndicator','customerNumber',
  'purchaseDate','category','pexReason','notes','technicalNotes','technician','monthlyLeadTime','repairType','problemDescriptionIPG'];
  columnsToDisplay: string[] = ['socNo','requestNo','createdOn','receivedBy','externalReference','flow','country','name','address','productId','model','family','serialNumber','status','closedOn','history','timeDifference','pexNumber','component','defect','comment','missingSpare','d2c','holder','repairedBy','packing','packedBy', 'totalPackingTime',
  'trayNmuber','weekNumber','monthNumber','socCreationDate','socAccountingIndicator','customerNumber',
  'purchaseDate','category','pexReason','notes','technicalNotes','technician','monthlyLeadTime','repairType','problemDescriptionIPG'];
  columnsMapping = {
    "SOC Number": "socNo",
    "Technical Request/Return Number": "requestNo",
    "Date": "createdOn",
    "Receiving Person":'receivedBy',
    "External Reference": "externalReference",
    "Flow": "flow",
    "Country": "country",
    "Customer Name":"name",
    "Address":"address",
    "12NC": "productId",
    "Model": "model",
    "Family": "family",
    "Serial Number": "serialNumber",
    "Status":"status",
    "Closing Date": "closedOn",
    "Appliance History": "history",
    "Repair Lead Time": "timeDifference",
    "PEx Number": "pexNumber",
    "Component":"component",
    "Defect":"defect",
    "Comment":"comment",
    "Missing Spare Parts":"missingSpare",
    "IS_D2C": "d2c",
    "TRI/Holder":"holder",
    "Repair Person":'repairedBy',
    "Packing Person":'packedBy',
    "Packing Lead Time":'totalPackingTime',



    "Tray Number":'trayNmuber',
    "Week Number":'weekNumber',
    "Month Number":'monthNumber',
    "SOC Creation Date":'socCreationDate',
    "Accounting Indicator":'socAccountingIndicator',
    // "Customer Names":'customerName',
    "Customer Number":'customerNumber',
    // "Customer Address":'customerAddress',
    "Purchase Date":'purchaseDate',
    "Category":'category',
    "Pex Reason":'pexReason',
    "Notes":'notes',
    "Technical Note":'technicalNotes',
    "Technician":'technician',
    "Monthly Lead Time":'monthlyLeadTime',
    "Repair Type":'repairType',
    "Problem Description":'problemDescriptionIPG',
    // "Comment Description":'commentDescription',
    // "Component Description":'componentDescription',
    // "Defect Description":'defectDescription'
    "Packing":'packing'
  };
  dataSource : MatTableDataSource<any>;
  dialogref: any;
  data: any;
  zone: string;
  minDate = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  length = 100;
  pageSize = 50;
  latest_date: any;
  userLDapResonseRoles: any;
  flag:boolean=true;
  D2CFilter:String="All";
  reportURL: string=APPCONFIG.REPORT_URL;
  repairURL: string=APPCONFIG.HOMEPAGE;
  PexNum: boolean= false;
  viewMoreReports: boolean;
  applyFilter(filterValue: string) {
   // const filterValue = event.target.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  permissions=[];
  clearFilters(){
    this.searchVal='';
    this.dataSource.filter = '';
 }
  constructor(private location: Location,private commonService: CommonService, private httpService: HttpService, private helperService: HelperService, private route: ActivatedRoute, private httpservice: HttpService, private spinner: NgxSpinnerService, private sanitizer:DomSanitizer,private router: Router,public dialog: MatDialog, private datePipe:DatePipe)  {
    this.minDate.setDate(this.minDate.getDate() - 30);
    // let old_date =this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
      this.latest_date =this.datePipe.transform(this.maxDate, 'yyyy-MM-dd  h:mm:ss');
    this.bsRangeValue = [ this.minDate, this.maxDate];

    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));             
    // if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
    //  ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
    // ) 
    // {
    // }else{
      
    //   if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
    //   ){  
    //     this.router.navigate(['/repair']);
    //   }else{
    //     this.router.navigate(['/common']);
    //   }     
    // }

    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null){
    this.permissions['dashboard.manage_report'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    this.permissions['dashboad.view_more_reports'].toLowerCase() == 'true'? this.viewMoreReports = true : this.viewMoreReports = false;
    }
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }

    if (this.httpService.loginCheckSession()) { }
    else {
        this.httpService.showSessionError();       
    } 
   }

   noSorting() {
    return 0;
  }
  ngOnInit() {
    let postData ={
      "startDate": this.bsRangeValue[0],
      "endDate":this.bsRangeValue[1],
      "soc":"",
      "filterD2C":this.D2CFilter,
      "technicalRequest":"",
      "serialNumber":"",
      "offSet":"0",
      "limit":"0",
      "type":"MANAGER"

      };
    this.fetchData(postData);
    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  fetchData(data){
    this.spinner.show();
    this.httpService.postRequest('checkInOperationApi/v1/listDashboard',data).subscribe(
    res => {
        this.spinner.hide();
        if (res['responseCode'] == '200') {
          this.data= res['items'];
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSort()
          if (res['itemCount']==0) {
            var msg = "No Records were found for the selected date";
            this.openInformationDialog(msg);
          }
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      }
    );
  }     

  dataSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }

  openFilterDialog() {
    this.dialogref = this.dialog.open(FilterDialogComponent,{ panelClass: 'date-dialog-container' })
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
  // this.dialogref.componentInstance.coloumn = this.displayedColumns;
    this.dialogref.componentInstance.maxDate = this.maxDate;
    this.dialogref.componentInstance.minDate = this.minDate;
    this.dialogref.componentInstance.filter = this.D2CFilter;
    this.dialogref.componentInstance .pexNum = this.PexNum;
    
    this. dialogref.afterClosed().subscribe(res => {
      console.log(res);
     if(res&&res.value){
      res.value['type']="MANAGER"
      this.fetchData(res.value);
       console.log(res.value.filterD2C)
       this.D2CFilter=res.value.filterD2C;
       this.bsRangeValue = [res.value.startDate, res.value.endDate];
       this.minDate=res.value.startDate;
       this.maxDate=res.value.endDate;
       this.PexNum=res.value.filterPEx;
     }
    })
  }

  setPageSizeOptions(event) {
  //  if (setPageSizeOptionsInput) {
   //   this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
 //   }
   console.log(event)
   console.log('coloumn',this.flag);
  let newOffset = event.pageSize * event.pageIndex;
  let postData ={
    "startDate": this.bsRangeValue[0],
    "endDate":this.bsRangeValue[1],
    "soc":"",
    "d2c":"",
    "filterD2C":this.D2CFilter,
    "technicalRequest":"",
    "serialNumber":"",
    "offSet":newOffset,
    "limit":"0"
    };
   
   if(this.flag){
     this.fetchData(postData);
    }
  }

  openDashboard() { 
    window.open( this.reportURL, "_blank");
}
routeSOC(){
  const link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: 6220293315 } }));
 window.open(link, '_blank');
}
getSOCInfo(soc) {
  if (!soc || (soc.toString().length != 10 )) {
    this.openInformationDialog("The SOC Number inserted is not valid, please proceed by inserting a valid one");
     return;
  }
  this.spinner.show();
  this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemBySocNo?socNumber=' + soc)//+"&type="+this.type)
    .subscribe(res => {
      this.spinner.hide();
      const resp = res['response'];
      if (res['status'] == 'ERROR') {
        var msg = 'Failed to process request. Please contact administrator.';
        this.openInformationDialog(msg);
      }
      else {
        const sapRes = resp['sapResponse'];
        this.helperService.sapResponse = res;
        const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
        if (sapResSerTypeDes.includes('refurb')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['refurb-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['refurb-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('repair')) {
          //  var link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: soc } }));
          //  link ='/#'+link;
          //   window.open(link, '_blank');
          this.router.navigate(['repair-info'], { queryParams: { socId: soc } });
          
        }
        else if (sapResSerTypeDes.includes('healthcheck')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['healthcheck-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['healthcheck-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
          // var link = this.router.serializeUrl(this.router.createUrlTree(['extensiverepair-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['extensiverepair-info'], { queryParams: { socId: soc } });
        }
        else {
          var msg = 'The Request type is invalid.Please contact administrator.';
          this.openInformationDialog(msg);
        }

      }
  
    }, err => {
      this.spinner.hide();
      if (err.status === 401) {
        this.openInformationDialog("Please login again")
      }
      else{
        var msg = 'Internal Issue.Please contact administrator.';
        this.openInformationDialog(msg);  
      }
    })
}

}
