/**
 * Created by shubham on 27/2/18.
 */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class CommonService {

    private loginRedirectUrl: string;
    public viewData = new Subject();
    public viewDataFlag = new Subject();
    public userData = new Subject();
    public adminDashboard = new Subject();
    public partnersDashboard = new Subject();
    public applicationDashboard = new Subject();
    public partnersView = new Subject();
    public subUsersView = new Subject();
    public adminList = new Subject();
    public managerList = new Subject();
    public applicationList = new Subject();
    public companyList=[];
    constructor(private _snackBar: MatSnackBar) {}

    setLoginRedirectUrl(url: string) {
        this.loginRedirectUrl = url;
    }

    getLoginRedirectUrl():string {
        return this.loginRedirectUrl;
    }

    changeViewData(data, flag){
        this.viewData.next(data);
        this.viewDataFlag.next(flag);
    }

    changeUserData(data){
        this.userData.next(data);
    }

    changeAdminDashboardData(){
        this.adminDashboard.next();
    }

    changePartnersDashboardData(){
        this.partnersDashboard.next();
    }

    changeApplicationDashboardData(){
        this.applicationDashboard.next();
    }

    changePartnerViewData(){
        this.partnersView.next();
    }

    changeSubUserViewData(){
        this.subUsersView.next();
    }

    changeAdminListDashboardData(){
        this.adminList.next();
    }

    changeManagerListDashboardData(){
        this.managerList.next();
    }

    changeApplicationListDashboardData(){
        this.applicationList.next();
    }

    contains(value, term, caseInsensitiveSearch) {
        if(!value) return false;
        if(!term) return false;
        if(caseInsensitiveSearch){
            value = value.toLowerCase();
            term = term.toLowerCase();
        }
        return (value.indexOf(term) != -1);
    }

    equals(value1, value2, caseInsensitiveMatch) {
        if(!value1) return false;
        if(!value2) return false;
        if(caseInsensitiveMatch){
            value1 = value1.toLowerCase();
            value2 = value2.toLowerCase();
        }
        return (value1==value2);
    }

    stopPropagationCheckBox(event) {
        if (event.stopPropagation) {
            event.stopPropagation();    // Standard
        }else {
            event.cancelBubble = true;  // Old IE
        }
    }

    getChoosenFile(event, componentModal, componentKey){
        let file = event.srcElement.files[0];
        let fileName  = file.name;
        var reader = new FileReader();
        reader.onload = function(){
            componentModal[componentKey] = {name:fileName,data:reader.result};
        }.bind(componentModal);
        reader.readAsDataURL(file);
    }

    openSnackBar(message, action) {
        this._snackBar.open(message, action, {
          duration: 4000,
          data: {
            icon: 'Close'
          },
          panelClass: ['isa-snackbar']
        });
    
      }
      setCompamyList(companyList){
          this.companyList=companyList
      }
      getCompamyList(){
        return this.companyList;
    }
}