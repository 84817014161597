import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';
import { HttpService } from '../shared/services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent, WarningDialogComponent, ScrapDialogComponent } from '../dialog/dialog.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionService } from 'app/shared/services/permission.service';
import { Location } from '@angular/common';
import { CommonService } from 'app/shared/services/common.service';


@Component({
  selector: 'app-scrap',
  templateUrl: './scrap.component.html',
  styleUrls: ['./scrap.component.scss']
})
export class ScrapComponent implements OnInit {
  step = 0;
  sapResponse: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  actionPerformed: any;
  dialogref: any;
  trayNum: String = "";
  socNum: String = "";
  soNum: String = "";
  isError: boolean = false;
  isError1: boolean = false;
  isError2: boolean = false;
  confirmMsg: string;
  refurbErrorMsg: string;
  isrefurbError: boolean;
  permissions = [];
  userLDapResonseRoles: any;
  companyId:any='';
  constructor(private _snackBar: MatSnackBar, private location: Location,private helperService: HelperService, private httpservice: HttpService, private route: Router, private spinner: NgxSpinnerService, public dialog: MatDialog,
    private permissionsService: PermissionService, private commonService:CommonService) {
      if (this.httpservice.loginCheckSession()) { }
      else {
        this.httpservice.showSessionError();
      }
      /*
            this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles')); 
            if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
              ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
              ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
            )
            {
            }else{
              // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
              // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
              // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
              // ){  
              //   this.route.navigate(['/scrap']);
              // }else{
                this.route.navigate(['/common']);
              // }
            }
      */
      this.companyId=this.permissionsService.companyId;
      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['scrap'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag || this.companyId =='2002' ){
          // this.route.navigate(['/common']);
          this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
          this.location.back();
        }
    }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 4000,
      data: {
        icon: 'Close'
      },
      panelClass: ['isa-snackbar']
    });
  }

  ngOnInit() {

  }



  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.isError = false;
      this.helperService.openModal('Delete_Dialog');
      //this.getTrayDetail();
    }
  }

  //  validate(){
  //   var self = this;
  //   if (this.trayNum.toString().length != 3) {
  //     this.isError = true;
  //     self.openSnackBar("Please enter your tray number", "Close")
  //     return;
  //   }
  //   this.isError=false;
  //   this.helperService.openModal('Delete_Dialog');
  //  }

  clearTray() {
    this.spinner.show();
    this.httpservice.getRequest('checkInOperationApi/v1/clearTray?trayId=' + this.trayNum + "&type=" + "SCRAP")
      .subscribe(res => {
        this.spinner.hide();

        if (res['status'] == 'ERROR') {
          this.confirmMsg = res['response'];
          this.openInformationDialog(this.confirmMsg);
          this.trayNum = '';
        } else {
          this.confirmMsg = res['response'];
          this.openInformationDialog(this.confirmMsg);
          this.trayNum = '';
        }
      }, err => {
        this.spinner.hide();
      })

  }

  scrapTrayNO() {
    var self = this;
    if (this.trayNum.toString().length != 3) {
      this.isError = true;
      self.openSnackBar("The Tray Number inserted is not valid, please proceed by inserting a valid one", "Close")
      return;
    }
    this.spinner.show();
    this.httpservice.getRequest('checkOutOperationApi/v1/scrapTray?trayNO=' + this.trayNum)
      .subscribe(res => {
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          if (res['response'] == 'The Tray selected has been designated for Repaired') {
            var msg = 'Tray ' + this.trayNum + ' Designated for Repaired.';
            this.openInformationDialog(msg);
          } else if (res['response'] == 'The Tray selected has been designated for 1st Choice') {
            var msg = 'Tray ' + this.trayNum + ' Designated for 1st Choice.';
            this.openInformationDialog(msg);
          // } else if (res['response'] == 'Tray Number:' + this.trayNum + 'is in process') {
          } else if (res['response'].includes("is in process")) {
            var msg = 'Tray ' + this.trayNum + ' is Not Handled yet and cannot be used for Scrap';
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Tray ' + this.trayNum + ' is free yet and cannot be used for Scrap';
            this.openInformationDialog(msg);
          }
        }
        else {
          this.sapResponse = resp['sapResponse'];
          this.showInformation();
        }
        this.spinner.hide();
      }, err => {
        this.isError = true;
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        } else {
          var msg = 'Internal Issue. Please contact the Support Team';
          this.openInformationDialog(msg);

        }
        this.spinner.hide();
      })
  }
  showInformation() {
    var msgAction = this.setAction()
    // var msg = 'Soc Id '+this.sapResponse.essocdata.socid+' associated with tray '+this.trayNum+' '+msgAction;
    //this.openInformationDialog(msg);
    var soc = this.sapResponse.essocdata.socid;
    this.confromMoreInformation(soc, msgAction);
  }

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
    // this.dialogref.afterClosed().subscribe(result => {
    //   console.log('result-----'+result);
    // });
  }

  confromMoreInformation(soc, action) {
    //  var msg='Soc Id 6220292884 associated with tray 174 has performed Action In Warranty And Are you sure you want to release the tray ?'
    var msg = 'Are you sure you want to release the tray ?'
    this.opencScrapConfromationDialog(soc, action, msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.clearTray();
      }
    });
  }

  opencScrapConfromationDialog(soc, action, msg) {
    this.dialogref = this.dialog.open(ScrapDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.tray = this.trayNum;
    this.dialogref.componentInstance.soc = soc;
    this.dialogref.componentInstance.action = action;
    this.dialogref.componentInstance.msg = msg;
  }


  setAction() {
    console.log("Action" + this.actionPerformed);
    if (this.sapResponse && this.sapResponse.etadditionalfields && JSON.stringify(this.sapResponse).indexOf('FLOW') > -1) {
      for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
        if (this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FLOW') {
          this.actionPerformed = this.sapResponse.etadditionalfields[i].value;
          // return 'has performed action '+this.actionPerformed;
          if (this.actionPerformed == '0001') {
            return 'In Warranty';
          } else if (this.actionPerformed == '0002') {
            return 'OOW - Prepayer';
          } else if (this.actionPerformed == '0003') {
            return 'OOW - Postpayer';
          } else if (this.actionPerformed == '0004') {
            return 'Healthcheck';
          } else if (this.actionPerformed == '0005') {
            return 'Scrap';
          } else if (this.actionPerformed == '0006') {
            return '1st Choice';
          } else if (this.actionPerformed == '0007') {
            return 'Refurb';
          }
        }
      }
    }
  }

}

