import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from './../helper.service';
import { CountupTimerService, countUpTimerConfigModel, timerTexts } from 'ngx-timer';
import * as $AB from 'jquery';
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpService } from "../shared/services/http.service";
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';
//ReplaySubject
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { map, startWith } from 'rxjs/operators';

import { take, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { APPCONFIG } from 'app/config';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogComponent,ConfirmDialogComponent,ConfirmationDialogComponent,WarningDialogComponent} from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { PermissionService } from 'app/shared/services/permission.service';

declare var $: any;
declare var updateOwlCarousel: any;
declare var owlCarousel: any;

@Component({
  selector: 'app-extensiverepair-info',
  templateUrl: './extensiverepair-info.component.html',
  styleUrls: ['./extensiverepair-info.component.scss']
})
export class ExtensiverepairInfoComponent implements OnInit {
  @ViewChild('defectCode', { static: false }) el: ElementRef;
  @ViewChild('solutioncode', { static: false }) el1: ElementRef;
  @ViewChild('comment', { static: false }) el2: ElementRef;
  @ViewChild('pexdefectCode', { static: false }) el3: ElementRef;
  @ViewChild('pexsolutioncode', { static: false }) el4: ElementRef;
  @ViewChild('pexexchangecode', { static: false }) el5: ElementRef;
  @ViewChild('pexcomment', { static: false }) el6: ElementRef;
  @ViewChild('waitingComment', { static: false }) el7: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('addApare', { static: false }) spare;
  @ViewChild('spform', {static:true}) spform;
  @ViewChild('focusInput', { static: true }) focusInput: ElementRef;

  dialogref: any;
  technicalRequest: any;
  closebCommentfiltered: Observable<string[]>;
  pexbCommentfiltered: Observable<string[]>;
  CommentCodedata: any;
  commentCodeFlag: boolean = false;
  pexcommentCodeFlag:  boolean = false;
  showCommentBox: boolean = false;
  pexshowCommentBox:  boolean = false;
  textComment: any;
  productFamily: string;
  applianceHistory: boolean  = false;actionPerformed: string;
  holder: any;
  packing: any;
  showImage: boolean = false;
  showDoc: any = [];
  imageURL: string = '../assets/pdf/image.png';
  showDocuments:any= [];
  pdfDownload:any=[];
  arr:any=[];
  isDoc;
  printContents: string='';
  popupWin: any;
  reportURL:string = '../assets/pdf/image.png';
  result:any= [];
  temp:any= [];
  notPauseApi: boolean=false;
  permissions: any;
  waitngMissingspareError: boolean = false;
  waitngMissingspareFlag: boolean = false;
  waitingMasterCode: any;
  historyMsg: string;
;
;
  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }

  color = 'primary';
  mode = 'determinate';
  value = 50;
  icon: boolean = false;
  iconexist: boolean = false;
  iconTogl: boolean = false;
  ClosedAction: any;
  spareMasterCode: Observable<string[]>;

  click() {
    this.icon = !this.icon;
  }

  clickTogl() {
    this.iconTogl = !this.iconTogl;
  }
  textForm: FormGroup;
  spareForm: FormGroup;
  pexForm: FormGroup;
  socForm: FormGroup;
  wallForm: FormGroup;
  spareList: any = [];
  spareDeleteble: any;
  testConfig: countUpTimerConfigModel;
  trayNo: String = '';
  SolutionCodeErrorMsg: any;
  SolutionCodeError: any;
  DefectCodeMsg: any;
  DefectCodeError: any;
  ComponentCodeError: any;
  ComponentCodeErrorMsg: any;
  componentCodedata: any;
  defectCodedata: any;
  solutionCodedata: any;
  isdeleteError: any;
  deleteErrorMsg: any;
  isSpareError: boolean = false;
  SpareErrorMsg: String = "";
  quantityErrorMsg: any;
  isquantityError: any;
  spares: any = [];
  type: string = 'repair';
  isrefurbError: boolean = false;
  refurbErrorMsg: String = "";
  conformMsg: string = "You successfully <action> for SOC Number <scoid>  You took  <time> to do the Intervention";
  exchangeCodesdata: any;
  exchangeCodesError: boolean = false;
  exchangeCodesErrorMsg: String = '';
  processStatus: any;
  isTableHide: boolean = false;
  sapResponse: any;
  mediaError: boolean;
  mediaErrorMsg: any;
  showingmediaError: boolean;
  showingMediaErrorMsg: any;
  test: boolean = false;
  showDocument: any = [];
  filesList: any = [];
  filename: string = '';
  cameraOpen: boolean = false;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  sizeErrorMsg: string = '';
  removeIndex: any = [];
  spareErrorMsg: string = '';
  spareError: boolean = false;
  feedbackCodeErrorMsg: string = '';
  feedbackCodeError: boolean = false;
  feedbackReasondata: any;
  numFiles: any;
  showUploadedFiles: boolean = false;
  currentDate: Date;
  userName: string = '';
  missingSpareData: any;
  sapEttexts: any
  spareTextCode: any
  //webcam variable start 
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  callLightBox: boolean;
  //webcam end
  //scanner variable
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  SOCId: string;
  formList: any = [];

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  qrResultString: string;
  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  scannerEnabled = false;
  scanStart: boolean = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  caoruseExist: boolean = false;
  qrResults: any = [];
  BarScanErrorMsg: string = '';
  BarScanError: boolean = false;
  scanResult: any = [];
  zone: string = "";
  date: string;
  productId: string = '';
  socNum: any;
  soNum: any;
  action: string = '';
  sapSpare: any = [];
  solutionCodeFlag: boolean = false;
  componentCodeFlag: boolean = false;
  defectCodeFlag: boolean = false;
  ssolutionCodeFlag: boolean = false;
  scomponentCodeFlag: boolean = false;
  sdefectCodeFlag: boolean = false;
  feedbackReasonFlag: boolean = false;
  sExchangeCodeFlag: boolean = false;

  CCode: any;
  DCode: any;
  SCode: any;
  sCCode: any;
  sDCode: any;
  sSCode: any;
  fReason: any;
  sECode: any;

  closeComponentfiltered: any;
  closeDefectfiltered: any;
  closebSoltionfiltered: any;
  pexComponentfiltered: any;
  pexDefectfiltered: any;
  pexbSoltionfiltered: any;
  pexexchangefiltered: any;
  waitingfiltered: any;
  closeRequestData: any;
  missingMasterCode: any = [];
  spareCodeFlag: boolean = false;
  SMcode: any;
  /** list of productcode */
  productcode: any = [];
  sMissingSpareFlag: boolean = false;
  showMissingSpare: boolean = false;
  showMissingError: boolean = false;
  sSpareCode: any;
  uploadErrorMsg:String = "";
  storeLocation:any='supermarket';
  uploadFlag: boolean = false;

  /** control for the selected bank */
  public pcodeCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public pcodeFilterCtrl: FormControl = new FormControl();

  /** list of productcode filtered by search keyword */
  public filteredproductcode: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  productcodeError: boolean = false;
  productcodeErrorMsg: string = '';

  userLDapResonseRoles: any;
  qntyVal: String;
  soldToId;
  companyId='';
  WaitingMissingSpare: boolean = false;

  constructor(private locationFORURL: Location,private commonService: CommonService, public fb: FormBuilder, private helperService: HelperService, private location: PlatformLocation, private countupTimerService: CountupTimerService, private httpService: HttpService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private deviceService: DeviceDetectorService, private sanitizer: DomSanitizer, private router: Router,public dialog: MatDialog,
    private permissionsService: PermissionService) {

    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
    // if (this.userLDapResonseRoles.rolesMap.code.includes('TECH')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) {
    // } else {

    //   // if (this.userLDapResonseRoles.rolesMap.code.includes('TECH')
    //   //   || this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //   //   || this.userLDapResonseRoles.rolesMap.code.includes('BA')
    //   // ) {
    //   //   this.router.navigate(['/scrap']);
    //   // } else {
    //     this.router.navigate(['/common']);
    //   // }
    // }
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['extensiveRepair'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.locationFORURL.back();
    }    
    if (this.httpService.loginCheckSession()) { }
    else {
      this.httpService.showSessionError();

    }

    this.route.queryParams.subscribe(params => {
      this.trayNo = params['trayId'];
      this.soNum = params['soId'];
      this.socNum = params['socId'];
    });
    if ((this.trayNo == null || this.trayNo == undefined || this.trayNo == "") && (this.soNum == null || this.soNum == undefined || this.soNum == "") && (this.socNum == null || this.socNum == undefined || this.socNum == "")) {
      this.router.navigate(['repair']);
      return;
    }


    this.epicFunction();
    this.userName = localStorage.getItem('userName');

    this.spareCodeData();
    this.companyId=this.permissionsService.companyId;

  }


  ngOnInit() {
    this.scannerEnabled = true;
    this.spareForm = this.fb.group({
      // name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      quantity: ['', [Validators.required, Validators.min(1)]],
      storeLocation: ['', [Validators.required]]
    });
    if(this.companyId=='2002'){/**Remove  storeLocation for Uk company user*/
      this.spareForm.get('storeLocation').setValidators(null);
      this.spareForm.get('storeLocation').updateValueAndValidity();
    }
    this.socForm = this.fb.group({
      solutionCode: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      componentCode: ['', [Validators.required]],
      defectCode: ['', [Validators.required]],

    });
    this.pexForm = this.fb.group({
      sSolutionCode: ['', [Validators.required]],
      sComment: ['', [Validators.required]],
      sComponentCode: ['', [Validators.required]],
      sDefectCode: ['', [Validators.required]],
      exchangecode: ['', [Validators.required]],
      missingSpare: [''],
    });
    this.wallForm = this.fb.group({
      feedbackReason: ['', [Validators.required]],
      wComment: [''],
      missingSpare: ['']
    });
    this.textForm = this.fb.group({
      tdid: ['', [Validators.required]],
      text: ['']
    });

    this.testConfig = new countUpTimerConfigModel();
    //this.testConfig.timerClass  = 'test_Timer_class';
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
    //       this.countupTimerService.startTimer();
    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    updateOwlCarousel();

    //setting response from repair-refurb component           


    if (typeof this.helperService.sapResponse != "undefined") {
      const resp = this.helperService.sapResponse['response'];
      // this.repairDetails=resp.repairDetails;
      this.sapResponse = resp.sapResponse;
      if (this.sapResponse) {
        this.SOCId = this.sapResponse.essocdata.socid;
        this.trayNo = resp.trayId;
        var dt = this.sapResponse.esproductdetails.crmpurchasedate;
        this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
        if (this.sapResponse.esproductdetails.refproduct) {
          this.productId = this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
        }
        if (this.sapResponse.ettexts) {
          this.sapEttexts = this.sapResponse.ettexts
          this.loadFilter();
        }
        this.processStatus = resp.processDetail.status;
        this.fetchMasterData();
       
        let postData = {

          //"userId":"ISA User",
          // "consumerId":this.sapResponse.esproductdetails.refproduct,//esproductdetails
          //"applianceId":this.sapResponse.esproductdetails.zz0014, //this.sapResponse.esproductdetails.zz0014
          "transactionId": this.sapResponse.essocdata.socid, //this.sapResponse.essocdata.socid
          // "userName":"",
          // "system":"",
          // "country":"",
          //"documentSource":"ISA"

        };
        this.imageShow(postData);
        this.getHistory()
        if (resp.repairDetails) {
          this.technicalRequest=resp.repairDetails.requestNo;
          let postData1 = {
            "transactionId": resp.repairDetails.requestNo, //technical request number
          };
          this.imageShowbyso(postData1);
        }
        this.loadReport();
      }
      if (resp.processDetail) {
        var dateStr = resp.processDetail.startTime;
        let dateJson = new Date(dateStr);
        var m1 = moment(dateJson).tz(this.zone).format();      // 2014-06-22T09:21:08-07:00
        let date1 = new Date(m1);
        let date2 = new Date();
        var duration = date2.getTime() - date1.getTime();
        // var difference = cdate.getTime() - dateJson.getTime();
        let startDate = (new Date().getTime()) - duration;
        this.countupTimerService.startTimer(startDate);
      }
      else {
        this.countupTimerService.startTimer()
      }
      this.sapSpare = resp.sapSpare;
      if (resp.spare && resp.sapSpare) {
        this.spares = this.sapSpare.concat(resp.spare);
      } else if (resp.sapSpare) {
        this.spares = this.sapSpare;
      } else {
        this.spares = resp.spare;
      }
      this.spinner.hide();
      if (resp.clodeRequestDetail) {
        this.ClosedAction = resp.clodeRequestDetail;
        this.closeRequestData = resp.clodeRequestDetail;
        if ((this.closeRequestData.exchangeReason == '001-Missing spare parts (in & out of warr.)')) {
          this.loadMissingSpare();
        }
      }
    } else {
      this.getSpareDetails();
    }
    this.loadFilter();
    this.setAction();
  }
  loadFilter() {
    this.closeComponentfiltered = this.socForm.get('componentCode').valueChanges
      .pipe(
        startWith(''),
        map(val => this._filter(val))
      );
    this.closeDefectfiltered = this.socForm.get('defectCode').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterDefect(value))
      );

    this.closebSoltionfiltered = this.socForm.get('solutionCode').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSolution(value))
      );
    this.pexComponentfiltered = this.pexForm.get('sComponentCode').valueChanges
      .pipe(
        startWith(''),
        map(val => this._filter(val))
      );
    this.pexDefectfiltered = this.pexForm.get('sDefectCode').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterDefect(value))
      );

    this.pexbSoltionfiltered = this.pexForm.get('sSolutionCode').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSolution(value))
      );

    this.pexexchangefiltered = this.pexForm.get('exchangecode').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterExchange(value))
      );

    this.waitingfiltered = this.wallForm.get('feedbackReason').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterFeedback(value))
      );
    this.spareMasterCode = this.spareForm.get('code').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSpareCode(value))
      );
    this.missingMasterCode = this.pexForm.get('missingSpare').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSpareCode(value))
      );
      this.waitingMasterCode = this.wallForm.get('missingSpare').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSpareCode(value))
      );
    // this.spareTextCode = this.textForm.get('tdid').valueChanges
    // .pipe(
    //   startWith(''),
    //   map(value => this._filterText(value))
    // );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.helperService.hideAllModal()
    if(!this.notPauseApi && this.processStatus  != 'PROCESSING_END'){
      this.StopTimer();
    }
  }
  private _filter(value: string): string[] {
    if ((value === undefined || value === null) || (this.componentCodedata === undefined || this.componentCodedata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.componentCodedata.filter(option => String(option.code).toLowerCase().indexOf(filterValue) > -1 ||
      option.description.toLowerCase().indexOf(filterValue) > -1);
    return data
  }

  // private _filterText(value: string): string[] {
  //   if ((value === undefined || value === null ) || (this.sapEttexts === undefined || this.sapEttexts === null ) ) 
  //   return ;
  //   const filterValue = value.toLowerCase();
  //   var data = this.sapEttexts.filter(option => String(option.tdid).toLowerCase().indexOf(filterValue) > -1 ||
  //     option.text.toLowerCase().indexOf(filterValue) > -1);
  //   return data
  // }
  private _filterDefect(value: string): string[] {
    if ((value === undefined || value === null) || (this.defectCodedata === undefined || this.defectCodedata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.defectCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
      option1.description.toLowerCase().indexOf(filterValue) > -1);
    return data

  }

  private _filterSolution(value: string): string[] {
    if ((value === undefined || value === null) || (this.solutionCodedata === undefined || this.solutionCodedata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.solutionCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
      option1.description.toLowerCase().indexOf(filterValue) > -1);
    return data

  }

  private _filterExchange(value: string): string[] {
    if ((value === undefined || value === null) || (this.exchangeCodesdata === undefined || this.exchangeCodesdata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.exchangeCodesdata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
      option1.description.toLowerCase().indexOf(filterValue) > -1);
    return data

  }

  private _filterFeedback(value: string): string[] {
    if ((value === undefined || value === null) || (this.feedbackReasondata === undefined || this.feedbackReasondata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.feedbackReasondata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
      option1.description.toLowerCase().indexOf(filterValue) > -1);
    return data
  }

  private _filterSpareCode(value: string): string[] {
    if ((value === undefined || value === null) || (this.productcode === undefined || this.productcode === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.productcode.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option1.spareName.toLowerCase().indexOf(filterValue) > -1);
    return data
  }
  getfeedbackReasonVerified(event) {
    this.feedbackReasonFlag = event.option.value?true:false;
    // this.feedbackReasonFlag = event.isUserInput;
    if ( this.fReason == "07-Waiting for Spare") {
      this.waitngMissingspareFlag = false;
      this.WaitingMissingSpare=true; 
    }else{
      this.waitngMissingspareFlag = true;
      this.WaitingMissingSpare=false; 
    }
  }
  focusOutfeedbackReason(code) {
    if (code != undefined) {
      var codeFound = this.feedbackReasondata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.feedbackReasonFlag = true;
      }
      else {
        this.feedbackReasonFlag = false;
      }
    }
  }
  getSpareCodeVerified(event) {
    this.spareCodeFlag = event.isUserInput;

  }
  focusOutSpareCode(code) {
    if (code != undefined) {
      var codeFound = this.productcode.find(x => x.spareName == code);
      if (codeFound != undefined && code == codeFound.spareName) {
        this.spareCodeFlag = true;
      }
      else {
        this.spareCodeFlag = false;
      }
    }
  }
  getComponentCodeVerified(event) {
    this.componentCodeFlag = event.isUserInput;

  }
  getSolutionCodeVerified(event) {
    this.solutionCodeFlag = event.isUserInput;

  }
  getDefectCodeVerified(event) {
    this.defectCodeFlag = event.isUserInput;

  }
  focusOutComponentCode(code) {
    if (code != undefined) {
      var codeFound = this.componentCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.componentCodeFlag = true;
        this.CommentCodeData(codeFound.code);
        
      }
      else {
        this.componentCodeFlag = false;
      }
    }

  }
  focusOutSolutionCode(code) {
    if (code != undefined) {
      var codeFound = this.solutionCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.solutionCodeFlag = true;
      }
      else {
        this.solutionCodeFlag = false;
      }
    }
  }
  focusOutDefectCode(code) {
    if (code != undefined) {
      var codeFound = this.defectCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.defectCodeFlag = true;
      }
      else {
        this.defectCodeFlag = false;
      }
    }
  }
  sgetComponentCodeVerified(event) {
    this.scomponentCodeFlag = event.isUserInput;

  }
  sgetSolutionCodeVerified(event) {
    this.ssolutionCodeFlag = event.isUserInput;

  }
  sgetDefectCodeVerified(event) {
    this.sdefectCodeFlag = event.isUserInput;

  }
  sgetMissingSpareVerified(event) {
    this.sMissingSpareFlag = event.isUserInput;
    this.showMissingError = false;
  }

  sgetExchangeCodeVerified(event) {
    this.sExchangeCodeFlag = true;
    if (event.option.value == "001-Missing spare parts (in & out of warr.)") {
      this.showMissingSpare = true;
      this.sMissingSpareFlag = false;
    }
    else {
      this.sMissingSpareFlag = true;
      this.showMissingSpare = false;
      // this.pexForm.controls['missingSpare'].value="";
      const d = { 'missingSpare': "" };
      this.pexForm.patchValue(d);
      this.sSpareCode = "";
    }
  }

  sfocusOutExchangeCode(code, form) {
    //this.showMissingSpare=false;
    var codeFound = this.exchangeCodesdata.find(x => x.description == code);
    if (codeFound != undefined && code == codeFound.description) {
      this.sExchangeCodeFlag = true;
      if (codeFound.description == "001-Missing spare parts (in & out of warr.)") {
        this.showMissingSpare = true;
      }
      else {
        this.sMissingSpareFlag = true;
        this.showMissingSpare = false;
        codeFound = null;
      }
    }
    else {
      this.sExchangeCodeFlag = false;
      this.showMissingSpare = false;
    }

  }

  sfocusOutComponentCode(code) {
    if (code != undefined) {
      var codeFound = this.componentCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.scomponentCodeFlag = true;
        this.CommentCodeData(codeFound.code);
      }
      else {
        this.scomponentCodeFlag = false;
      }
    }

  }
  sfocusOutSolutionCode(code) {
    if (code != undefined) {
      var codeFound = this.solutionCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.ssolutionCodeFlag = true;
      }
      else {
        this.ssolutionCodeFlag = false;
      }
    }
  }
  sfocusOutDefectCode(code) {
    if (code != undefined) {
      var codeFound = this.defectCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.sdefectCodeFlag = true;
      }
      else {
        this.sdefectCodeFlag = false;
      }
    }
  }
  sMissingSpareCode(code) {
    if (code == undefined || code == "") {
      this.showMissingError = true;
      this.sMissingSpareFlag = false;
    }
    if (code != undefined && code != "") {
      this.showMissingError = false;
      var codeFound = this.productcode.find(x => x.spareName == code);
      if (codeFound != undefined && code == codeFound.spareName) {
        this.sMissingSpareFlag = true;
      }
      else {
        this.sMissingSpareFlag = false;
      }
    }
  }

  componentCodeData(modelCode) {
    this.httpService.getRequest("refurbOperationApi/v1/componentCode?modelNo="+modelCode).subscribe(
      res => {
        if (res['status'] == 'SUCCESS') {
          this.componentCodedata = res['response'];
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
      }
    );
  }
  defectCodeData(modelCode) {
    this.httpService.getRequest("refurbOperationApi/v1/defectCode?modelNo="+modelCode).subscribe(
      res => {
        if (res['status'] == 'SUCCESS') {
          this.defectCodedata = res['response'];
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
      }
    );
  }

  
  solutionCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/soluionCode").subscribe(
      res => {
        this.solutionCodedata = res['response'];
        this.SolutionCodeError = false;
      },

      err => {
        this.SolutionCodeErrorMsg = "error occured while getting soluionCode details";
        this.SolutionCodeError = true;
        this.spinner.hide();
      }
    );
  }

  exchangeCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/exchangeCodes").subscribe(
      res => {
        this.exchangeCodesdata = res['response'];
        this.exchangeCodesError = false;
      },

      err => {
        this.exchangeCodesErrorMsg = "error occured while getting exchange Codes details";
        this.exchangeCodesError = true;
        this.spinner.hide();
      }
    );
  }

  selectDeletableSpare(obj_id) {
    this.spareDeleteble = obj_id;
    var msg = 'Do you confirm you need to delete this spare?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.deleteSpare()
      }
    });
  }

  deleteSpare() {
    this.spinner.show();
    this.httpService.postRequest("refurbOperationApi/v1/deleteSpare?spareId=" + this.spareDeleteble, {}).subscribe(
      res => {
        this.spareData();
        this.isdeleteError = false;
      },
      err => {
        this.deleteErrorMsg = "some error occured while delete  spare";
        this.isdeleteError = true;
        this.spinner.hide();
      }
    );
  }

  cancelSpare() {
    this.isSpareError = false;
    this.spareForm.reset();
    this.spform.resetForm();
    setTimeout(()=>{
      this.storeLocation='supermarket';
    },100)
    this.helperService.hideModal('Add-Spare-Dialog');
    this.loadFilter();
  }

  redirect(path?) {
    window.location.href = path;
  }

  changeQuantity(quantity, spareId) {
    if (spareId == '' || spareId == null || quantity < 1) {
      return;
    }
    this.spinner.show();
    this.httpService.postRequest("refurbOperationApi/v1/updateQuantity?spareId=" + spareId + "&quantity=" + quantity, {}).subscribe(
      res => {
        this.spareData();
        this.isquantityError = false;

      },
      err => {
        this.quantityErrorMsg = "error occured while update spare";
        this.isquantityError = true;
        this.spinner.hide();
      }
    );

  }


  lessQuantity(quantity, id) {
    if (quantity <= 1) {
      return;
    }
    var newQuantity = quantity - 1;
    this.changeQuantity(newQuantity, id);

  }
  addQuantity(quantity, id) {
    var newQuantity = quantity + 1;
    this.changeQuantity(newQuantity, id);
  }

  addSpare() {
    if (!this.spareForm.valid) {
      this.helperService.makeFormDirty(this.spareForm);
      return;
    }
    var spare = this.spareForm.value;
    if (spare.code != undefined) {
      var codeFound = this.productcode.find(x => x.spareName == spare.code);
    }
    this.spinner.show();
    
    let url="refurbOperationApi/v1/spareDetails?code=" + codeFound.matnr12nc + "&quantity=" + spare.quantity + "&trayNO=" + this.trayNo + "&socNo=" + this.sapResponse.essocdata.socid+"&location=" + spare.storeLocation
    this.httpService.postRequest(url, {}).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          this.SpareErrorMsg = res['response'];
          this.isSpareError = true;
          this.helperService.makeFormDirty(this.spareForm);

          // setTimeout(()=>{
          //   this.isSpareError =false;
          //   this.spareForm.reset();
          // },2000)
        } else {
          if (this.sapSpare) {
            this.spares = this.sapSpare.concat(res['response']);
          } else {
            this.spares = res['response'];
          }
          this.spareCodeFlag = false;
          this.cancelSpare() ; 
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  getSpareDetails() {
    var newUrl = null;
    if (this.trayNo != null) {
      newUrl = 'checkInOperationApi/v1/getSpareItemByTrayNO?trayNO=' + this.trayNo;
    }
    else if (this.socNum != null) {
      newUrl = 'checkInOperationApi/v1/getSpareItemBySocNo?socNumber=' + this.socNum;
    } else if (this.soNum != null) {
      newUrl = 'checkInOperationApi/v1/getSpareItemBySoNo?soNumber=' + this.soNum;
    } else {
      return;
    }
    this.spinner.show();
    this.httpService.getRequest(newUrl)
      .subscribe(res => {
        const resp = res['response'];
        this.sapResponse = resp.sapResponse
        if (this.sapResponse) {
          this.SOCId = this.sapResponse.essocdata.socid;
          this.trayNo = resp.trayId;
          var dt = this.sapResponse.esproductdetails.crmpurchasedate;
          this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
          if (this.sapResponse.esproductdetails.refproduct) {
            this.productId = this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
          }
          if (this.sapResponse.ettexts) {
            this.sapEttexts = this.sapResponse.ettexts
            this.loadFilter();
          }
          this.setAction();
          this.fetchMasterData();
          this.processStatus = resp.processDetail.status;
          let postData = {
            //// "userId":"ISA User",
            //"consumerId":this.sapResponse.esproductdetails.refproduct,
            //"applianceId":this.sapResponse.esproductdetails.zz0014,
            "transactionId": this.sapResponse.essocdata.socid,
            // "userName":"",
            //"system":"",
            //"country":"",
            //"documentSource":"ISA"
          };
          this.imageShow(postData);
          this.getHistory()
          if (resp.repairDetails) {
            this.technicalRequest=resp.repairDetails.requestNo;
            let postData1 = {
              "transactionId": resp.repairDetails.requestNo, //technical request number
            };
            this.imageShowbyso(postData1);
          }
          this.loadReport();

        }
        if (resp.processDetail) {
          var dateStr = resp.processDetail.startTime;
          let dateJson = new Date(dateStr);
          var m1 = moment(dateJson).tz(this.zone).format();      // 2014-06-22T09:21:08-07:00
          let date1 = new Date(m1);
          let date2 = new Date();
          var duration = date2.getTime() - date1.getTime();
          // var difference = cdate.getTime() - dateJson.getTime();
          let startDate = (new Date().getTime()) - duration;
          this.countupTimerService.startTimer(startDate);
        } else {
          this.countupTimerService.startTimer()
        }
        this.sapSpare = resp.sapSpare;
        if (resp.spare && resp.sapSpare) {
          this.spares = this.sapSpare.concat(resp.spare);
        } else if (resp.sapSpare) {
          this.spares = this.sapSpare;
        } else {
          this.spares = resp.spare;
        }
        if (resp.clodeRequestDetail) {
          this.ClosedAction = resp.clodeRequestDetail;
          this.closeRequestData = resp.clodeRequestDetail;
          if ((this.closeRequestData.exchangeReason == '001-Missing spare parts (in & out of warr.)')) {
            this.loadMissingSpare();
          }
        }
        this.spinner.hide();
      }, err => {
       // debugger;
        this.spinner.hide();
      })
  }


  closeRefurb() {
    if (!this.socForm.valid) {
      this.helperService.makeFormDirty(this.socForm);
      return;
    }
    var socFormdata = this.socForm.value;
    this.action = "Closed the Intervention";
    // var fields = socFormdata.componentCode.split('-');
    // var compcode = fields[0];
    // var fields2 = socFormdata.defectCode.split('-');
    // var deftcode = fields2[0];
    // var fields3 = socFormdata.solutionCode.split('-');
    // var solucode = fields3[0];

    if (this.sapResponse.etservicetype)
      var serviceType = this.sapResponse.etservicetype[0].servicetype;

    let postData = {
      "componentCode": socFormdata.componentCode, "defectCode": socFormdata.defectCode, "solutionCode": socFormdata.solutionCode,  "comment": socFormdata.sComment == "Other" ? this.textComment : socFormdata.comment+"- "+this.textComment, "socId": this.sapResponse.essocdata.socid, "action": "REPA", "serviceType": serviceType, "feedback": "12"
    };
    this.closeScrap(postData);
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confromRepair();
    }
  }


  resqestPex() {
    if (!this.pexForm.valid) {
      this.helperService.makeFormDirty(this.pexForm);
      return;
    }
    var pexFormData = this.pexForm.value;
    this.action = "Requested the PEx";
    if (pexFormData.missingSpare != undefined && pexFormData.missingSpare != "") {
      var codeFound = this.productcode.find(x => x.spareName == pexFormData.missingSpare);
      var codes = codeFound.matnr12nc;
    }

    if (this.sapResponse.etservicetype)
      var serviceType = this.sapResponse.etservicetype[0].servicetype;
    if (codes != undefined) {
      let postData = {
        "componentCode": pexFormData.sComponentCode, "defectCode": pexFormData.sDefectCode, "solutionCode": pexFormData.sSolutionCode,  "comment": pexFormData.sComment =="Other"?this.textComment:pexFormData.sComment+"- "+this.textComment, "socId": this.sapResponse.essocdata.socid, "exchangeReason": pexFormData.exchangecode, "action": "PEXR", "serviceType": serviceType, "feedback": "13", "missingSpare": codes
      };
      this.closeScrap(postData);
    } else {
      let postData = {
        "componentCode": pexFormData.sComponentCode, "defectCode": pexFormData.sDefectCode, "solutionCode": pexFormData.sSolutionCode,  "comment": pexFormData.sComment =="Other"?this.textComment:pexFormData.sComment+"- "+this.textComment, "socId": this.sapResponse.essocdata.socid, "exchangeReason": pexFormData.exchangecode, "action": "PEXR", "serviceType": serviceType, "feedback": "13"
      };
      this.closeScrap(postData);
    }
  }
  onKeydownscrabEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confromPex();
    }
  }

  closeScrap(data) {
    this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/closeIntervention', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          this.refurbErrorMsg = res['errorMessage'];
          this.isrefurbError = true;
          this.helperService.openStaticModal('conform_Dialog');
        } else {
          this.uploadFlag=true;
          this.uploadData()
          this.spinner.hide();
          this.isrefurbError = false;
          this.conformMsg = this.conformMsg.replace('<action>', this.action).replace('<scoid>', this.sapResponse.essocdata.socid)
            .replace('<time>', res['response']);
          this.notPauseApi=true
          if (res['status'] == 'SUCCESS') {
            this.helperService.openStaticModal('conform_Dialog');
            // window.location.href = '#/repair';
          }
        }
      },
      err => {
        this.refurbErrorMsg = 'Some issue please try again or contact to admin!';
        this.isrefurbError = true;
        this.helperService.openModal('conform_Dialog');
        this.spinner.hide();
      }
    );
  }

  tableShow() {
    this.isTableHide = true;
    this.loadFilter();
    setTimeout(() => {
      this.spare.nativeElement.scrollIntoView();
    }, 100);
  }

  doubleCheck() {
    if (!this.pexForm.valid) {
      this.helperService.makeFormDirty(this.pexForm);
      return;
    }
    this.helperService.openModal('check_Dialog');
  }

  // IMAGE UPLOAD
  uploadImage(event: any) {
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
    this.numFiles = $("input:file")[0].files.length;
    $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      f.mediaSRC = URL.createObjectURL(files[i]);
      this.filesList.push(f);
      let jQueryInstance = this;
    }
    this.showUploadedFiles = true;
  }

  getObjectUrl(file) {
    return URL.createObjectURL(file);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  removeFile(file) {
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    let index = this.filesList.indexOf(file);
    this.filesList.splice(index, 1);
    if (this.filesList.length == 0) {
      this.webcamImage = null;
      this.helperService.hideModal("isa-current-slider");
      $('#chooseFile').val('');
    }
    //$(".isa-current-media").trigger('remove.owl.carousel', index).trigger('refresh.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
  }

  uploadData() {
    if (this.filesList.length == 0) {
      return;
    }

    var fd = new FormData();
    var applianceId = null;
    fd.append('userId', localStorage.getItem('userId'));
    fd.append('userName', this.userName);
    fd.append('system', APPCONFIG.system);
    fd.append('Region', APPCONFIG.Region);
    fd.append('country', APPCONFIG.country);
    fd.append('Language', APPCONFIG.Language);
    fd.append('documentSource', APPCONFIG.documentSource);
    fd.append('consumerId', this.sapResponse.esbpdata.partnerid);
    if (this.sapResponse.esproductdetails.productid) {
      applianceId = this.sapResponse.esproductdetails.productid.replace(/^0+/, '');
    }
    fd.append('applianceId', applianceId);
    fd.append('transactionId', this.sapResponse.essocdata.socid);
    for (var i = 0; i < this.filesList.length; i++) {
      var file = this.filesList[i]
      fd.append('files', file);
    }
    this.singleImageUpload(fd);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    var imges = this.dataURItoBlob((webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',') + 1)));
    const imageFile = new File([imges], new Date().valueOf() + "" + Math.round(Math.random() * 500) + '.jpeg', { type: 'image/jpeg' });
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel');
    $('#lightbox-current').trigger('destroy.owl.carousel');
    setTimeout(() => {
      this.isaMedia();
    }, 20);
    this.numFiles = $("input:file")[0].files.length;
    $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
    var f: any = imageFile;
    f.mediaSRC = URL.createObjectURL(imageFile);
    this.filesList.push(f);
    this.showUploadedFiles = true;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  openCamera() {
    this.cameraOpen = true;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

  }

  lightboxInit() {

    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });

    ($('#lightbox-already') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#isa-allready-slider') as any).on('shown.bs.modal', function (e) {
      $('.modal-backdrop').addClass('isa-backdrop-modal');
    });

    ($('.lightboxGallery') as any).owlCarousel({
      autoplay: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      margin: 0,
      items: 1
    });

    ($('#lightbox') as any).lightGallery({
      thumbnail: false,
      mousewheel: false,
      autoplay: false,
      share: false,
      play: false,
      hash: false,
      zoom: false,
      autoplayControls: false,
      progressBar: false

    });


  }

  isaMedia() {

    ($('#lightbox-already') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    ($('#lightbox-current') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight: true,
      items: 1
    });

    this.caoruseExist = true;

    ($('.isa-current-media') as any).owlCarousel({
      margin: 0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items: 1
    });

    ($('.isa-media-slider') as any).owlCarousel({
      loop: false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin: 0,
      items: 1
    });


  }


  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  closeCam() {
    this.cameraOpen = false;
  }

  removeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
    this.filesList = [];
    this.webcamImage = null;
  }


  uploadData1() {
    // 
    if (this.filesList.length == 0) {
      return;
    }

    let fd = new FormData();

    for (var i = 0; i < this.filesList.length; i++) {

      let file = this.filesList[i];
      var applianceId = null;
      fd.append('userId', localStorage.getItem('userId'));
      fd.append('userName', this.userName);
      fd.append('system', APPCONFIG.system);
      fd.append('Region', APPCONFIG.Region);
      fd.append('country', APPCONFIG.country);
      fd.append('Language', APPCONFIG.Language);
      fd.append('documentSource', APPCONFIG.documentSource);
      fd.append('consumerId', this.sapResponse.esbpdata.partnerid);
      if (this.sapResponse.esproductdetails.productid) {
        applianceId = this.sapResponse.esproductdetails.productid.replace(/^0+/, '');
      }
      fd.append('applianceId', applianceId);
      fd.append('transactionId', this.sapResponse.essocdata.socid);
      //this.singleImageUpload1(fd);
    }


    this.singleImageUpload1(fd);
  }
  singleImageUpload1(data) {
    //;
    this.httpService.uploadPostRequest('document/uploadDocument', data).subscribe(
      res => {
        if (res['code'] != '200') {
          this.refurbErrorMsg = res['message'];
          this.isrefurbError = true;
          // console.log(this.refurbErrorMsg);
        } else {
          this.isrefurbError = false;
          var conformMsg = res['message'];
        }
      },
      err => {
        this.refurbErrorMsg = "error occured while media upload";
        this.isrefurbError = true;
        //this.helperService.openModal('conform_Dialog');
      }
    );

  }

  singleImageUpload(data) {
    if (!this.uploadFlag) {
      console.log('upload')
      this.spinner.show();
    }
    this.httpService.uploadPostRequest('document/uploadDocument', data).subscribe(
      res => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        if (res['code'] != '200') {
          this.uploadErrorMsg = res['message'];
          //   this.isuploadError =true;
          if (!this.uploadFlag) {
            this.openInformationDialog(this.uploadErrorMsg);
          }
        } else if (res['code'] == '200') {
          //this.isuploadError = false;
          var conformMsg = res['message'];
          if (!this.uploadFlag) {
            this.openInformationDialog(conformMsg);
            this.getImages();
            this.filesList = [];
        }
        } else {
          if (!this.uploadFlag) {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        //  console.log("error occured while media upload");
        this.uploadErrorMsg = "error occured while media upload";
       // this.isuploadError = true;
        //this.helperService.openModal('conform_Dialog');
      }
    );

  }

  imageShow(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();
    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {

        this.spinner.hide();
        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
        } else {
          this.showingmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
              if (documentData[i].attachmentType != 'UPS_LABEL' &&
                (documentData[i].documentName.toLowerCase().includes('jpeg') ||
                  documentData[i].documentName.toLowerCase().includes('jpg') ||
                  documentData[i].documentName.toLowerCase().includes('png') ||
                  documentData[i].documentName.toLowerCase().includes('mp4'))
              ) {
                this.showDocument.push(documentData[i]);
              }
            }
          }
          if (this.showDocument) {
            this.callLightBox = true;

            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 1000);
          }
          this.spinner.hide();
        }
      },
      err => {
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();
      }
    );
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
  //scanner method

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.qrResults.push(this.qrResultString);
    let audio = new Audio();
    audio.play();
    audio.src = "assets/mp3/beep-02.mp3";
    audio.load();
    audio.play();
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000)
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  closeScanner() {
    this.scanStart = false;
    this.insertScanSpares();
    this.helperService.hideModal("barcodeScan");
  }

  insertScanSpares() {
    // this.qrResults.push("00123456789101112133");
    // this.qrResults.push("00123456789101112133");
    // this.qrResults.push("w123");

    if (this.qrResults.length == 0) {
      return;
    }
    let postData = {
      "soc": this.sapResponse.essocdata.socid,
      "codes": this.qrResults
    };

    this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/scanBarCodes', postData).subscribe(
      res => {
        if (res['status'] == 'ERROR') {
          this.BarScanErrorMsg = res['response'];
          this.BarScanError = true;
          this.qrResults.length = 0;
          this.spinner.hide();
          this.helperService.openStaticModal('barcode_Dialog');
        } else {
          this.BarScanError = false;
          this.scanResult = res['response'];
          this.helperService.openStaticModal('barcode_Dialog');
          this.qrResults.length = 0;
          this.spinner.hide();
        }
      },
      err => {
        this.BarScanErrorMsg = "insert appliance bar codes";
        this.BarScanError = true;
        this.qrResults.length = 0;
        this.helperService.openStaticModal('barcode_Dialog');
        this.spinner.hide();
      }
    );
  }

  spareData() {
    this.spinner.show();
    this.httpService.getRequest("refurbOperationApi/v1/sparedetailsbysocno?socNo=" + this.sapResponse.essocdata.socid).subscribe(
      res => {
        if (this.sapSpare) {
          this.spares = this.sapSpare.concat(res['response']);
        } else {
          this.spares = res['response'];
        }
        this.spareError = false;
        this.spinner.hide();
      },

      err => {
        this.spareErrorMsg = "error occured while getting soluionCode details";
        this.spareError = true;
        // console.log("error occured while getting soluionCode details");
        this.spinner.hide();
      }
    );
  }

  protected filterproductcode() {
    if (!this.productcode) {
      return;
    }
    // get the search keyword
    let search = this.pcodeFilterCtrl.value;
    if (!search) {
      this.filteredproductcode.next(this.productcode.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the productcode
    this.filteredproductcode.next(
      this.productcode.filter(pcode => pcode.name.toLowerCase().indexOf(search) > -1)
    );
  }

  spareCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/spareCode").subscribe(
      res => {
        this.productcode = res['response'];
        this.productcodeError = false;
        this.loadMissingSpare();
        this.loadFilter();
      },

      err => {
        this.productcodeErrorMsg = "error occured while getting spare Codes details";
        this.productcodeError = true;
        // console.log("error occured while getting spare Codes details");
        this.spinner.hide();
      }
    );
  }

  feedbackReasonCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/feedbackReasons").subscribe(
      res => {
        this.feedbackReasondata = res['response'];
        this.feedbackCodeError = false;
      },

      err => {
        this.feedbackCodeErrorMsg = "error occured while getting feedback code details";
        this.feedbackCodeError = true;
        // console.log("error occured while getting feedback code details");
        this.spinner.hide();
      }
    );
  }

  requestWaitngWall() {
    if (!this.wallForm.valid) {
      this.helperService.makeFormDirty(this.wallForm);
      return;
    }
    var wallFormData = this.wallForm.value;
    this.action = "Waiting Wall the Intervention";
    var fields = wallFormData.feedbackReason.split('-');
    var waitingcode = fields[0];
    if (this.sapResponse.etservicetype)
      var serviceType = this.sapResponse.etservicetype[0].servicetype;

    let postData = {
      "socId": this.sapResponse.essocdata.socid, "feedback": waitingcode, "action": waitingcode == "07" ? "WWSP" : "WAWA", "comment": wallFormData.wComment,"missingSpare": waitingcode == "07" ? wallFormData.missingSpare:"","serviceType": serviceType

    };

    this.waitingWall(postData);
  }
  onKeydownwaitingEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confromWaitingWall();
    }
  }
  waitingWall(data) {
    this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/waitingWall', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          this.refurbErrorMsg = res['errorMessage'];
          this.isrefurbError = true;
          this.helperService.openStaticModal('conform_Dialog');
        } else {
          this.uploadData()
          this.spinner.hide();
          this.isrefurbError = false;
          this.conformMsg = this.conformMsg.replace('<action>', this.action).replace('<scoid>', this.sapResponse.essocdata.socid)
            .replace('<time>', res['response']);
          this.notPauseApi=true
          if (res['status'] == 'SUCCESS') {
            this.helperService.openStaticModal('conform_Dialog');
            // window.location.href = '#/repair';
          }
        }
      },
      err => {

        this.refurbErrorMsg = 'Some issue please try again or contact to admin!';
        this.isrefurbError = true;
        this.helperService.openModal('conform_Dialog');
        this.spinner.hide();
      }
    );
  }
  StopTimer() {
    this.spinner.show();
    if (!this.sapResponse) {
      this.spinner.hide();
      return;
    }
    this.httpService.getRequest("refurbOperationApi/v1/timerStop?socNo=" + this.sapResponse.essocdata.socid).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          // console.log("timer Pause",res['status']);
        } else {
          // console.log("timer Pause",res['status']);
        }
      },
      err => {
        // console.log("error occured while close refurb");
        this.spinner.hide();
      }
    );

  }
  setData() {

    if (this.ClosedAction.action == "REPA") {
      const d = { 'solutionCode': this.ClosedAction.solutionCode, 'componentCode': this.ClosedAction.componentCode, 'defectCode': this.ClosedAction.defectCode, 'comment': this.ClosedAction.comment };
      this.socForm.setValue(d);
      this.socForm.disable();
    }

    if (this.ClosedAction.action == "PEXR") {
      const d = { 'sSolutionCode': this.ClosedAction.solutionCode, 'sComponentCode': this.ClosedAction.componentCode, 'sDefectCode': this.ClosedAction.defectCode, 'sComment': this.ClosedAction.comment, 'exchangecode': this.ClosedAction.exchangeReason };
      this.pexForm.setValue(d);
      this.pexForm.disable();
    }
  }

  imageShowbyso(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();
    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {

        this.spinner.hide();
        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
        } else {
          this.showingmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length > 0) {
            for (var i = 0; i < documentData.length; i++) {
              if (documentData[i].attachmentType != 'UPS_LABEL' &&
                (documentData[i].documentName.toLowerCase().includes('jpeg') ||
                  documentData[i].documentName.toLowerCase().includes('jpg') ||
                  documentData[i].documentName.toLowerCase().includes('png') ||
                  documentData[i].documentName.toLowerCase().includes('mp4'))
              ) {
                this.showDocument.push(documentData[i]);
              }
            }
          }
          if (this.showDocument) {
            this.callLightBox = true;

            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 1000);
          }
          this.spinner.hide();
        }
      },
      err => {
        //  console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();
      }
    );

  }

  imageShowUPS(data) {     
    //this.spinner.show();

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
          this.showImage = true;
        } else {
          this.showingmediaError = false;
          this.showDoc = res['data'];
          if (this.showDoc && this.showDoc.length > 0) {
            this.imageURL = this.showDoc[0].urlMediaLink;
            //this.callLightBox = true;
            this.showImage = false;
            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 100);
          } else {
            this.showImage = true;
          }
          //this.spinner.hide();

        }
      },
      err => {
        // console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();

      }
    );
  }


  imageShowReport(data) {     
    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
          this.isDoc = true;
        } else {
          this.showingmediaError = false;
          this.showDocuments = res['data'];
          if (this.showDocuments && this.showDocuments.length > 0) {
            for(let i=0;i<this.showDocuments.length;i++)
            {
              if(this.showDocuments[i].documentName.match(/.(pdf)$/i))
              {
                  
                  this.showDocuments.sort((a,b) => +new Date(a.documentUploadDate) - +new Date(b.documentUploadDate));

                  
                   this.pdfDownload.push(this.showDocuments[i]); 
                 //  this.arr.push(this.pdfDownload[i].documentName);
                   
              }
            }
          } 
            //console.log(this.arr);

if(this.pdfDownload.length>0){
          
 this.pdfDownload=this.pdfDownload.reverse();
 this.result.push(this.pdfDownload[0]);
 console.log(this.result);

 for(var i=1;i<this.pdfDownload.length;i++){
   if(this.pdfDownload[i].documentName == this.pdfDownload[i-1].documentName)
   {
              continue;
   }
   else{
              this.result.push(this.pdfDownload[i]);
   }
 }
 //console.log(this.result);
 

 		var en_flag:boolean=false;	
     var it_flag:boolean=false;
     var nl_flag:boolean=false;
     var fr_flag:boolean=false;
     var de_flag:boolean=false;				
 for(var i=0;i<this.result.length;i++){
  if(this.result[i].documentName.search("EN.pdf") !== -1 && en_flag==false)
  {
    this.temp.push(this.result[i]);
    en_flag =true;
  }
  else if(this.result[i].documentName.search("IT.pdf") !== -1 && it_flag==false)
  {
    this.temp.push(this.result[i]);
    it_flag =true;

  }
  else if(this.result[i].documentName.search("NL.pdf") !== -1 && nl_flag==false)
  {
    this.temp.push(this.result[i]);
    nl_flag =true;

  }
  else if(this.result[i].documentName.search("FR.pdf") !== -1 && fr_flag==false)
  {
    this.temp.push(this.result[i]);
    fr_flag =true;

  }
  else if(this.result[i].documentName.search("DE.pdf") !== -1 && de_flag==false)
  {
    this.temp.push(this.result[i]);
    de_flag =true;

  }
  else{
    continue;
  }
}
console.log(this.temp); 
//this.reportURL=this.temp[0].urlMediaLink;


            
            this.isDoc = false;
            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 100);
          } else {
            this.isDoc = true;
          }
          

        }
      },
      err => {
        
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();

      }
    );
  }

  downloadd(ind) {

    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%"+ind)
    for(var i=0;i<this.temp.length;i++){
  if(this.temp[i].documentName==ind){
    this.reportURL=this.temp[i].urlMediaLink;
    this.downloadReport();
  }
  }
  }
  
  downloadReport() { 
  window.open(this.reportURL);
}
  
  changeFocus(event, index) {
    if (index == 1)
      this.el.nativeElement.focus();
    if (index == 2)
      this.el1.nativeElement.focus();
    if (index == 3)
      this.el2.nativeElement.focus();
    if (index == 4)
      this.el3.nativeElement.focus();
    if (index == 5)
      this.el4.nativeElement.focus();
    if (index == 6)
      this.el5.nativeElement.focus();
    if (index == 7)
      this.el6.nativeElement.focus();
    if (index == 8)
      this.el7.nativeElement.focus();
  }


  open(trigger) {
    trigger.openPanel()
  }
  loadMissingSpare() {
    if ((this.closeRequestData && this.closeRequestData.exchangeReason == '001-Missing spare parts (in & out of warr.)' && (this.productcode != undefined && this.productcode.length > 0))) {
      var codeFound = this.productcode.find(x => x.matnr12nc == this.closeRequestData.missingSpare);
      this.missingSpareData = codeFound.spareName;
    }
  }

  onselectModelValue(desc) {
    //  var codeFound =  this.sapEttexts.find(x => x.tdid == desc);
    const d = { 'text': desc };
    this.textForm.patchValue(d);
    this.textForm.controls['text'].disable();
  }

  confromWaitingWall(){
    if (!this.wallForm.valid) {
      this.helperService.makeFormDirty(this.wallForm);
      return;
    }
    var msg='Do you confirm this item has to go to Waiting Wall?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
       this.requestWaitngWall()
      } 
    });
   }
   
   openconfromationDialog(msg) {
    this.dialogref =   this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true, 
      data: {
        message :msg
      },
      panelClass: ['no-padding', 'custom-openconfromDialog','custom-mat-dialog']
    });
  }

  confromPex() {
    if (!this.pexForm.valid) {
      this.helperService.makeFormDirty(this.pexForm);
      return;
    }
    var msg='Do you confirm this item has to be PEXed?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
       this.resqestPex()
      } 
    });
  }
  confromRepair() {
    if (!this.socForm.valid) {
      this.helperService.makeFormDirty(this.socForm);
      return;
    }
    var msg='Do you confirm this item is repaired?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
       this.closeRefurb();
      } 
    });
  }
  getMoreInfo() {
    if((this.technicalRequest === undefined || this.technicalRequest === null) ){
      var msg='Technical Request id is not available to complete this request.';
      this.openInformationDialog(msg);
    }
    this.spinner.show();
    this.httpService.getRequest('refurbOperationApi/v1/moreDetials?Request='+this.technicalRequest+'&soc='+this.SOCId)
        .subscribe(
            res => {
                let data = res;
                this.spinner.hide()
                if (data['status'] == 'SUCCESS') {
                  //  var msg = data['response'];
                    var msg = "Email sent successfully.";
                    this.openInformationDialog(msg);
                    // this.dialogref.afterClosed().subscribe(result => {
                    //     if (result) {
                    //     console.log(result);    
                    //     }
                    // });
                } else {
                   if (res['status'] == 'ERROR') {
                         var msg =   data['response'];
                         this.openInformationDialog(msg);
                      } else {
                        var msg  = 'Failed to process request. Please contact administrator.';
                        this.openInformationDialog(msg);
                      }
                }
            },
            err => {
                var errorMsg = err;
                this.spinner.hide()

            });

            
}
openInformationDialog(msg){
  this.dialogref = this.dialog.open(ConfirmationDialogComponent)
  .addPanelClass(['no-padding', 'custom-openconfromDialog','custom-mat-dialog'] )
  this.dialogref.componentInstance.msg = msg;
  this.dialogref.componentInstance.isNotConformation = true;            
}

confromMoreInformation() {
  var msg='Do you confirm you need more information?';
  this.openconfromationDialog(msg);
  this.dialogref.afterClosed().subscribe(result => {
    if(result){
      console.log(result);
     this.getMoreInfo();
    } 
  });
}

fetchMasterData(){
  this.getFamily();
  if(!this.productFamily){
    var msg = 'Failed to get product family in this request.';
    this.openInformationDialog(msg);
    this.mailMissingSpare();
  }
    this.productFamily=this.productFamily==undefined?"":this.productFamily;    
    this.componentCodeData(this.productFamily);
    this.defectCodeData(this.productFamily);
    this.solutionCodeData();
    this.exchangeCodeData();
 
  this.feedbackReasonCodeData();
}
focusOutCommentCode(code) {
  if (code != undefined) {
    var codeFound = this.CommentCodedata.find(x => x.description == code);
    if (codeFound != undefined && code == codeFound.description) {
      this.commentCodeFlag = true;
      if (codeFound.description == "Other") {
        this.showCommentBox = true;
      }else{
        this.showCommentBox = false;
      }
    }
    else {
      this.commentCodeFlag = false;
      this.showCommentBox = false;
    }
    console.log( "flag", this.commentCodeFlag )
  }
}

CommentCodeData(component) {
  this.spinner.show();
   this.httpService.getRequest("refurbOperationApi/v1/commentCode?modelNo="+this.productFamily+"&code="+component).subscribe(
    res => {
      this.spinner.hide();
      if (res['status'] == 'SUCCESS') {
        this.CommentCodedata = res['response'];
        this.loadCommentFillter();
      } else {
        if (res['status'] == 'ERROR') {
          var msg = res['response'];
          this.openInformationDialog(msg);
        } else {
          var msg = 'Failed to process request. Please contact administrator.';
          this.openInformationDialog(msg);
        }
      }
    },
    err => {
      var errorMsg = err;
      this.spinner.hide();
    
    }
  );
}
loadCommentFillter(){
  this.closebCommentfiltered = this.socForm.get('comment').valueChanges
  .pipe(
    startWith(''),
    map(value => this.Commentfiltered(value))
  );
  this.pexbCommentfiltered = this.pexForm.get('sComment').valueChanges
  .pipe(
    startWith(''),
    map(value => this.Commentfiltered(value))
  );
}
private Commentfiltered(value: string): string[] {
  if ((value === undefined || value === null) || (this.CommentCodedata === undefined || this.CommentCodedata === null))
    return;
  const filterValue = value.toLowerCase();
  var data = this.CommentCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
    option1.description.toLowerCase().indexOf(filterValue) > -1);
  return data

}

getCommentCodeVerified(event) {
 this.commentCodeFlag = event.isUserInput;
  if (event.isUserInput) {    
    this.focusOutCommentCode(event.source.value)
  }
}

pexfocusOutCommentCode(code) {
  if (code != undefined) {
    var codeFound = this.CommentCodedata.find(x => x.description == code);
    if (codeFound != undefined && code == codeFound.description) {
      this.pexcommentCodeFlag = true;
      if (codeFound.description == "Other") {
        this.pexshowCommentBox = true;
      }else{
        this.pexshowCommentBox = false;
      }
    }
    else {
      this.pexcommentCodeFlag = false;
      this.pexshowCommentBox = false;
    }
  }
}
pexCommentCodeVerified(event) {
  this.pexcommentCodeFlag = event.isUserInput;
   if (event.isUserInput) {    
     this.pexfocusOutCommentCode(event.source.value)
   }
 }
 getFamily(){
  console.log("Action"+this.productFamily);
  if( this.sapResponse && this.sapResponse.etadditionalfields && JSON.stringify( this.sapResponse).indexOf('FAMILY') > -1){
    for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
      if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FAMILY')
       this.productFamily  =this.sapResponse.etadditionalfields[i].value ;  
      console.log(this.productFamily);
      }
  } 
}

fetchApplianceHistory() {
  let postData = {
    "socNumber": this.sapResponse.essocdata.socid,
    "serialNumber": this.sapResponse.esproductdetails.zz0014
    };
  this.spinner.show();
  this.httpService.postRequest("refurbOperationApi/v1/getHistory", postData).subscribe(
    res => {
      this.spinner.hide();
      if (res['responseCode'] == '200') {
        var applianceHistory = res['applianceHistory'];
        var count = applianceHistory.length;
        if(count>1){
          this.applianceHistory=true;
          this.historyMsg = "The Product has already been repaired "+count+" times";
          this.openStaticInformationDialog( this.historyMsg);
        }
        } else {
        if (res['responseCode'] == '400') {
          var msg = res['errorMessage'];
         // this.openInformationDialog(msg);
         console.log(msg);
        }
        else {
          var msg = 'Failed to process request. Please contact administrator.';
          this.openInformationDialog(msg);
        }
      }
    },
    err => {
      var errorMsg = err;
      this.spinner.hide();

    }
  );
}
getHistory(){
  if( this.sapResponse && this.sapResponse.etadditionalfields && JSON.stringify(this.sapResponse).indexOf('HISTORY') > -1){
    for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
      if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'HISTORY')
     {
      var count = this.sapResponse.etadditionalfields[i].value ;  
      if(count>1){
      this.applianceHistory=true;
      this.historyMsg = "The Product has already been repaired "+count+" times";
      this.openStaticInformationDialog( this.historyMsg);
      }
     }
    }
  } 
}

openStaticInformationDialog(msg) {
  this.dialogref = this.dialog.open(WarningDialogComponent,{disableClose:true})
    .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog','waring-dialogbox'])
  this.dialogref.componentInstance.msg = msg;
  this.dialogref.componentInstance.isNotConformation = true;
}

setAction(){
  console.log("Action"+this.actionPerformed);
  if( this.sapResponse && this.sapResponse.etadditionalfields && (JSON.stringify( this.sapResponse).indexOf('FLOW') > -1 || JSON.stringify( this.sapResponse).indexOf('HOLDER') > -1 || JSON.stringify( this.sapResponse).indexOf('PACKING') > -1 )){
    this.soldToId=this.sapResponse && this.sapResponse.etadditionalfields[0].soldto?this.sapResponse.etadditionalfields[0].soldto:'';

    for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
      if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FLOW')
       this.actionPerformed  =this.sapResponse.etadditionalfields[i].value ;  
       if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'HOLDER')
       this.holder  =this.sapResponse.etadditionalfields[i].value ;
       if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'PACKING')
       this.packing  =this.sapResponse.etadditionalfields[i].value ;
      }
  } 
}

getImages(){
  this.showDocument=[];
  let postData = {
     "transactionId": this.sapResponse.essocdata.socid, //this.sapResponse.essocdata.socid
  };
  this.imageShow(postData);
  if(this.technicalRequest){
      let postData1 = {
          "transactionId": this.technicalRequest, //technical request number
      };
      this.imageShowbyso(postData1);
    }
  }


deleteMedia(userId,ref,name){
  // let postData = {
  //   "userId": userId,
  //   "userName": name,
  //   "documentRef": ref
  // };
  var data = new FormData();
  data.append('userId',userId); 
  data.append('userName',name);
  data.append('documentRef', ref);
  console.log('data', data)
  this.spinner.show();
  this.httpService.uploadPostRequest('document/deleteDocument',data).subscribe(
  res => {
      this.spinner.hide();
      if (res['code'] == '200') {
        var msg = res['message'];
        this.openInformationDialog(msg);
        this.getImages();
      } else {
        if (res['code'] == '400') {
          var msg = res['message'];
          this.openInformationDialog(msg);
        }else if (res['code'] == '500') {
          var msg = res['message'];
          this.openInformationDialog(msg);
        }else if (res['code'] == '600') {
          var msg = res['message'];
          this.openInformationDialog(msg);
        }
        else {
          var msg = 'Failed to process request. Please contact administrator.';
          this.openInformationDialog(msg);
        }
      }
    },
    err => {
      var errorMsg = err;
      this.spinner.hide();

    }
  );
}     

  mailMissingSpare() {
    this.spinner.show();
    this.httpService.getRequest("refurbOperationApi/v1/missingfamily?soc=" + this.sapResponse.essocdata.socid + "&productCode=" + this.productId).subscribe(
      res => {
        this.spinner.hide();
        console.log("response", res['status']);
        if (res['status'] == 'SUCCESS') {
          var response = res['response'];
          console.log("response", response);
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      }
    );
  }

  confromChangelocation(location, spareId) {
    var msg = 'Do you confirm you need to update spare part location?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
       this.changelocation(location, spareId);
      }
    });
  }

  changelocation(location, spareId) {
    if (spareId == '' || spareId == null || location == '' || location == null ) {
      return;
    }
    this.spinner.show();
    this.httpService.getRequest("refurbOperationApi/v1/updatesaprelocation?spareId=" + spareId + "&location=" + location).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'SUCCESS') {
          this.spareData();
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();

      }
    );
  }

  downloadImage() {  
    window.open(this.imageURL);
  }
  
  
   printInvoice(): void {
    this.printContents = document.getElementById("imageDiv").innerHTML;
    const originalContents = document.body.innerHTML;    
    this.printContents=this.printContents.replace('custom-width=""','width="750px" height="550px"')    
    setTimeout(()=>{
      this.popupWin = window.open();
      var is_chrome = Boolean(this.popupWin.chrome);
      this.popupWin.document.open();
      this.popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles/main.css" /></head><body onload="">' + this.printContents + '</html>');
      if(is_chrome){
        setTimeout(()=>{
          this.popupWin.print();
          this.popupWin.document.close();
        },1000)
      }else{
              this.popupWin.print();
              this.popupWin.document.close();
        }

    }) 
  }

  loadReport(){
    if(this.processStatus  != 'PROCESSING_END'){
      return
    }
    if(this.technicalRequest){
    let postDataups = {
      "transactionId":  this.technicalRequest, 
       "attachmentType": "UPS_LABEL"
     };
   
     this.imageShowUPS(postDataups);           //call servicedoc api for ups
    }
     let postDataReport = {
       "transactionId": this.sapResponse.essocdata.socid, 
     };
     this.imageShowReport(postDataReport);      //call servicedoc api for report
  }

  async printPDF(urlPdf) {
    this.spinner.show();
    let response = await fetch(urlPdf);
    let data = await response.blob();
    var blob = new Blob([data], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    // this.pdfURL= this.sanitizer.bypassSecurityTrustResourceUrl();
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
    this.spinner.hide();
  }
  waitingWallMissingSpare(code) {
    if (code == undefined || code == "") {
      this.waitngMissingspareError = true;
      this.waitngMissingspareFlag = false;
    }
    if (code != undefined && code != "") {
      this.waitngMissingspareFlag = false;
      var codeFound = this.productcode.find(x => x.spareName == code);
      if (codeFound != undefined && code == codeFound.spareName) {
        this.waitngMissingspareFlag = true;
      }
      else {
        this.waitngMissingspareFlag = false;
      }
    }
  }
  waitingWallMissingSpareVerified(event) {
    this.waitngMissingspareFlag = event.isUserInput;
    this.waitngMissingspareError = false;
  }
  focus(){
    this.focusInput.nativeElement.focus();
  }
}


