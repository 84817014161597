import { Component, OnInit,ViewChild, Inject, ChangeDetectorRef} from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'app/helper.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { debug } from 'util';
import { APPCONFIG } from '../config';
import {DialogComponent,ConfirmDialogComponent} from '../dialog/dialog.component'

export interface Food {
  value: string;
  viewValue: string;
}
export interface DialogData {
  animal: string;
}

@Component({
  selector: 'app-trays',
  templateUrl: './trays.component.html',
  styleUrls: ['./trays.component.scss'],

})


export class TraysComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  
  
  traysList: any=[];
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  displayedColumns = ['id', 'trayStatus', 'socNumber', 'swaping','action'];
  dataSource: MatTableDataSource<any>;

  userLDapResonseRoles: any;
  isError:boolean;
  result: any;
  permissions =[];
  
  constructor(private helperservice: HelperService, private spinner: NgxSpinnerService, private httpService:HttpService, public dialog: MatDialog, private router : Router) {
    
      this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));    
     /*       
      if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
       ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
      ) 
      {
      }else{
        
        if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
        ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
        ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
        ){  
          this.router.navigate(['/repair']);
        }else{
          this.router.navigate(['/common']);
        }
         
      }
     */
      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['tray'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag)
        this.router.navigate(['/common']);


      if (this.httpService.loginCheckSession()) { }
      else {
          this.httpService.showSessionError();
          
      }
  }
  ngOnInit() {
    this.fetchTraysDetail();
  }

  
  /*  *
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  setPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  
  fetchTraysDetail(){
    this.spinner.show();
    this.httpService.getRequest('checkOutOperationApi/v1/freetrays').subscribe(
      res => {
        this.spinner.hide();
        this.traysList= res['response'];
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.traysList);
      this.setPaginator();
     },
      err => {
        alert("Internal issue to fetch the information");
        this.spinner.hide();
      }
    );
  }


  dialog1: any;
  animal: any;
  prevTrayid: any;
  
  openDialog(oldTrayId, socNumber,trayStatus) {
    if(socNumber!=undefined){
      socNumber=socNumber;
    }else{
      socNumber='';
    }
    //this.prevTrayid = oldTrayId;
    //const dialogRef = this.dialog.open(DialogOverviewExampleDialog);
   //debugger;
  let filterTraysList: any=[];
  if(trayStatus=="FREE"){
    for(var i = 0; i<this.traysList.length; i++){   
      if(this.traysList[i].trayStatus != "FREE"){   
       filterTraysList.push(this.traysList[i]);      
       }
   }
  }else{
    for(var i = 0; i<this.traysList.length; i++){
      if(this.traysList[i].id != oldTrayId){ 
        filterTraysList.push(this.traysList[i]);      
      }
    }
  }
  // for(var i = 0; i<this.traysList.length; i++){ 
  //   if(this.traysList[i].id != oldTrayId){  
  //     filterTraysList.push(this.traysList[i]);   
  //   }
  // }  
  //filterTraysList=this.traysList;
   
    localStorage.setItem("traysList",JSON.stringify(filterTraysList));
    this.helperservice.applianceList=filterTraysList;
    for(var i = 0; i<filterTraysList.length; i++){
      //console.log(filterTraysList[i].trayStatus);
    }
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {     
      disableClose: true, 
      data: { dialog1:filterTraysList, prevTrayid:oldTrayId, socNumber: socNumber, trayStatus: trayStatus}
     
    });
    
    dialogRef.beforeClosed().subscribe(result => {
         });
  }

   reloadComponent(){
    this.fetchTraysDetail();
  }
  
  clearTray(trayId){
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/clearTray?trayId='+trayId+"&type="+"traysManagement")
   .subscribe(res=>{
    if(res['status']=='ERROR'){
      this.spinner.hide();
      var refurbErrorMsg=res['response'];  //res['response'];
      this.openconfromDialog(refurbErrorMsg);
  
    }else{
      this.spinner.hide();
        var confirmMsg=res['response'];
        this.fetchTraysDetail();
        this.openconfromDialog(confirmMsg);
      }
  }, err => {
    this.spinner.hide();
  
  })
  }
 
  openconfromDialog(msg) {
    this.dialog.open(DialogComponent, {
      disableClose: true, 
      data: {
        message :msg
      }
    });
  }

    openconaddSocDialog(id) {
      const dialog =  this.dialog.open(AddSocDialog, {
        disableClose: true, 
        data: {
          trayid:id
        }
      });
      dialog.afterClosed().subscribe(result => {
        if(result){
          this.fetchTraysDetail();
        } 
      });
    }
  

    confirmClearAllTrays(message): void {
     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true, 
      maxWidth: "400px",
        data: {
          message:message
        }
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        this.result = dialogResult;
       if(this.result){
        this.clearAllTrays();
       }
      });
    }
  
  

    confirmClearTray(message,trayId): void {
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{
         disableClose: true, 
         maxWidth: "400px",
         data: {
           message:message
         }
       });
       dialogRef.afterClosed().subscribe(dialogResult => {
         this.result = dialogResult;
        if(this.result){
         this.clearTray(trayId);
        }
       });
     }
   


    
    clearAllTrays(){
      this.spinner.show();
      this.httpService.getRequest('checkInOperationApi/v1/clearAllTrays')
      .subscribe(res=>{
        if(res['status']=='ERROR'){
         this.spinner.hide();
         var refurbErrorMsg=res['response'];  //res['response'];
         this.openconfromDialog(refurbErrorMsg);
       }else{
         this.spinner.hide();
           var confirmMsg=res['response'];
           this.openconfromDialog(confirmMsg);
            this.fetchTraysDetail();
          }
     }, err => {
       this.spinner.hide();
     })   
    }
  


}

@Component({
  templateUrl: './dialogtrays.component.html',
})
export class DialogOverviewExampleDialog {
  quantity1: string='';
  newSocnumber: string='';
  newTrayId: string='';
  newTrayStatus='';
  tray2: any;
  confirmMsg:string;
  refurbErrorMsg: string;
  isrefurbError: boolean;
  isError:boolean;
  confirmDialogAgain = false; 
  traysList: any=[];
  filterTraysList: any=[];
  trayId1:string;
  trayId2:string;
  constructor(private helperservice: HelperService, private cdRef: ChangeDetectorRef,public dialog: MatDialog, private helperService:HelperService,public router:Router, private httpService:HttpService,
    
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {           
      
      this.filterTraysList = JSON.parse(localStorage.getItem("traysList"));         
      localStorage.removeItem("traysList");   

    }
  onNoClick(): void {    
    this.dialogRef.close();
  }
  myControl = new FormControl();    
  //options: string[] = ['101','102','103'];  
  
  filteredOptions: Observable<string[]>;
  options=[];
  ngOnInit() { 
  
    for(var i=0;i<this.filterTraysList.length;i++){    
      this.options.push((this.filterTraysList[i].id).toString());      
    }
    //this.filterTraysList = localStorage.getItem("traysList");         
    //localStorage.removeItem("traysList");
    //this.filterTraysList=this.helperService.applianceList   
   // console.log(this.filterTraysList[0]);
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] { 
  const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
 
  t1(event):void {  
    
  }
  confirmDialog() {    
    this.confirmDialogAgain = true;
    this.cdRef.detectChanges();
   
  }
  test(trayId1): void{      
    let postDataOLD = {
      // "oldTrayId": obj.prevTrayid,
      // "oldSocNo": obj.socNumber,
      // "oldTrayStatus":obj.trayStatus,
      // "newTrayId":this.newTrayId,
      // "newSocNo":this.newSocnumber,
      // "newTrayStatus":this.newTrayStatus
    };    
    let postData = {
      "oldTrayId": trayId1,      
      "newTrayId":this.trayId2,     
    };  
    this.httpService.postRequest('checkInOperationApi/v1/updateTraySwap', postData).subscribe(
      res => {
        this.isrefurbError = false;
        this.isError=false;
        this.confirmMsg="Tray Swapped Successfully ";
        this.helperService.openStaticModal('conform_Dialog');
        
        //this.router.navigate(['/scrap']);
        //window.location.href = '#/trays';
        //document.location.reload();
        //this.router.navigate(['/trays']);
        //window.location.reload();
     },

      err => {
        this.isrefurbError = true;
        this.isError=true;
        alert("Internal issue to fetch the information");
      }
    );
    
   this.dialogRef.close();
  }
  

  onClickOk(){
    
    
  }
}



@Component({
  templateUrl: './addSoc.component.html',
})
export class AddSocDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>,private httpService:HttpService,private spinner: NgxSpinnerService,public dialog: MatDialog) {}
  socNumber:any="";
  ngOnInit() {
  
  }
  
  onClose(): void {    
    this.dialogRef.close(false);
  }    

  addSoc(){
    if((this.socNumber =='' || this.socNumber ==undefined ) ||  this.socNumber.toString().length != 10)
    {
      var msg="The SOC Number inserted is not valid, please proceed by inserting a valid one"
      this.openconfromDialog(msg);
      return;
    }
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/updateSoc?trayId='+this.data.trayid+"&socNumber="+this.socNumber)
    .subscribe(res=>{
      this.dialogRef.close(true);
      if(res['status']=='ERROR'){
       this.spinner.hide();
       var refurbErrorMsg=res['response'];  //res['response'];
       this.openconfromDialog(refurbErrorMsg);
   
     }else{
       this.spinner.hide();
         var confirmMsg=res['response'];
         this.openconfromDialog(confirmMsg);
        }
   }, err => {
     this.spinner.hide();
   })
  }
  openconfromDialog(msg) {
    this.dialog.open(DialogComponent, {
      disableClose: true, 
      data: {
        message :msg
      }
    });
  }



}