import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from '../shared/services/http.service';
import { MatAutocompleteTrigger, MatDialog } from '@angular/material';
import { HelperService } from 'app/helper.service';
import {DialogComponent,ConfirmDialogComponent} from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-soc-create-manually',
  templateUrl: './soc-create-manually.component.html',
  styleUrls: ['./soc-create-manually.component.scss']
})
export class SocCreateManuallyComponent implements OnInit {
  // @ViewChild(MatAutocompleteTrigger, {static:true}) inputAutoComplit;
  // @ViewChild(MatAutocompleteTrigger, {static:true}) tradeAutoComplit;

  requestNo: any;
  requestForm: FormGroup;
  dataEancode: any;
  dataEancodeFilter: any;
  modelNumberFlag:boolean = true;
  Mnumber:any;
  tradeNumber:any;
  returnReason:any;
  qntyVal: String;
  modelNumberError: boolean =false;
  saveInspectionResponse: any;
  inspectionConfromMsg: any;
  tradeData: any;
  reasonCode: any;
  tradeCodeFilter: any;
  returnReasonFilter: any;
  tradeCodeFlag: boolean = false;
  returnReasonFlag: boolean= false;
  tradeNumberError: boolean =false;
  returnReasonError: boolean=false;
  userLDapResonseRoles: any;
  permissions: any;

  constructor(private location: Location,private commonService: CommonService, public dialog: MatDialog, private route: ActivatedRoute,private fb: FormBuilder, private httpservice: HttpService, private spinner: NgxSpinnerService,private helperService: HelperService,private router: Router) { 
    this.route.queryParams.subscribe(params => {
      this.requestNo = params['requestNo'];
    });
    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));     
    // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')
    //       ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //       ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 
    //   {
    //   }else{
    //       this.router.navigate(['/common']);
    //   }

      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['createSOC'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.location.back();
      }
      if (this.httpservice.loginCheckSession()) { }
      else {
          this.httpservice.showSessionError();
      }
      if((this.requestNo==null || this.requestNo==undefined || this.requestNo=="")){
        this.router.navigate(['checkin']);
        return;
       }
       this.fetchEancode();
       this.tradeCodeData();
       this.retuenCodeData();
   }

  ngOnInit() {
    this.requestForm = this.fb.group({
      // serialNumber: ['', [Validators.required, Validators.pattern(/^([W|w]{1}[A-Za-z0-9]{1}(([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})|([W|Y|w|y]{1}[A-Za-z]{1}[A-Za-z0-9](([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})$/)]],
     // serialNumber: ['', [Validators.required, Validators.pattern(/^((229)$)|(([y|Y]{1}[A-Za-z0-9]{1}(([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{2})|([W|w|Y|y]{1}[A-Za-z0-9]{1}[A-Za-z0-9](([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}))$/)]],
     // serialNumber: ['', [Validators.required, Validators.pattern(/^((229)$)|(([W|w]{1}[A-Za-z0-9]{1}(([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})|([W|Y|w|y]{1}[A-Za-z]{1}[A-Za-z0-9](([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}))$/)]],
        serialNumber: ['', [Validators.required, Validators.pattern(/^((229)$)|^((000)$)|(([y|Y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{2})|([W|w|Y|y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})|([W|w|Y|y]{1}[A-Za-z0-9]{1}[A-Za-z0-9](([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}))$/)]],
        trayNumber: ['', [Validators.required, Validators.min(100), Validators.max(999), Validators.minLength(3),Validators.maxLength(3) ]],
       modelNO: ['', [Validators.required]],
       item12NC: ['', [Validators.required]],
       tradeId: ['', [Validators.required]],
       returnReason: ['', [Validators.required]],
     
      });
    this.loadFilter();
    }

    // openPanel(evt): void {
    //   evt.stopPropagation();
    //   this.inputAutoComplit.openPanel();
    // }
    // opentrade(evt): void {
    //   evt.stopPropagation();
    //   this.tradeAutoComplit.openPanel();
    // }


    loadFilter() {
      this.dataEancodeFilter = this.requestForm.get('modelNO').valueChanges
      .pipe(
        startWith(''),
        map(val => this._filter(val))
      );
      this.tradeCodeFilter = this.requestForm.get('tradeId').valueChanges
      .pipe(
        startWith(''),
        map(val => this.tradeFilter(val))
      );

      this.returnReasonFilter = this.requestForm.get('returnReason').valueChanges
      .pipe(
        startWith(''),
        map(val => this.returnFilter(val))
      );
    }
    
    private returnFilter(value: string): string[] {
      if ((value === undefined || value === null ) || (this.reasonCode === undefined || this.reasonCode === null ))
      return ;
      const filterValue = value.toLowerCase();
     var data= this.reasonCode.filter(option => String(option.reason).toLowerCase().includes(filterValue));
     return data;
    }
    
    
    private tradeFilter(value: string): string[] {
      if ((value === undefined || value === null ) || (this.tradeData === undefined || this.tradeData === null ))
      return ;
      const filterValue = value.toLowerCase();
     var data = this.tradeData.filter(option1 => String(option1.tradeId).toLowerCase().indexOf(filterValue) > -1 ||
     option1.tradeDescription.toLowerCase().indexOf(filterValue) > -1);
     return data;
    }

      private _filter(value: string): string[] {
        if ((value === undefined || value === null ) || (this.dataEancode === undefined || this.dataEancode === null ))
        return ;
        const filterValue = value.toLowerCase();
        var data = this.dataEancode.filter(option => String(option.matnr12nc).toLowerCase().indexOf(filterValue ) > -1 || 
        option.commCode.toLowerCase().indexOf(filterValue ) > -1);
        return data
      }
    
      focusOutModelNumber(code){
        this.modelNumberError =false;
        if(code == null || code == ""){code = undefined;}
       if(code != undefined ){
       var codeFound =  this.dataEancode.find(x => x.commCode == code);
       if(codeFound != undefined && code == codeFound.commCode){
        this.modelNumberFlag = true;
       }
       else{
        this.modelNumberFlag = false;
        this.modelNumberError =true;
       }
      }
     }
     
     focusOutTradeId(code) {
      this.tradeNumberError =false;
      if (code != undefined) {
        var codeFound = this.tradeData.find(x => x.tradeDescription == code);
        if (codeFound != undefined && code == codeFound.tradeDescription) {
          this.tradeCodeFlag = true;
        }
        else {
          this.tradeCodeFlag = false;
          this.tradeNumberError =true;
        
        }
      }
    }

    focusOutReturnReason(code) {
      this.returnReasonError =false;
      if (code != undefined) {
        var codeFound = this.reasonCode.find(x => x.reason == code);
        if (codeFound != undefined && code == codeFound.reason) {
          this.returnReasonFlag = true;
        }
        else {
          this.returnReasonFlag = false;
          this.returnReasonError =true;
     
        }
      }
    }

    onSelectTrade(event){
      this.tradeCodeFlag = true;
      this.tradeNumberError =false;
  
    }

    onSelectReturnReason(event){
      this.returnReasonFlag = true;
      this.returnReasonError = false;
    }

     onselectModelNumerValue(event){
      this.modelNumberError =false;
      this.modelNumberFlag = event.option._selected;
      var codeFound =  this.dataEancode.find(x => x.commCode == event.option.value);
      if(codeFound != undefined && event.option.value == codeFound.commCode){
      const d={'item12NC': codeFound.matnr12nc};
     this.requestForm.patchValue(d);
     this.requestForm.controls['item12NC'].disable();
      }
    }
    
        fetchEancode(){
              this.spinner.show();
              this.httpservice.getRequest('checkInOperationApi/v1/getEanCodeModel?modelCode='+'').subscribe(
                res => {
                  this.spinner.hide();
                  this.dataEancode=res['response']
                  if(this.dataEancode.length > 0){
                  this.loadFilter();
                }
                },
                err => {
                  alert("Invalid request");
                  this.spinner.hide();
            }
              );
            }


            openconfromDialog(msg) {
              this.dialog.open(DialogComponent, {
                disableClose: true, 
                data: {
                  message :msg
                }
              });
            }
           
            createSoc(){
              this.requestForm.controls['item12NC'].enable();
              this.requestForm.controls['tradeId'].enable();
              this.requestForm.controls['returnReason'].enable();
              
              if (this.requestForm.invalid) {
                this.helperService.makeFormDirty(this.requestForm);
                return;
              }
              this.spinner.show();
               var inspFrom = this.requestForm.value;
            //    if (inspFrom.tradeId != undefined) {
              //   var codeFound = this.tradeData.find(x => x.spareName == inspFrom.tradeId);
              //}
              var strModel =inspFrom.tradeId ;
              var res = strModel.split(" ");
              let postData = {
                "trayId" :inspFrom.trayNumber,
                "serialNo":inspFrom.serialNumber,
                "requestId":this.requestNo,
                "partnerId":res[0],
                "item12NC":inspFrom.item12NC,
                "modelNO":inspFrom.modelNO,
                "returnReason":inspFrom.returnReason,
              };
              this.httpservice.postRequest('/checkInOperationApi/v1/inspectionManually',postData)
              .subscribe(res=>{
                this.spinner.hide();
                if(res['status']=='ERROR'){
                  this.inspectionConfromMsg= res['response'];
                  this.requestForm.controls['item12NC'].disable();
                  this.requestForm.controls['tradeId'].disable();
                  this.requestForm.controls['returnReason'].disable();
                  this.helperService.openStaticModal('ConfirmDialog');
                }else{
                  this.saveInspectionResponse=res['response'];
                  this.inspectionConfromMsg="Successfully created SOC Number "+this.saveInspectionResponse.evsocid+" the appliance "+inspFrom.serialNumber+" for Trade Partner "+inspFrom.tradeId; 
                  this.helperService.openStaticModal('ConfirmDialog');
                  this.requestForm.reset();
                  this.setTradePartner(inspFrom.tradeId,inspFrom.returnReason);
                  
                }
          
              },err=>{
                this.requestForm.controls['item12NC'].disable();
                this.requestForm.controls['tradeId'].disable();
                this.requestForm.controls['returnReason'].disable();
                this.spinner.hide();
              });
            } 

            confirm(){
              this.router.navigate(['checkin']);
            }         
            setTradePartner(tradeId,reason){
              const d={'tradeId': tradeId,'returnReason': reason};
              this.requestForm.patchValue(d);
              this.requestForm.controls['tradeId'].disable();
              this.requestForm.controls['returnReason'].disable();
              this.loadFilter();  
            }
            

              tradeCodeData(){
                this.httpservice.getRequest("checkInOperationApi/v1/TradeId").subscribe(
                  res => {
                  this.tradeData=res['response'];
                   //this.loadFilter();
                  },
                  err => {
                    // console.log("error occured while getting spare Codes details");
                    this.spinner.hide();
                  }
                );
              }
              
              retuenCodeData(){
                this.httpservice.getRequest("checkInOperationApi/v1/getReasonCode").subscribe(
                  res => {
                  this.reasonCode=res['response'];
                 this.loadFilter();
                  },
                  err => {
                    // console.log("error occured while getting spare Codes details");
                    this.spinner.hide();
                  }
                );
              }



}
