import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from '../helper.service';
import { HttpService } from '../shared/services/http.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { MatSnackBar } from '@angular/material';
import { APPCONFIG } from '../config';
import { PermissionService } from 'app/shared/services/permission.service';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-repair-refurb',
  templateUrl: './repair-refurb.component.html',
  styleUrls: ['./repair-refurb.component.scss']
})
export class RepairRefurbComponent implements OnInit {

  trayNum: String = "";
  socNum: String = "";
  soNum: String = "";
  isError: boolean = false;
  isErrorMsg: string = '';
  isError1: boolean = false;
  isError2: boolean = false;
  healthcheck: string = "";
  isErrorMsg2: string = "";
  isErrorMsg1: string = "";
  disabledTab: boolean = false;
  trayNumberFlag: boolean = false;
  userLDapResonseRoles: any;

  dsbTogl() {
    // debugger;
    this.disabledTab = !this.disabledTab;
    this.step = 1;
    if (!this.disabledTab) {
      this.step = 0;
      //this.disabledTab = false ;
    }
  }
  favoriteSeason: string;

  seasons: string[] = ['SO Number', 'SOC Number'];
  step = 0;
  permissions = [];
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step = 0;
    this.disabledTab = !this.disabledTab;
  }

  constructor(private location: Location,private commonService: CommonService, private _snackBar: MatSnackBar, private helperService: HelperService, private httpservice: HttpService, private permissionsService: PermissionService
    , private route: Router, private spinner: NgxSpinnerService) {

    /* 
     this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));     
     if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')
         ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
         ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 
     {
     }else{
       //this.router.navigate(['/login']);
       //localStorage.clear();
       // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
       // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
       // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
       // ){  
       // this.route.navigate(['/scrap']);
       // }else{
         this.route.navigate(['/common']);
       // }
    }
    */
    this.checkPermissions()
    if (this.httpservice.loginCheckSession()) { }
    else {
      this.httpservice.showSessionError();
    }
  }
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 4000,
      data: {
        icon: 'Close'
      },
      panelClass: ['isa-snackbar']
    });

  }
  ngOnInit() {
    this.favoriteSeason = 'SO Number';
  }

  async checkPermissions() {
    let permissionFlag = false;
    const resp = this.permissionsService.permissionData?this.permissionsService.permissionData : await this.permissionsService.checkPermissions();
    let permissions = resp['items']
    if (this.permissions !== null)
      permissions['repair_refurb'].toLowerCase() == 'true' ? permissionFlag = true : permissionFlag = false;
      permissions['repaire/refurb-trayid'].toLowerCase() == 'true'? this.trayNumberFlag = true : this.trayNumberFlag = false;

      if (!this.trayNumberFlag) {
        this.setStep(1);
      }
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.location.back();
      }

  }

  getSpareDetails() {
    var self = this;
    if (this.trayNum.toString().length != 3) {
      this.isError = true;
      self.openSnackBar("The Tray Number inserted is not valid, please proceed by inserting a valid one", "Close")
      return;
    }
    this.spinner.show();
    this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemByTrayNO?trayNO=' + this.trayNum)//+"&type="+this.type)
      .subscribe(res => {
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          // this.isErrorMsg = 'The Tray Number is free or do not exist. Please contact the Administrator';
          
          this.isError = true;
          let errorMsg = res['response'] || 'Something went wrong! Please try again later.';
          self.openSnackBar(errorMsg, "Close")
          // this.helperService.openModal('conform_Dialog');
        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
          if (sapResSerTypeDes.includes('refurb')) {
            this.route.navigate(['refurb-info'], { queryParams: { trayId: this.trayNum } });
          }
          else if (sapResSerTypeDes.includes('repair')) {
            this.route.navigate(['repair-info'], { queryParams: { trayId: this.trayNum } });
          }
          else if (sapResSerTypeDes.includes('healthcheck')) {
            this.route.navigate(['healthcheck-info'], { queryParams: { trayId: this.trayNum } });
          }
          else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
            this.route.navigate(['extensiverepair-info'], { queryParams: { trayId: this.trayNum } });
          }
          else {
            this.isError = true;
            self.openSnackBar("Please contact the Support Team", "Close")
            this.isErrorMsg = 'Please contact the Support Team';
            // this.helperService.openModal('conform_Dialog');
          }
        }
        this.spinner.hide();
      }, err => {
        this.isError = true;
        this.isErrorMsg = 'Internal Issue. Please contact the Support Team';
        self.openSnackBar("Internal Issue. Please contact the Support Team", "Close")

        // this.helperService.openModal('conform_Dialog');
        this.spinner.hide();
      })
  }
  getSOCInfo() {
    let self = this;
    if (!this.socNum || (this.socNum.toString().length != 10)) {
      self.openSnackBar("The SOC Number inserted is not valid, please proceed by inserting a valid one", "Close")
      return;

    }
    this.spinner.show();
    this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemBySocNo?socNumber=' + this.socNum)//+"&type="+this.type)
      .subscribe(res => {
        this.helperService.hideModal('TextBoxDialog');
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          this.isErrorMsg1 = 'An Error occurred on the SOC Document, please contact the Support Team';
          this.isError1 = true;
          let errorMsg = res['response'] || 'Something went wrong! Please try again later.';
          self.openSnackBar(errorMsg, "Close")
          // this.helperService.openModal('conform_Dialog');
        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
          if (sapResSerTypeDes.includes('refurb')) {
            this.route.navigate(['refurb-info'], { queryParams: { socId: this.socNum } });
          }
          else if (sapResSerTypeDes.includes('repair')) {
            this.route.navigate(['repair-info'], { queryParams: { socId: this.socNum } });
          }
          else if (sapResSerTypeDes.includes('healthcheck')) {
            this.route.navigate(['healthcheck-info'], { queryParams: { socId: this.socNum } });
          }
          else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
            this.route.navigate(['extensiverepair-info'], { queryParams: { socId: this.socNum } });
          }
          else {
            this.isError1 = true;
            this.isErrorMsg1 = 'Please contact the Support Team';
            // this.helperService.openModal('conform_Dialog');
            self.openSnackBar("Please contact the Support Team", "Close")
          }

        }
        this.spinner.hide();
      }, err => {
        this.isError1 = true;
        this.isErrorMsg1 = 'Internal Issue, please contact the Support Team';
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        }
        else
          self.openSnackBar("Internal Issue, please contact the Support Team", "close");
        this.spinner.hide();

      })
  }

  getSOInfo() {
    let self = this;
    if ((!this.soNum || (this.soNum.toString().length != 10)) || !(this.soNum.toString().startsWith('621'))) {
      self.openSnackBar("The SO Number inserted is not valid, please proceed by inserting a valid one", "Close")
      return;
    }
    this.spinner.show();
    this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemBySoNo?soNumber=' + this.soNum)//+"&type="+this.type)
      .subscribe(res => {
        this.helperService.hideModal('TextBoxDialog');
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          this.isError2 = true;
          this.isErrorMsg2 = 'An Error occurred on the SO Document, please contact the Support Team';
          // this.helperService.openModal('conform_Dialog');
          let errorMsg = res['response'] || 'Something went wrong! Please try again later.';
          self.openSnackBar(errorMsg, "Close");
        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
          if (sapResSerTypeDes.includes('refurb')) {
            this.route.navigate(['refurb-info'], { queryParams: { soId: this.soNum } });
          }
          else if (sapResSerTypeDes.includes('repair')) {
            this.route.navigate(['repair-info'], { queryParams: { soId: this.soNum } });
          }
          else if (sapResSerTypeDes.includes('healthcheck')) {
            this.route.navigate(['healthcheck-info'], { queryParams: { soId: this.soNum } });
          }
          else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
            this.route.navigate(['extensiverepair-info'], { queryParams: { soId: this.soNum } });
          }
          else {
            this.isError2 = true;
            this.isErrorMsg2 = 'please contact the Support Team';
            // this.helperService.openModal('conform_Dialog');
            self.openSnackBar("Please contact the Support Team", "Close")
          }
        }
        this.spinner.hide();
      }, err => {
        this.isError2 = true;
        this.isErrorMsg2 = 'Internal Issue. Please contact the Support Team';
        // this.helperService.openModal('conform_Dialog');
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        }
        else
          self.openSnackBar("Internal Issue. Please contact the Support Team", "close")
        this.spinner.hide();
      })
  }



  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getSpareDetails();
      //this.getTrayDetail();
    }
  }
  onKeydownEventSo(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getSOInfo();
      //this.getTrayDetail();
    }
  }
  onKeydownEventSoc(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getSOCInfo();
      //this.getTrayDetail();
    }
  }


}
