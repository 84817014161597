import { Injectable } from '@angular/core';

@Injectable()
export class SessionHandler {
    public prevUrl:any;

    constructor() { }

    saveItem(key,value){
        localStorage.setItem(key,value);
    }

    getItem(key){;
        return localStorage.getItem(key);
    }

    removeItem(key){
        localStorage.removeItem(key);
    }

    clearAllItems(){
        localStorage.clear();
    }

    setRedirectionUrl(prevUrl: any) {
        this.prevUrl = prevUrl;
    }

    getRedirectionUrl() {
        return this.prevUrl;
    }
}