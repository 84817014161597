import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import TsMap from 'ts-map';
import { Subject } from 'rxjs/Subject';
import { Observable } from "rxjs/Rx";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private permissionMap: TsMap<string, boolean> = new TsMap<string, boolean>();
    companyId='';
    constructor(private httpClient: HttpService,private spinner: NgxSpinnerService) {
        this.getCompanyId();
     }
     public oncompanyChange = new EventEmitter();

     public companyChanged() {
         this.oncompanyChange.emit('company changed');
     }

    permissionData:any;
    //function not used -

    checkPermission(permissionList: string[]): Observable<TsMap<string,boolean>> {
        let roleIds = JSON.parse(
            localStorage.getItem("loggedUserRoles")
        ).sysRoleIds;
        let result: Subject<TsMap<string, boolean>> = new Subject<TsMap<string, boolean>>();
        let resultMap: TsMap<string, boolean> = new TsMap<string, boolean>();
        if (permissionList.length > 0) {
            let req = { "roleIds": roleIds, "uiUUIDList": permissionList };
            this.httpClient.validatePermissions('loginOperationApi/v1/validatePermission', req).subscribe(res => {
                permissionList.forEach(re => {
                    resultMap.set(re, res.uiUUIDMap[re]);
                    this.permissionMap.set(JSON.stringify(re), JSON.parse(JSON.stringify(res.uiUUIDMap[re])));
                });
                result.next(resultMap);
            });
        } else {
            result.next(resultMap);
        }
        return result;
    }

    async checkPermissions(){
        this.spinner.show();
        if(localStorage.getItem('loginSessionExit') && localStorage.getItem('loggedUserRoles')){
            var roleIds = JSON.parse(
                localStorage.getItem("loggedUserRoles")
            ).sysRoleIds;
            let req = { "roleIds": roleIds };
            return new Promise((resolve,reject) => {
              this.httpClient.validatePermissions('loginOperationApi/v1/validatePermission', req).subscribe((res:any) => {
                if(res){
                    this.permissionData = res;
                  resolve(res);
                  this.spinner.hide();
                }  
              }, (err) => {
                this.spinner.hide();
                reject(err);
              })
            })
        }

        }
    getCompanyId(){
        let companyId='';
        if(localStorage.getItem('loginSessionExit')){
            if(localStorage.getItem('UserRole')=="SA"){
                this.companyId = localStorage.getItem('companyId');
            }else{
                this.companyId = JSON.parse(localStorage.getItem("loggedUserRoles")).company.id;
            }
        }
        this.companyChanged();
        // return companyId.toString();
    }    
}

// Permission code with uuid's

// efe2b37e-51a8-11ec-b4b3-42010a840003	    DashBoard	                    dashboad
// efe2bc0d-51a8-11ec-b4b3-42010a840003	    DashBoard - View More Reports	dashboad.view_more_reports
// efe2bd98-51a8-11ec-b4b3-42010a840003	    DashBoard - Repair/D2C Report	dashboad.repair_d2c_reports
// efe2bdd2-51a8-11ec-b4b3-42010a840003	    DashBoard - Return Report	    dashboard.return_report
// efe2be01-51a8-11ec-b4b3-42010a840003	    DashBoard - Manager Report	    dashboard.manage_report
// efe2be2d-51a8-11ec-b4b3-42010a840003	    Receiving (check-in)	        receiving
// efe2be5b-51a8-11ec-b4b3-42010a840003	    Repair/Refurb	                repair_refurb
// efe2be83-51a8-11ec-b4b3-42010a840003	    Packing (Checkout)	            packing
// efe2bebc-51a8-11ec-b4b3-42010a840003	    Scrap	                        scrap
// efe2bee1-51a8-11ec-b4b3-42010a840003	    Tray Management	                tray
// efe2bf05-51a8-11ec-b4b3-42010a840003	    Logs	                        logs
// efe2bf26-51a8-11ec-b4b3-42010a840003	    Tray Logs	                    tray_logs

