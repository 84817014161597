import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'app/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from 'app/shared/services/http.service';
import { Router } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-trays-logs',
  templateUrl: './trays-logs.component.html',
  styleUrls: ['./trays-logs.component.scss']
})
export class TraysLogsComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  requestForm: FormGroup;
  traysList: any=[];
  displayedColumns = ['id', 'createdOn', 'socNumber', 'userName','trayInfo','trayStatus'];
  dataSource: MatTableDataSource<any>;
  data: any;
  trayFilter: any;
  userFilter: any;
  userLDapResonseRoles: any;
  InitFlag:boolean = true;  
  minDate = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  trayInfo: any=[];
  uname: any=[];
  permissions =[];
  constructor(private fb: FormBuilder,private helperservice: HelperService, private spinner: NgxSpinnerService, private httpService:HttpService, private router : Router ) {
   console.log("tray component initialised")
   
    this.minDate.setDate(this.minDate.getDate() - 2);
    this.bsRangeValue = [this.minDate, this.maxDate];
   /*
    this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));    
            
    if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
     ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
    ) 
    {
    }else{
      
      if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
      ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
      ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
      ){  
        this.router.navigate(['/repair']);
      }else{
        this.router.navigate(['/common']);
      }
       
    }
 */
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['tray_logs'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag)
    this.router.navigate(['/common']);

    if (this.httpService.loginCheckSession()) { }
    else {
        this.httpService.showSessionError();
        
    } 

  }

  ngOnInit() {
    this.requestForm = this.fb.group({
      endDate: [this.maxDate, [Validators.required]],
      startDate: [this.minDate, [Validators.required]],
      userName: ['', []],
      trayNumber: ['', []],
     });
    
  this.fetchTraysHistoryDetail();
   

  this.trayFilter = this.requestForm.get('trayNumber').valueChanges
  .pipe(
    startWith(''),
    map(value => this._filter(value))
  );

  this.userFilter = this.requestForm.get('userName').valueChanges
  .pipe(
    startWith(''),
    map(val => this._filter1(val))
  );

}

  /*  *
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  setPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchTraysHistoryDetail(){
    if (this.requestForm.invalid) {
      this.helperservice.makeFormDirty(this.requestForm);
      return;
    }
    this.spinner.show();
    var socFormdata = this.requestForm.value;
    let postData ={
       "startDate":socFormdata.startDate,
       "endDate":socFormdata.endDate, 
    //   "startDate":'2019-07-07T18:30:00.000Z',
    //  "endDate":'2020-07-13T18:30:00.000Z', 
      "userName":socFormdata.userName,
      "trayid":socFormdata.trayNumber,
    };
    this.httpService.postRequest('checkInOperationApi/v1/traylogs',postData).subscribe(
      res => {
        this.spinner.hide();
        this.traysList= res['response'];
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.traysList);
        this.setPaginator();
        //this.data=this.traysList;
        this.uname=[];
        this.trayInfo=[]; 
        this.getDuplicatesData(this.traysList);
      },
      err => {
        alert("Internal issue to fetch the information");
        this.spinner.hide();
      }
    );
  }



  dateFilterChanged(bsRangeValue: string) {
    //console.log('filterValue', bsRangeValue);
    const d={'startDate': bsRangeValue[0],'endDate': bsRangeValue[1]};
    this.requestForm.patchValue(d);
    //this.fetchTraysHistoryDetail();
  }

  getDuplicatesData(trayData){
  //  console.log(trayData.length)
    for(var i=0;i<trayData.length;i++){
      
      if(this.trayInfo.length == 0 || !(this.trayInfo.includes(trayData[i].trayInfo)))  
    {
      this.trayInfo.push(trayData[i].trayInfo);
    } 
    if(this.uname.length == 0 || !(this.uname.includes(trayData[i].userName)))  
    {
      this.uname.push(trayData[i].userName);
    } 

    }
  }




  private _filter(value: string): string[] {
    if ((value === undefined || value === null) || (this.trayInfo === undefined || this.trayInfo === null))
    return;
    const filterValue = value.toLowerCase();
    return this.trayInfo.filter(option => String(option).toLowerCase().includes(filterValue));
  }

  private _filter1(value: string): string[] {
    if ((value === undefined || value === null) || (this.uname === undefined || this.uname === null))
    return;
    const filterValue = value.toLowerCase();
    return this.uname.filter(option => String(option).toLowerCase().includes(filterValue));
  }


}
