import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTabChangeEvent, MatTableDataSource, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { APPCONFIG } from 'app/config';
import { HelperService } from 'app/helper.service';
import { HttpService } from 'app/shared/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'app/dialog/dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-missing-spares-report',
  templateUrl: './missing-spares-report.component.html',
  styleUrls: ['./missing-spares-report.component.scss'],
  providers:[DatePipe]
})
export class MissingSparesReportComponent implements OnInit {

 
  // @ViewChild(MatSort,{static:true}) sort: MatSort;

  @ViewChild('closedSort',{static:true}) closedSort: MatSort;
  @ViewChild('openSort',{static:true}) openSort: MatSort;

  @ViewChild('closedPaginator',{static:true}) closedPaginator : MatPaginator;
  @ViewChild('openPaginator',{static:true}) openPaginator : MatPaginator;


  // @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;
  title = "Repair/D2C Report";
  searchVal = '';
  displayedColumns: string[] = ['soc','soNumber','tray','wwReasonDescription','wwReason','timeOnWW','operator','socCreationDate', 'waitingWallDate','lastUpdatedOn','lastupdatedBy'];
  columnsToDisplay: string[] = ['soc','soNumber','tray','wwReasonDescription','wwReason','timeOnWW','operator','socCreationDate', 'waitingWallDate','lastUpdatedOn','lastupdatedBy'];
  columnsMapping = {
    "SOC Number": "soc",
    "Technical Request/Return Number": "soNumber",
    "Tray Number": "tray",
    "Missing Spare/Comment": "wwReasonDescription",
    "Waiting Wall Reason": "wwReason",
    "Time On Waiting Wall": "timeOnWW",
    "Operator": "operator",
    "SOC Creation Date":'socCreationDate',
    "Watting Wall Date": "waitingWallDate",
    "Last Updated On":"lastUpdatedOn",
    "Last Updated By":"lastupdatedBy",
    
  };
  // dataSource : MatTableDataSource<any>;
  closedSocDataSource : MatTableDataSource<any>;
  openSocDataSource : MatTableDataSource<any>;
  dialogref: any;
  data: any;
  zone: string;
  minDate = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  maxDatesocCreationDate=new Date();
  minDatesocCreationDate=new Date();
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  length = 100;
  pageSize = 50;
  latest_date: any;
  userLDapResonseRoles: any;
  flag:boolean=true;
  reportURL: string=APPCONFIG.REPORT_URL;
  repairURL: string=APPCONFIG.HOMEPAGE;
  productcode: any;
  requestStatus:any;
  missingSpare:any;
  soc:any;
  soNumber:any;
  closeSoc:any= [];
  openSoc:any= [];
  tabIndex=0;
  applyFilter(filterValue: string) {
   // const filterValue = event.target.value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    this.openSocDataSource.filter = filterValue.trim().toLowerCase();
    this.closedSocDataSource.filter = filterValue.trim().toLowerCase();

    // if(this.tabIndex==0){
    //   this.openSocDataSource.filter = filterValue.trim().toLowerCase();
    // }else if(this.tabIndex==1){
    //   this.closedSocDataSource.filter = filterValue.trim().toLowerCase();
    // }
  }
  
  clearFilters(){
    this.searchVal='';
    // this.dataSource.filter = '';

    this.closedSocDataSource.filter='';
    this.openSocDataSource.filter='';
 }

  constructor(private httpService: HttpService, private helperService: HelperService, private route: ActivatedRoute, private httpservice: HttpService, private spinner: NgxSpinnerService, private sanitizer:DomSanitizer,private router: Router,public dialog: MatDialog, private datePipe:DatePipe)  {
    this.minDate.setDate(this.minDate.getDate() - 30);
    this.minDatesocCreationDate.setDate(this.minDatesocCreationDate.getDate() - 30);

    
    // let old_date =this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
      this.latest_date =this.datePipe.transform(this.maxDate, 'yyyy-MM-dd  h:mm:ss');
    this.bsRangeValue = [ this.minDate, this.maxDate];

    this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));    
            
    if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
     ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
    ) 
    {
    }else{
      
      if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
      ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
      ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
      ){  
        this.router.navigate(['/repair']);
      }else{
        this.router.navigate(['/common']);
      }
       
    }

    if (this.httpService.loginCheckSession()) { }
    else {
        this.httpService.showSessionError();
        
    } 
   }

   noSorting() {
    return 0;
  }
  ngOnInit() {
    this.spareCodeData();
    let postData ={
      // "startDate":"" ,
      // "endDate":"",
      "soc":"",
      "requestStatus":"",
      "missingSpare":"",
      "socStartDate":this.bsRangeValue[0],
      "socEndDate":this.bsRangeValue[1],
      "limit":"0"
      };

    this.fetchData(postData);
    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  }
  spareCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/spareCode").subscribe(
      res => {
        console.log(res)
        this.productcode = res['response'];
        // this.productcodeError = false;
        // this.loadFilter();
        // this.loadMissingSpare();
      },

      err => {
        // this.productcodeErrorMsg = "error occured while getting spare Codes details";
        // this.productcodeError = true;
        this.spinner.hide();
      }
    );
  }
  // loadMissingSpare() {
  //   if ((this.closeRequestData && this.closeRequestData.exchangeReason == '001-Missing spare parts (in & out of warr.)' && (this.productcode != undefined && this.productcode.length > 0))) {
  //     var codeFound = this.productcode.find(x => x.matnr12nc == this.closeRequestData.missingSpare);
  //     this.missingSpareData = codeFound.spareName;
  //   }
  // }
  private _filterSpareCode(value: string): string[] {
    if ((value === undefined || value === null) || (this.productcode === undefined || this.productcode === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.productcode.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option1.spareName.toLowerCase().indexOf(filterValue) > -1);
    return data
  }
  fetchData(data){
    this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/getMissingSpareData',data).subscribe(
      res => {
      this.closeSoc=[];
      this.openSoc=[];
        this.spinner.hide();
        if (res['responseCode'] == '200') {
          this.data= res['items'];
          // this.dataSource = new MatTableDataSource(this.data);
          if (res['itemCount']==0) {
            var msg = "No Records were found for the selected date";
            this.openInformationDialog(msg);
          }else{
            console.log(this.data,"++++++++++++");
            console.log(this.data.length,"++++++++++++");
            for(var i=0;i< this.data.length; i++){
              console.log(this.data[i].timeOnWW);
              if(this.data[i].timeOnWW === "Request is open"){
                this.openSoc.push(this.data[i]);
                console.log(this.openSoc[i]);
              }else{
                this.closeSoc.push(this.data[i]);
                console.log(this.closeSoc[i]);
              }
            }
            
            this.openSocDataSource = new MatTableDataSource(this.openSoc);
            this.closedSocDataSource = new MatTableDataSource(this.closeSoc);
            this.dataSort()
            console.log(this.openSoc.length,this.openSoc,"**********");
            console.log(this.closeSoc.length,this.closeSoc,"###########");
          }



        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      }
    );
  }     
  // updateStatus(data){
  //   this.spinner.show();
  //   this.httpService.postRequest('refurbOperationApi/v1/updatestatus',data).subscribe(
  //   res => {
  //       this.spinner.hide();
  //       if (res['responseCode'] == '200') {
  //         let postData ={
  //           "startDate": this.bsRangeValue[0],
  //           "endDate":this.bsRangeValue[1],
  //           "soc":"",
  //           "requestStatus":"",
  //           "missingSpare":"",
  //           "socStartDate":"",
  //           "socEndDate":"0",
  //           "limit":"0"
  //           };
  //           this.fetchData(postData)
  //         if (res['itemCount']==0) {
  //           var msg = "No Records were found for the selected date";
  //           this.openInformationDialog(msg);
  //         }
  //       } else {
  //         if (res['code'] == '400') {
  //           var msg = res['message'];
  //           this.openInformationDialog(msg);
  //         }else if (res['code'] == '500') {
  //           var msg = res['message'];
  //           this.openInformationDialog(msg);
  //         }else if (res['code'] == '600') {
  //           var msg = res['message'];
  //           this.openInformationDialog(msg);
  //         }
  //         else {
  //           var msg = 'Failed to process request. Please contact administrator.';
  //           this.openInformationDialog(msg);
  //         }
  //       }
  //     },
  //     err => {
  //       var errorMsg = err;
  //       this.spinner.hide();
  //     }
  //   );
  // }     

  dataSort() {
    // this.dataSource.sort = this.sort;
    
    this.openSocDataSource.sort =this.openSort;
    this.closedSocDataSource.sort =this.closedSort;
    this.openSocDataSource.paginator = this.openPaginator;
    this.closedSocDataSource.paginator = this.closedPaginator;
  }

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }
  // openUpdateDialog(data) {
  //   console.log(data)
  //   this.dialogref = this.dialog.open(MissingSpareUpdateComponent,{ panelClass: 'date-dialog-container' })
  //     .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
  //     this.dialogref.componentInstance.selected = data.requestStatus.toLowerCase();
  //     this. dialogref.afterClosed().subscribe(res => {
  //       console.log(res.value);
  //      if(res&&res.value){
  //        let dto={
  //         soc:data.soc,
  //         statusToUpdate:res.value.status.toUpperCase(),
  //         missingSpare:data.missingSpare
  //        }
  //       this.updateStatus(dto);
  //      }
  //     })
  // }
  openFilterDialog() {
    this.dialogref = this.dialog.open(MissingSpareFilterComponent,{ panelClass: 'date-dialog-container' })
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
  // this.dialogref.componentInstance.coloumn = this.displayedColumns;
    // this.dialogref.componentInstance.maxDate = this.maxDate;
    // this.dialogref.componentInstance.minDate = this.minDate;
    this.dialogref.componentInstance.maxDatesocCreationDate = this.maxDatesocCreationDate;
    this.dialogref.componentInstance.minDatesocCreationDate = this.minDatesocCreationDate;
    this.dialogref.componentInstance.productcode = this.productcode;
    this.dialogref.componentInstance.requestStatus = this.requestStatus;
    this.dialogref.componentInstance.missingSpare = this.missingSpare;
    this.dialogref.componentInstance.soc = this.soc;
    this.dialogref.componentInstance.soNumber = this.soNumber;
    console.log(this.dialogref.componentInstance)
    this. dialogref.afterClosed().subscribe(res => {
      console.log(res.value);
     if(res&&res.value){
       res.value.requestStatus?res.value.requestStatus=res.value.requestStatus.toUpperCase():'';
      this.fetchData(res.value);
       this.bsRangeValue = [res.value.socStartDate, res.value.socEndDate];
      //  this.minDate=res.value.startDate;
      //  this.maxDate=res.value.endDate;
       this.minDatesocCreationDate=res.value.socStartDate;
       this.maxDatesocCreationDate=res.value.socEndDate;
       this.requestStatus=res.value.requestStatus;
       this.missingSpare=res.value.missingSpare;
       this.soc=res.value.soc;
       this.soNumber=res.value.soNumber;
     }
    })
  }

  setPageSizeOptions(event) {
   console.log(event)
   console.log('coloumn',this.flag);
  let newOffset = event.pageSize * event.pageIndex;
  let postData ={
    // "startDate":"" ,
    // "endDate":"",
    "soc":"",
    "requestStatus":"",
    "missingSpare":"",
    "socStartDate":this.bsRangeValue[0],
    "socEndDate":this.bsRangeValue[1],
    "limit":"0",
    "offSet":newOffset
    };
   
   if(this.flag){
     this.fetchData(postData);
    }
  }

  openDashboard() { 
    window.open( this.reportURL, "_blank");
}
routeSOC(){
  const link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: 6220293315 } }));
 window.open(link, '_blank');
}
getSOCInfo(soc) {
  if (!soc || (soc.toString().length != 10 )) {
    this.openInformationDialog("The SOC Number inserted is not valid, please proceed by inserting a valid one");
     return;
  }
  this.spinner.show();
  this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemBySocNo?socNumber=' + soc)//+"&type="+this.type)
    .subscribe(res => {
      this.spinner.hide();
      const resp = res['response'];
      if (res['status'] == 'ERROR') {
        var msg = 'Failed to process request. Please contact administrator.';
        this.openInformationDialog(msg);
      }
      else {
        const sapRes = resp['sapResponse'];
        this.helperService.sapResponse = res;
        const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
        if (sapResSerTypeDes.includes('refurb')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['refurb-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['refurb-info'], { queryParams: { socId: soc } });

        }
        else if (sapResSerTypeDes.includes('repair')) {
          //  var link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: soc } }));
          //  link ='/#'+link;
          //   window.open(link, '_blank');
          this.router.navigate(['repair-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('healthcheck')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['healthcheck-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['healthcheck-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
          // var link = this.router.serializeUrl(this.router.createUrlTree(['extensiverepair-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['extensiverepair-info'], { queryParams: { socId: soc } });
        }
        else {
          var msg = 'The Request type is invalid.Please contact administrator.';
          this.openInformationDialog(msg);
        }

      }
  
    }, err => {
      this.spinner.hide();
      if (err.status === 401) {
        this.openInformationDialog("Please login again")
      }
      else{
        var msg = 'Internal Issue.Please contact administrator.';
        this.openInformationDialog(msg);  
      }
    })
}
tabChanged(tabChangeEvent: MatTabChangeEvent): void {
  this.tabIndex = tabChangeEvent.index;
}
}
@Component({
  templateUrl: './missing-spare-filter.component.html',
  styleUrls: ['./missing-spares-report.component.scss']

})
export class MissingSpareFilterComponent implements OnInit {
  //coloumn: string[];
  filterForm:FormGroup;
  maxDate: any;
  minDate: any;
  maxDatesocCreationDate: any;
  minDatesocCreationDate: any;
  bsRangeValue: any[];
  bsSocCreationRangeValue:any[];
  productcode:any[];
  missingMasterCode:any=[];
  requestStatus:any="";
  missingSpare:any="";
  soc:any="";
  soNumber:any="";
  constructor(@Inject(MAT_DIALOG_DATA) public info: any,public dialogRef: MatDialogRef<MissingSpareFilterComponent>,private fb: FormBuilder, private router: Router) {
  
  }
  
  ngOnInit() {
    console.log(this.maxDate)
    console.log(this.minDate)
    this.bsRangeValue = [this.minDate, this.maxDate];
    this.bsSocCreationRangeValue=[this.minDatesocCreationDate, this.maxDatesocCreationDate];
     this.filterForm = this.fb.group({
      soc: [this.soc],
      soNumber: [this.soNumber],
      requestStatus: [this.requestStatus],
      missingSpare:[this.missingSpare],
      socStartDate:[this.minDatesocCreationDate, [Validators.required]],
      socEndDate: [this.maxDatesocCreationDate, [Validators.required]],
      // startDate:[this.minDate, [Validators.required]],
      // endDate: [this.maxDate, [Validators.required]],
    });  
    console.log(this.filterForm)
    this.loadFilter();
  }
  private _filterSpareCode(value: string): string[] {
    if ((value === undefined || value === null) || (this.productcode === undefined || this.productcode === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.productcode.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option1.spareName.toLowerCase().indexOf(filterValue) > -1);
    return data
  }
  onselectValue(){
    console.log('coloumn');
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  dateFilterChanged(bsRangeValue: string) {
    const d={'startDate': bsRangeValue[0],'endDate': bsRangeValue[1]};
    this.filterForm.patchValue(d);
  }
  socCreationdateFilterChanged(bsSocCreationRangeValue: string) {
    const d={'socStartDate': bsSocCreationRangeValue[0],'socEndDate': bsSocCreationRangeValue[1]};
    this.filterForm.patchValue(d);
  }

  close(){
    console.log('coloumn',this.filterForm.value);
    this.dialogRef.close(this.filterForm);
  }
  loadFilter(){
    this.missingMasterCode = this.filterForm.get('missingSpare').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSpareCode(value))
      );
      console.log(this.missingMasterCode)
  }
}
@Component({
  templateUrl: './missing-spare-update.component.html',
  styleUrls: ['./missing-spares-report.component.scss']

})
export class MissingSpareUpdateComponent implements OnInit {
  //coloumn: string[];
  updateForm:FormGroup;
  selected;

  constructor(@Inject(MAT_DIALOG_DATA) public info: any,public dialogRef: MatDialogRef<MissingSpareUpdateComponent>,private fb: FormBuilder, private router: Router) {
  }
  
  ngOnInit() {

     this.updateForm = this.fb.group({
      status: [''],
 
    });  
  }
  onselectValue(event){

  }
  

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

 

  close(){
    console.log('coloumn',this.updateForm.value);
    this.dialogRef.close(this.updateForm);
  }
}

