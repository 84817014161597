import { CdkMonitorFocus } from '@angular/cdk/a11y';
import { Component, OnInit,Inject, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'app/shared/services/common.service';
import { HttpService } from 'app/shared/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>) {}
  ngOnInit() {
  }


  onClose(): void {    
    this.dialogRef.close(true);
  }
}


@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./dialog.component.scss']

})
export class ConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>) {}

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}


@Component({
  templateUrl: './confirmationDialog.component.html',
  styleUrls: ['./dialog.component.scss']

})
export class ConfirmationDialogComponent implements OnInit {
  public isNotConformation:boolean  = false;
  public msg:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>) {}

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

@Component({
  templateUrl: './warningDialog.component.html',
  styleUrls: ['./dialog.component.scss']

})
export class WarningDialogComponent implements OnInit {
  public msg:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>) {}

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}

@Component({
  templateUrl: './ScrapDialog.component.html',
  styleUrls: ['./dialog.component.scss']

})
export class ScrapDialogComponent implements OnInit {
  public msg:any;
  public tray:any;
  public soc:any;
  public action:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogComponent>) {}

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}



@Component({
  templateUrl: './CompanyDialog.component.html',
  styleUrls: ['./dialog.component.scss']

})
export class CompanyDialog implements OnInit {
  @ViewChild('focusInput', { static: true }) focusInput;

  company: String='';
  companyList = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, public commonService:CommonService, public dialogRef: MatDialogRef<DialogComponent>, private spinner: NgxSpinnerService, private httpService: HttpService) {}

  ngOnInit() {
    this.spinner.show();
    this.httpService.getCompanyList("loginOperationApi/v1/fetchAllCompany",this.data).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'SUCCESS') {
          this.companyList = res['response']
          setTimeout(()=>{
            this.focus();
          },1000)
        } else {        
          this.commonService.openSnackBar("Something went wrong, contact Administrator", "Close"); 
        }
      },
      err => {        
        alert("Internal issue dialog to fetch the information");
        this.spinner.hide();
      }
    );
  }

  getCountry(company) {
    if(company !== '')
    {
      localStorage.setItem('companyId', company);
      this.dialogRef.close('confirmed');
    }
  }
  focus(){
    this.focusInput.open();

    // this.focusInput.nativeElement.focus();
  }
}


