import { Component, OnInit,OnDestroy ,ViewChild,ElementRef} from "@angular/core";
import { FormGroup, FormBuilder, Validators,FormControl } from "@angular/forms";
import { CountupTimerService, countUpTimerConfigModel, timerTexts } from "ngx-timer";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "../helper.service";
import { HttpService } from "../shared/services/http.service";
import * as $AB from 'jquery';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';
import {map, startWith} from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material';
import { APPCONFIG } from "app/config";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogComponent,ConfirmDialogComponent,ConfirmationDialogComponent} from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { PermissionService } from "app/shared/services/permission.service";

declare var updateOwlCarousel: any;
declare var owlCarousel: any;

@Component({
  selector: 'app-refurb-info',
  templateUrl: './refurb-info.component.html',
  styleUrls: ['./refurb-info.component.scss']
})
export class RefurbInfoComponent implements OnInit {

  //@ViewChild('singleSelect',{ static: true }) singleSelect: MatSelect;  
  @ViewChild('defectCode',{static:false}) el:ElementRef;  
  @ViewChild('solutioncode',{static:false}) el1:ElementRef;
  @ViewChild('comment',{static:false}) el2:ElementRef;
  @ViewChild('scrapdefectCode',{static:false}) el3:ElementRef;
  @ViewChild('scrapsolutioncode',{static:false}) el4:ElementRef;
  @ViewChild('scrapcomment',{static:false}) el5:ElementRef;
  @ViewChild(MatAutocompleteTrigger, {static:true}) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('addApare',{static:false}) spare;
  @ViewChild('spform', {static:true}) spform;
  spareMasterCode: Observable<any>;
  dialogref: MatDialogRef<ConfirmationDialogComponent, any>;
  CommentCodedata: any;
  commentCodeFlag: boolean = false;
  closebCommentfiltered: any;
  scrapCommentfiltered: any;
  scrapcommentCodeFlag: boolean = false;
  scrapshowCommentBox: boolean = false;
  showCommentBox: boolean = false;textComment: any;
  productFamily: string;
  choiceForm: FormGroup;
  choiceSoltionfiltered: any;
  choiceDefectfiltered: any;
  choiceComponentfiltered: any;
  choiceCommentfiltered: any;
  ccomponentCodeFlag: boolean = false;
  csolutionCodeFlag: boolean= false;
  cdefectCodeFlag: boolean= false;
  ccommentCodeFlag: boolean= false;
  cshowCommentBox: boolean= false;
  actionPerformed: string;
  IS_4TH:boolean=false;
  D2C:boolean=false;
  holder: any;
  packing: any;
  showImage: boolean = false;
  showDoc: any = [];
  imageURL: string = '../assets/pdf/image.png';
  showDocuments:any= [];
  pdfDownload:any=[];
  arr:any=[];
  printContents: string='';
  popupWin: any;
  reportURL:string = '../assets/pdf/image.png';
  result:any= [];
  temp:any= [];
  isDoc;
  notPauseApi: any=false;
  permissions: any;
;
  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }

  color = 'primary';
  mode = 'determinate';
  value = 50;
  icon: boolean = false;
  iconexist:boolean = false;
  iconTogl: boolean = false;
  ClosedAction: any;
  closeRequestData: any;

click(){
    this.icon = !this.icon;
  }

  clickTogl(){
    this.iconTogl = !this.iconTogl;
   
  }
  textForm:FormGroup;
  spareForm: FormGroup;
  itemDetails: any;
  spares: any = [];
  codeVal: String;
  qntyVal: String;
  refurbForm: FormGroup;
  scrabForm: FormGroup;
  spareList: any = [];
  spareDeleteble: any;
  testConfig: countUpTimerConfigModel;
  addMedia: boolean = false;
  isSpareError: boolean = false;
  isTableHide: boolean = false;
  SpareErrorMsg: String = "";
  trayNo: String;
  spareArray: any = [];
  entryArray: any = [];
  componentCodedata: any;
  defectCodedata: any;
  solutionCodedata: any;
  comment: string = "";
  isrefurbError: boolean = false;
  refurbErrorMsg: String = "";
  isscrapError: boolean = false;
  scrapErrorMsg: String = "";
  SolutionCodeErrorMsg: any;
  SolutionCodeError: any;
  DefectCodeMsg: any;
  DefectCodeError: any;
  ComponentCodeError: any;
  ComponentCodeErrorMsg: any;
  quantityErrorMsg: any;
  isquantityError: any;
  isdeleteError: any;
  deleteErrorMsg: any;
  conformMsg: string ="You successfully <action> for SOC Number <scoid>  You took  <time> to do the Intervention" ;
  processStatus: any;
  type: string = 'refurb';
  filesList: any = [];
  filename: string = '';
  cameraOpen: boolean = false;
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  showDocument: any = [];
  isDesktopDevice: boolean;
  mediaError: boolean;
  mediaErrorMsg: any;
  showingmediaError: boolean;
  showingMediaErrorMsg: any;
  test: boolean = false;
  removeIndex: any = [];
  sizeErrorMsg: string = '';
  scanResult:any=[];
  spareErrorMsg:string='';
  spareError:boolean=false;
  caoruseExist:boolean = false;
  productcode:any =[];
  productcodeError: boolean=false;
  productcodeErrorMsg: string='';
  ChoiceCode:any;
  ChoiceDCode:any;
  ChoiceSCode:any;
  ChoiceComment:any;
  choicetextComment:any;
//webcam variable
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  callLightBox: boolean;
//webcam end

//scanner variable
availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  qrResultString: string;
  hasDevices: boolean;
  hasPermission: boolean;
  torchEnabled = false;
  scanStart:boolean=false; 
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  qrResults: any=[];
  BarScanErrorMsg :string='';
  BarScanError:boolean=false;
  zone :string = "";
  sapResponse: any;
  productId: any;
  date: string;
  socNum: any;
  soNum: any;
  action:string='';
  SOCId: string='';
  sapSpare: any;
  numFiles:any;
  showUploadedFiles:boolean = false;
  currentDate:Date;
  SpareComponentfiltered:any;
  SpareDefectfiltered:any;
  SpareSoltionfiltered: any;
  refurbComponentfiltered:any;
  refurbDefectfiltered:any;
  refurbSoltionfiltered:any;
  userName:string='';
  solutionCodeFlag:boolean = false;
  componentCodeFlag:boolean = false;
  defectCodeFlag:boolean = false;
  ssolutionCodeFlag:boolean = false;
  scomponentCodeFlag:boolean = false;
  sdefectCodeFlag:boolean = false;
  CCode:any;
  DCode:any;
  SCode :any;
  sCCode:any;
  sDCode:any;
  sSCode :any;
  userLDapResonseRoles: any;
  spareTextCode:any
  sapEttexts:any
  uploadFlag: boolean = false;
  uploadErrorMsg:String = "";
  storeLocation:any='supermarket';
  companyId='';

  constructor(private locationFORURL: Location,private commonService: CommonService, private _formBuilder: FormBuilder,public fb: FormBuilder, private helperService: HelperService,  private location: PlatformLocation , private deviceService: DeviceDetectorService, private countupTimerService: CountupTimerService, private httpService: HttpService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, private router: Router,public dialog: MatDialog,
    private permissionsService: PermissionService) {
    
    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));     
    //   if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //     ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 
    //   {
    //   }else{
        
    //     // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
    //     // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //     // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
    //     // ){  
    //     //   this.router.navigate(['/scrap']);
    //     // }else{
    //       this.router.navigate(['/common']);
    //     // }
    //   }
      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['refurbInfo'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.locationFORURL.back();
      }

      
    if (this.httpService.loginCheckSession()) { }
    else {
        this.httpService.showSessionError();
        
    }
  
    this.route.queryParams.subscribe(params => {
      this.trayNo = params['trayId'];
      this.soNum = params['soId'];
      this.socNum = params['socId'];
    });
    if((this.trayNo==null || this.trayNo==undefined || this.trayNo=="") && (this.soNum==null ||this.soNum==undefined ||this.soNum=="") &&( this.socNum==null || this.socNum==undefined || this.socNum=="")){
      this.router.navigate(['repair']);
      return;
     }

    this.epicFunction();
    this.userName=localStorage.getItem('userName');
  
 
  this.spareCodeData();
  this.companyId=this.permissionsService.companyId;

}

  ngOnDestroy() {
    this.helperService.hideAllModal()
    if(!this.notPauseApi && this.processStatus  != 'PROCESSING_END'){
      this.StopTimer();
    }
  }
 
  ngOnInit() {
    this.callLightBox = false;
    this.spareForm = this.fb.group({
      //      name: ['', [Validators.required]],
      code: ['', [Validators.required,Validators.min(1)]],
      quantity: ['', [Validators.required, Validators.min(1)]],
      storeLocation: ['', [Validators.required]]
    });
    if(this.companyId=='2002'){/**Remove  storeLocation for Uk company user*/
      this.spareForm.get('storeLocation').setValidators(null);
      this.spareForm.get('storeLocation').updateValueAndValidity();
    }

    this.refurbForm = this.fb.group({
      solutionCode: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      componentCode: ['', [Validators.required]],
      defectCode: ['', [Validators.required]],
      checkox:[false],
    });

    this.scrabForm = this._formBuilder.group({
      sSolutionCode: ['', [Validators.required]],
      sComment: ['', [Validators.required]],
      sComponentCode: ['', [Validators.required]],
      sDefectCode: ['', [Validators.required]],

    });
    this.choiceForm = this._formBuilder.group({
      cSolutionCode: ['', [Validators.required]],
      cComment: ['', [Validators.required]],
      cComponentCode: ['', [Validators.required]],
      cDefectCode: ['', [Validators.required]],

    });
    this.textForm = this.fb.group({
      tdid: ['', [Validators.required]],
      text: ['']
    });

    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = 'test_Timer_class';
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
   // this.countupTimerService.startTimer();
    updateOwlCarousel();
    this.zone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    //setting response from repair-refurb component           
      
    if (typeof this.helperService.sapResponse != "undefined"){
      const resp=this.helperService.sapResponse['response'];
      this.itemDetails=resp.itemDetails;
      this.sapResponse=resp.sapResponse;
        if (this.sapResponse) {
          this.SOCId = this.sapResponse.essocdata.socid;
          this.trayNo=resp.trayId;
          var dt=this.sapResponse.esproductdetails.crmpurchasedate;
          this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
          if(this.sapResponse.esproductdetails.refproduct){
            this.productId=this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
            }
            if (this.sapResponse.ettexts) {
              this.sapEttexts=this.sapResponse.ettexts
              this.loadFilter();
     
            }
            this.fetchMasterData();
         
            this.processStatus=resp.processDetail.status;

          let postData = {
           // "userId": "ISA User",
           // "consumerId": this.sapResponse.esproductdetails.refproduct,//this.sapResponse.esproductdetails.refproduct
           // "applianceId": this.sapResponse.esproductdetails.zz0014,//this.sapResponse.esproductdetails.zz0014
            "transactionId": this.sapResponse.essocdata.socid, //this.sapResponse.essocdata.socid
           // "userName":"",
            ////"system":"",
            //"country":"",
            //"documentSource":"ISA"   
        
        
          };
          this.imageShow(postData);
          this.loadReport();
       }
       if (resp.processDetail) {
        var dateStr = resp.processDetail.startTime;
        let dateJson = new Date(dateStr);
        var m1=moment(dateJson).tz(this.zone).format();      // 2014-06-22T09:21:08-07:00
        let date1=new Date(m1);
        let date2=new Date();
        var duration =date2.getTime()-date1.getTime();
       // var difference = cdate.getTime() - dateJson.getTime();
        let startDate=(new Date().getTime()) - duration;
        this.countupTimerService.startTimer(startDate);
      } else{
        this.countupTimerService.startTimer()
      } 
      this.sapSpare=resp.sapSpare;
      if(resp.spare && resp.sapSpare)
      {
      this.spares=this.sapSpare.concat(resp.spare);
      }else if(resp.sapSpare){
      this.spares=this.sapSpare;  
      }else{
        this.spares=resp.spare;
      }  
      this.spinner.hide();       
      if(resp.clodeRequestDetail){
        this.ClosedAction=resp.clodeRequestDetail;
         this.closeRequestData=resp.clodeRequestDetail;
        
       }
    }else{
      this.getSpareDetails();
    }
    this.loadFilter();
    this.setAction();
    
  }

loadFilter(){
  this.refurbComponentfiltered = this.refurbForm.get('componentCode').valueChanges
    .pipe(
      startWith(''),
      map(val => this._filter(val))
    );
    this.refurbDefectfiltered = this.refurbForm.get('defectCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterDefect(value))
    );

    this.refurbSoltionfiltered = this.refurbForm.get('solutionCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterSolution(value))
    );
    
    this.SpareComponentfiltered = this.scrabForm.get('sComponentCode').valueChanges
    .pipe(
      startWith(''),
      map(val => this._filter(val))
    );
    this.SpareDefectfiltered = this.scrabForm.get('sDefectCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterDefect(value))
    );

    this.SpareSoltionfiltered = this.scrabForm.get('sSolutionCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterSolution(value))
    );
    this.spareMasterCode = this.spareForm.get('code').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterSpareCode(value))
    );

    // this.spareTextCode = this.textForm.get('tdid').valueChanges
    // .pipe(
    //   startWith(''),
    //   map(value => this._filterText(value))
    // );
    this.choiceComponentfiltered = this.choiceForm.get('cComponentCode').valueChanges
    .pipe(
      startWith(''),
      map(val => this._filter(val))
    );
    this.choiceDefectfiltered = this.choiceForm.get('cDefectCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterDefect(value))
    );

    this.choiceSoltionfiltered = this.choiceForm.get('cSolutionCode').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filterSolution(value))
    );
}
  
  private _filter(value: string): string[] {
    if ((value === undefined || value === null ) || (this.componentCodedata === undefined || this.componentCodedata === null ) ) 
    return ;
    const filterValue = value.toLowerCase();
    var data = this.componentCodedata.filter(option => String(option.code).toLowerCase().indexOf(filterValue ) > -1 || 
    option.description.toLowerCase().indexOf(filterValue ) > -1);
    return data
  
  }

  
  private _filterDefect(value: string): string[] {
    if ((value === undefined || value === null ) || (this.defectCodedata === undefined || this.defectCodedata === null ) ) 
    return ;
    const filterValue = value.toLowerCase();
    var data = this.defectCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue ) > -1 || 
    option1.description.toLowerCase().indexOf(filterValue ) > -1);
     return data
   
   }
 
   private _filterSolution(value: string): string[] {
    if ((value === undefined || value === null ) || (this.solutionCodedata === undefined || this.solutionCodedata === null ) ) 
    return ;
    const filterValue = value.toLowerCase();
    var data = this.solutionCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue ) > -1 || 
    option1.description.toLowerCase().indexOf(filterValue ) > -1);
     return data
   
   }

   private _filterSpareCode(value: string): string[] {
    if ((value === undefined || value === null ) || (this.productcode === undefined || this.productcode === null ) ) 
    return ;
    const filterValue = value.toLowerCase();
    var data = this.productcode.filter(option1 => String(option1.matnr12nc).toLowerCase().indexOf(filterValue ) > -1 || 
    option1.spareName.toLowerCase().indexOf(filterValue ) > -1);
    return data
   }
   
  //  private _filterText(value: string): string[] {
  //   if ((value === undefined || value === null ) || (this.sapEttexts === undefined || this.sapEttexts === null ) ) 
  //   return ;
  //   const filterValue = value.toLowerCase();
  //   var data = this.sapEttexts.filter(option => String(option.tdid).toLowerCase().indexOf(filterValue) > -1 ||
  //     option.text.toLowerCase().indexOf(filterValue) > -1);
  //   return data
  // }

   getComponentCodeVerified(event){
    this.componentCodeFlag = event.isUserInput;
       
  }
  getSolutionCodeVerified(event){
   this.solutionCodeFlag = event.isUserInput;

  }
  getDefectCodeVerified(event){
   this.defectCodeFlag = event.isUserInput;

  }
  focusOutComponentCode(code){
    if(code != undefined){
    var codeFound =  this.componentCodedata.find(x => x.description == code);
    if(codeFound != undefined && code == codeFound.description){
     this.componentCodeFlag = true;
     this.CommentCodeData(codeFound.code);
    }
    else{
     this.componentCodeFlag = false;
    }
   }
    
  }
  focusOutSolutionCode(code){
   if(code != undefined){
     var codeFound =  this.solutionCodedata.find(x => x.description == code);
     if(codeFound != undefined && code == codeFound.description){
      this.solutionCodeFlag = true;
     }
     else{
      this.solutionCodeFlag = false;
     }
    }
  }
  focusOutDefectCode(code){
   if(code != undefined){
     var codeFound =  this.defectCodedata.find(x => x.description == code);
     if(codeFound != undefined && code == codeFound.description){
      this.defectCodeFlag = true;
     }
     else{
      this.defectCodeFlag = false;
     }
    }
  }
  sgetComponentCodeVerified(event){
   this.scomponentCodeFlag = event.isUserInput;
      
 }
 sgetSolutionCodeVerified(event){
  this.ssolutionCodeFlag = event.isUserInput;

 }
 sgetDefectCodeVerified(event){
  this.sdefectCodeFlag = event.isUserInput;

 }
 sfocusOutComponentCode(code){
   if(code != undefined){
   var codeFound =  this.componentCodedata.find(x => x.description == code);
   if(codeFound != undefined && code == codeFound.description){
    this.scomponentCodeFlag = true;
    this.CommentCodeData(codeFound.code); 
  }
   else{
    this.scomponentCodeFlag = false;
   }
  }
   
 }
 sfocusOutSolutionCode(code){
  if(code != undefined){
    var codeFound =  this.solutionCodedata.find(x => x.description == code);
    if(codeFound != undefined && code == codeFound.description){
     this.ssolutionCodeFlag = true;
    }
    else{
     this.ssolutionCodeFlag = false;
    }
   }
 }
 sfocusOutDefectCode(code){
  if(code != undefined){
    var codeFound =  this.defectCodedata.find(x => x.description == code);
    if(codeFound != undefined && code == codeFound.description){
     this.sdefectCodeFlag = true;
    }
    else{
     this.sdefectCodeFlag = false;
    }
   }
 }

  addSpare() {
    if (!this.spareForm.valid) {
      this.helperService.makeFormDirty(this.spareForm);
      return;
    }
    var spare = this.spareForm.value;
    if(spare.code != undefined){
      var codeFound =  this.productcode.find(x => x.spareName == spare.code);
    }

    let url="refurbOperationApi/v1/spareDetails?code=" + codeFound.matnr12nc + "&quantity=" + spare.quantity + "&trayNO=" + this.trayNo + "&socNo=" + this.sapResponse.essocdata.socid+"&location=" + spare.storeLocation
     this.spinner.show();
    this.httpService.postRequest(url,{}).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          this.SpareErrorMsg = res['response'];
          this.isSpareError = true;
          this.helperService.makeFormDirty(this.spareForm);

          // setTimeout(()=>{
          //   this.isSpareError =false;
          //   this.spareForm.reset();
          // },2000)
        } else {
         if(this.sapSpare){
          this.spares = this.sapSpare.concat(res['response']);
          }else{
            this.spares = res['response'];
          } 
          this.spareCodeFlag = false;
          this.cancelSpare() ; 
        }
      },
      err => {
        // console.log("error occured while adding new spare");
        this.spinner.hide();
      }
    );

  }

  getSpareDetails() {
    var newUrl=null;
    if(this.trayNo!=null) {
    newUrl='checkInOperationApi/v1/getSpareItemByTrayNO?trayNO='+this.trayNo;
    }
    else if(this.socNum!=null){
    newUrl='checkInOperationApi/v1/getSpareItemBySocNo?socNumber='+this.socNum;
    }else if(this.soNum!=null){
    newUrl='checkInOperationApi/v1/getSpareItemBySoNo?soNumber='+this.soNum;
    } else{
    return;
    }
    this.spinner.show();
    this.httpService.getRequest(newUrl)
         .subscribe(res => {
        const resp = res['response'];
        this.itemDetails = resp.itemDetails;
        this.sapResponse=resp.sapResponse;
        if (this.sapResponse) {
          this.SOCId = this.sapResponse.essocdata.socid;
          this.trayNo=resp.trayId;
          var dt=this.sapResponse.esproductdetails.crmpurchasedate;
          this.date = [dt.slice(0, 4), "-", dt.slice(4, 6), "-", dt.slice(6, 8)].join('');
          if(this.sapResponse.esproductdetails.refproduct){
            this.productId=this.sapResponse.esproductdetails.refproduct.replace(/^0+/, '');
            }
            if (this.sapResponse.ettexts) {
              this.sapEttexts=this.sapResponse.ettexts
              this.loadFilter();
            }
            this.setAction();
          this.fetchMasterData();
          this.processStatus = resp.processDetail.status;
          let postData = {
            //"userId": "ISA User",
            //"consumerId": this.sapResponse.esproductdetails.refproduct,
            //"applianceId": this.sapResponse.esproductdetails.zz0014,
            "transactionId": this.sapResponse.essocdata.socid,
            //"userName":"",
            //"system":"",
            //"country":"",
            //"documentSource":"ISA"
            
          };
          this.imageShow(postData);
          this.loadReport();
       }
       if (resp.processDetail) {
        var dateStr = resp.processDetail.startTime;
        let dateJson = new Date(dateStr);
        var m1=moment(dateJson).tz(this.zone).format();      // 2014-06-22T09:21:08-07:00
        let date1=new Date(m1);
        let date2=new Date();
        var duration =date2.getTime()-date1.getTime();
       // var difference = cdate.getTime() - dateJson.getTime();
        let startDate=(new Date().getTime()) - duration;
        this.countupTimerService.startTimer(startDate);
      } else{
        this.countupTimerService.startTimer()
      } 
      this.sapSpare=resp.sapSpare;
      if(resp.spare && resp.sapSpare)
      {
      this.spares=this.sapSpare.concat(resp.spare);
      }else if(resp.sapSpare){
      this.spares=this.sapSpare;  
      }else{
        this.spares=resp.spare;
      } 
      this.spinner.hide();
      if(resp.clodeRequestDetail){
        this.ClosedAction=resp.clodeRequestDetail;
         this.closeRequestData=resp.clodeRequestDetail;
        
       }  
    }, err => {

        this.spinner.hide();
      })
  }
  loadReport() {
    let postDataups = {
      "transactionId": this.sapResponse.essocdata.socid, 
       "attachmentType": "UPS_LABEL"
     };
   
     this.imageShowUPS(postDataups);           //call servicedoc api for ups

     let postDataReport = {
       "transactionId": this.sapResponse.essocdata.socid, 
     };
     this.imageShowReport(postDataReport);      //call servicedoc api for report
  }


  selectDeletableSpare(obj_id) {
    this.spareDeleteble = obj_id;
    var msg = 'Do you confirm you need to delete this spare?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.deleteSpare()
      }
    });
  }
  deleteSpare() {
    this.spinner.show();
    this.httpService.postRequest("refurbOperationApi/v1/deleteSpare?spareId=" + this.spareDeleteble, {}).subscribe(
      res => {
        this.spareData();
        this.isdeleteError = false;

      },
      err => {
        this.deleteErrorMsg = "some error occured while delete  spare";
        this.isdeleteError = true;
        // console.log("error occured while delete spare");
        this.spinner.hide();
      }
    );

  }


  cancelSpare() {
    this.isSpareError = false;
    this.spareForm.reset();
    this.spform.resetForm();
    setTimeout(()=>{
      this.storeLocation='supermarket';
    },100)
    this.helperService.hideModal('Add-Spare-Dialog');
    this.loadFilter();
  }

  redirect(path) {
    window.location.href = path;
  }

  changeQuantity(quantity, spareId) {
    if(spareId==''|| spareId==null || quantity < 1 ){
      return;
   }
    this.spinner.show();
    this.httpService.postRequest("refurbOperationApi/v1/updateQuantity?spareId=" + spareId + "&quantity=" + quantity, {}).subscribe(
      res => {
        this.spareData();
        this.isquantityError = false;

      },
      err => {
        this.quantityErrorMsg = "error occured while update spare";
        this.isquantityError = true;
        this.spinner.hide();
      }
    );

  }


  lessQuantity(quantity, id) {
    if(quantity <= 1){
      return;
    }
    var newQuantity = quantity - 1;
    this.changeQuantity(newQuantity, id);

  }
  addQuantity(quantity, id) {
    var newQuantity = quantity + 1;
    this.changeQuantity(newQuantity, id);

  }

  componentCodeData(modelCode) {
    this.httpService.getRequest("refurbOperationApi/v1/componentCode?modelNo="+modelCode).subscribe(
      res => {
        if (res['status'] == 'SUCCESS') {
          this.componentCodedata = res['response'];
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
      }
    );
  }
  defectCodeData(modelCode) {
    this.httpService.getRequest("refurbOperationApi/v1/defectCode?modelNo="+modelCode).subscribe(
      res => {
        if (res['status'] == 'SUCCESS') {
          this.defectCodedata = res['response'];
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
      }
    );
  }

  SolutionCodeData() {
    this.httpService.getRequest("refurbOperationApi/v1/soluionCode").subscribe(
      res => {
        this.solutionCodedata = res['response'];
        this.SolutionCodeError = false;
      },

      err => {
        this.SolutionCodeErrorMsg = "error occured while getting soluionCode details";
        this.SolutionCodeError = true;
        this.spinner.hide();
      }
    );
  }

  closeRefurb() {
    if (!this.refurbForm.valid) {
      this.helperService.makeFormDirty(this.refurbForm);
      return;
    }
    var refurbFormdata = this.refurbForm.value;
    this.action="Closed the Intervention";
    if(refurbFormdata.checkox){
      var serviceType:any="001";
    }else{
      if(this.sapResponse.etservicetype){
        var serviceType =this.sapResponse.etservicetype[0].servicetype;
        }
    }
    
    let postData = {
      "componentCode": refurbFormdata.componentCode, "defectCode": refurbFormdata.defectCode, "solutionCode": refurbFormdata.solutionCode, "comment": refurbFormdata.sComment == "Other" ? this.textComment : refurbFormdata.comment+"- "+this.textComment, "socId": this.sapResponse.essocdata.socid, "action": "REPA","serviceType":serviceType,"feedback": "12"
    };
    this.closeScrap(postData);
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confromRefurbishment();
    }
  }


  scrabAppliance(action?) {
    if (!this.scrabForm.valid) {
      this.helperService.makeFormDirty(this.scrabForm);
      return;
    }
    var scrabFormData = this.scrabForm.value;
    this.action="Scrap the Appliance"; 
    // var fields = scrabFormData.sComponentCode.split('-');
    // var compcode = fields[0];
    // var fields2 = scrabFormData.sDefectCode.split('-');
    // var deftcode = fields2[0];
    // var fields3 = scrabFormData.sSolutionCode.split('-');
    // var solucode = fields3[0];
    if(this.sapResponse.etservicetype)
    var serviceType=this.sapResponse.etservicetype[0].servicetype;
   
    // let postData = {
    //   "componentCode": scrabFormData.sComponentCode, "defectCode": scrabFormData.sDefectCode, "solutionCode": scrabFormData.sSolutionCode, "comment": scrabFormData.sComment =="Other"?this.textComment:scrabFormData.sComment, "socId": this.sapResponse.essocdata.socid, "action": "SCRA","serviceType":serviceType,"feedback": "13"
    // };
    let postData = {
      "componentCode": scrabFormData.sComponentCode, "defectCode": scrabFormData.sDefectCode, "solutionCode": scrabFormData.sSolutionCode, "comment": scrabFormData.sComment =="Other"?this.textComment:scrabFormData.sComment+"- "+this.textComment, "socId": this.sapResponse.essocdata.socid, "action": action?action:"SCRA","serviceType":serviceType,"feedback": "13"
    };
    this.closeScrap(postData);
  }
  onKeydownscrabEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.confromScrap();
    }
  }


  closeScrap(data) {
    console.log(data)
   this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/closeIntervention', data).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'ERROR') {
          this.refurbErrorMsg = res['errorMessage'];
          this.isrefurbError = true;
          this.helperService.openStaticModal('conform_Dialog');
          setTimeout(() => {
            this.isrefurbError = false;
            this.refurbForm.reset();
          }, 10000)
        } else {
          this.uploadFlag=true;
          this.uploadData()
          this.spinner.hide();
          this.isrefurbError = false;
          this.conformMsg = this.conformMsg.replace('<action>',this.action).replace('<scoid>',this.sapResponse.essocdata.socid)
          .replace('<time>',res['response']);
          if (res['status'] == 'SUCCESS') {
            this.refurbForm.reset();
            this.helperService.openStaticModal('conform_Dialog');
            this.notPauseApi=true;
            // window.location.href = '#/repair';
          }
        }
      },
      err => {
      
        this.refurbErrorMsg = 'Some issue please try again or contact to admin!';
        this.isrefurbError = true;
        this.helperService.openModal('conform_Dialog');
        this.spinner.hide();
      }
    );

  }

  tableShow(tab?) {
    this.isTableHide = true;
    this.loadFilter();
    setTimeout(()=>{
      this.spare.nativeElement.scrollIntoView();
    },100); 
    if(tab && tab=='SCRA'){
      this.scrabForm.reset();
    }
  }

  // doubleCheck() {
  //   if (!this.scrabForm.valid) {
  //     this.helperService.makeFormDirty(this.scrabForm);
  //     return;
  //   }
  //  this.helperService.openModal('scrap_Dialog');
  // }

  // IMAGE UPLOAD
    // IMAGE UPLOAD
 uploadImage(event: any){
  this.currentDate = new Date();
   $('.isa-current-media').trigger('destroy.owl.carousel'); 
   $('#lightbox-current').trigger('destroy.owl.carousel');
   $('#lightbox-already').trigger('destroy.owl.carousel');
   setTimeout(()=>{
     this.isaMedia();
   },10); 
   this.numFiles = event.target.files.length;
   $(".isa-med-lenght").text(this.numFiles);
   $('.avatar-preview').css('display', 'flex');
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      f.mediaSRC = URL.createObjectURL(files[i]);
      
  
 
      this.filesList.push(f);
       
      let jQueryInstance = this;
   
      
  }
  this.showUploadedFiles = true;
 }
 
 getObjectUrl(file){
   return URL.createObjectURL(file);
 }
 
 sanitize(url:string){
   return this.sanitizer.bypassSecurityTrustUrl(url);
 }
 
 removeCam(){
  this.cameraOpen = false;
  this.helperService.hideModal('camera-media-dialog');
  this.filesList=[];   
  this.webcamImage=null;
}

 removeFile(file){
   $('.isa-current-media').trigger('destroy.owl.carousel');
   $('#lightbox-current').trigger('destroy.owl.carousel');
   $('#lightbox-already').trigger('destroy.owl.carousel');
   let index = this.filesList.indexOf(file);
   this.filesList.splice(index,1);
   
   if(this.filesList.length == 0){
     this.webcamImage = null;
     this.helperService.hideModal("isa-current-slider");
     $('#chooseFile').val('');
   }
   //$(".isa-current-media").trigger('remove.owl.carousel', index).trigger('refresh.owl.carousel');
   setTimeout(()=>{
     this.isaMedia();
   },10);
 }
 
  uploadData() {
    if (this.filesList.length == 0) {
      return;
    }
    this.removeIndex.sort(function (a, b) { return b - a; });
    for (var j = this.removeIndex.length - 1; j >= 0; j--) {
      this.filesList.splice(this.removeIndex[i], 1);
    }

    var fd = new FormData();
    var applianceId =null;
    fd.append('userId',localStorage.getItem('userId')); 
    fd.append('userName',this.userName);
    fd.append('system', APPCONFIG.system);
    fd.append('Region', APPCONFIG.Region);
    fd.append('country',APPCONFIG.country);
    fd.append('Language',APPCONFIG.Language);
    fd.append('documentSource',APPCONFIG.documentSource);
    fd.append('consumerId',this.sapResponse.esbpdata.partnerid);
if(this.sapResponse.esproductdetails.productid){ 
 applianceId = this.sapResponse.esproductdetails.productid.replace(/^0+/, '');
}
fd.append('applianceId',applianceId); 
fd.append('transactionId', this.sapResponse.essocdata.socid);
    for (var i = 0; i < this.filesList.length; i++) {
      var file = this.filesList[i]
      fd.append('files', file);      
    }
    this.singleImageUpload(fd);
  }


  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  // public handleImage(webcamImage: WebcamImage): void {
  //   this.webcamImage = webcamImage;
  //   $('.avatar-preview').css('display', 'flex');
  //   var files = webcamImage;
  //  // var img = this.base64toBlob(btoa(unescape(encodeURIComponent(webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',') + 1)))), "image/jpeg");
  //   var imges=this.dataURItoBlob((webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',')+1))); 
  //   const imageFile = new File([imges],new Date().valueOf()+""+Math.round(Math.random() * 500)+'.jpeg', { type: 'image/jpeg' });
  //   this.filesList.push(imageFile);
  //   var srcView = $('.avatar-preview');
  //   var srcImg = document.createElement('img');
  //   var reader = new FileReader();
  //   var srcItem = $(document.createElement('div')).addClass('src-items');
  //   srcImg.classList.add('isa-source-style');
  //   srcImg.src = webcamImage.imageAsDataUrl;
  //   var srcWebIndex = this.filesList.indexOf(webcamImage);
  //   srcItem.append('<button type="button" class="closeItem" webid = ' + srcWebIndex + '><i class="fa fa-times-circle" aria-hidden="true" ></i></button>')
  //   srcItem.append(srcImg);
  //   srcView.append(srcItem);
  //   //$('button.closeItem').find('button').attr('id',this.filesList.indexOf(webcamImage))
  //   // this.filesList.indexOf(webcamImage);
  //   let jQueryInstance = this;
  //   $(srcItem).find('.closeItem').click(function () {
  //     $(this).parent('.src-items').remove();
  //     var index = $(this).attr('webid');
  //     jQueryInstance.removeIndex.push(index);
  //   });


  // }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    var imges=this.dataURItoBlob((webcamImage.imageAsDataUrl.substring(webcamImage.imageAsDataUrl.indexOf(',')+1))); 
    const imageFile = new File([imges],new Date().valueOf()+""+Math.round(Math.random() * 500)+'.jpeg', { type: 'image/jpeg' });
    this.currentDate = new Date();
    $('.isa-current-media').trigger('destroy.owl.carousel'); 
    $('#lightbox-current').trigger('destroy.owl.carousel');
    $('#lightbox-already').trigger('destroy.owl.carousel');
    setTimeout(()=>{
      this.isaMedia();
    },10); 
  //  this.numFiles = $("input:file")[0].files.length;
    $(".isa-med-lenght").text(this.numFiles);
    $('.avatar-preview').css('display', 'flex');
       var f:any  = imageFile;
       f.mediaSRC = URL.createObjectURL(imageFile);
       this.filesList.push(f);
    this.showUploadedFiles = true;  
  }
  
  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  openCamera() {
    this.cameraOpen = true;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

  }

  lightboxInit(){
    ($('.isa-current-media') as any ).owlCarousel({
      margin:0,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      items:1
    }); 
  
    ($('.isa-media-slider') as any ).owlCarousel({
      loop:false,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: true,
      margin:0,
      items:1
    });
    
    ($('#lightbox-already') as any ).owlCarousel({
      margin:10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight : true,
      items:1
     });
  
    ($('#lightbox-current') as any ).owlCarousel({
      margin:10,
      nav: true,
      navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
      dots: false,
      autoHeight : true,
      items:1
     });
     
    ($('.lightboxGallery') as any ).owlCarousel({
     autoplay: false,
     nav: true,
     navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
     dots: false,
     margin:0,
     items:1
    });
    ($('#lightbox') as any).lightGallery({
      thumbnail:false,
      mousewheel:false,
      autoplay:false,
      share:false,
      play:false,
      hash:false,
      zoom:false,
      autoplayControls:false,
      progressBar:false
    
    });

     setTimeout(() => {
      let self = this; 
      $('.isa-media-slider .owl-next').on('click',function(){  if(self.iconexist){self.iconexist = !self.iconexist;} });
      $('.isa-media-slider .owl-prev').on('click',function(){  if(self.iconexist){self.iconexist = !self.iconexist;} });
    }, 200);
    }
    
    isaMedia(){
      ($('#lightbox-already') as any ).owlCarousel({
        margin:10,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: false,
        autoHeight : true,
        items:1
       });
    
      ($('#lightbox-current') as any ).owlCarousel({
        margin:10,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: false,
        autoHeight : true,
        items:1
       });

       ($('.isa-current-media') as any ).owlCarousel({
        margin:0,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: true,
        items:1
      }); 
    
      ($('.isa-media-slider') as any ).owlCarousel({
        loop:false,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: true,
        margin:0,
        items:1
      });
      this.caoruseExist = true;
    
    setTimeout(() => {
    let self = this; 
    $('.isa-current-media .owl-next').on('click',function(){  if(self.icon){self.icon = !self.icon;} });
    $('.isa-current-media .owl-prev').on('click',function(){  if(self.icon){self.icon = !self.icon;} });

    }, 200);
    }


  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  closeCam() {
    this.cameraOpen = false;
    this.helperService.hideModal('camera-media-dialog');
  }

  singleImageUpload(data) {
    if (!this.uploadFlag) {
      console.log('upload')
      this.spinner.show();
    }
    this.httpService.uploadPostRequest('document/uploadDocument', data).subscribe(
      res => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        if (res['code'] != '200') {
          this.uploadErrorMsg = res['message'];
          //   this.isuploadError =true;
          if (!this.uploadFlag) {
            this.openInformationDialog(this.uploadErrorMsg);
          }
        } else if (res['code'] == '200') {
          //this.isuploadError = false;
          var conformMsg = res['message'];
          if (!this.uploadFlag) {
            this.openInformationDialog(conformMsg);
            this.getImages();
            this.filesList = [];
        }
        } else {
          if (!this.uploadFlag) {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        if (!this.uploadFlag) {
          console.log('upload hide')
          this.spinner.hide();
        }
        //  console.log("error occured while media upload");
        this.uploadErrorMsg = "error occured while media upload";
       // this.isuploadError = true;
        //this.helperService.openModal('conform_Dialog');
      }
    );

  }

  imageShow(data) {
    $('#lightbox-already').trigger('destroy.owl.carousel');
    this.spinner.show();
    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();
        if(res['code']!='200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
        } else {
          this.showingmediaError = false;
          var documentData = res['data'];
          var conformMsg = res['message'];
          if (documentData != undefined && documentData != null && documentData.length >0 ) {
            for(var i=0;i<documentData.length;i++ ){
              if(documentData[i].attachmentType !='UPS_LABEL'&&  
             (documentData[i].documentName.toLowerCase().includes('jpeg')||
              documentData[i].documentName.toLowerCase().includes('jpg') ||
              documentData[i].documentName.toLowerCase().includes('png') ||
              documentData[i].documentName.toLowerCase().includes('mp4'))
              ){
                this.showDocument.push(documentData[i]);
              }
          }
          }
            if(this.showDocument){
             this.callLightBox = true;
      
           var conformMsg=res['message'];
           setTimeout(()=>{
             this.lightboxInit();
           },1000);
       }
          this.spinner.hide();
        }
      },
      err => {
        // console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();
      }
    );

  }

  imageShowUPS(data) {     
    //this.spinner.show();

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
          this.showImage = true;
        } else {
          this.showingmediaError = false;
          this.showDoc = res['data'];
          if (this.showDoc && this.showDoc.length > 0) {
            this.imageURL = this.showDoc[0].urlMediaLink;
            //this.callLightBox = true;
            this.showImage = false;
            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 100);
          } else {
            this.showImage = true;
          }
          //this.spinner.hide();

        }
      },
      err => {
        // console.log("error occured fetch image data ");
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();

      }
    );
  }


  imageShowReport(data) {     
    

    this.httpService.downloadPostRequest('document/downloadDocument', data).subscribe(
      res => {
        this.spinner.hide();

        if (res['code'] != '200') {
          this.showingMediaErrorMsg = res['message'];
          this.showingmediaError = true;
          this.isDoc = true;
        } else {
          this.showingmediaError = false;
          this.showDocuments = res['data'];
          if (this.showDocuments && this.showDocuments.length > 0) {
            for(let i=0;i<this.showDocuments.length;i++)
            {
              if(this.showDocuments[i].documentName.match(/.(pdf)$/i))
              {
                  
                  this.showDocuments.sort((a,b) => +new Date(a.documentUploadDate) - +new Date(b.documentUploadDate));

                  
                   this.pdfDownload.push(this.showDocuments[i]); 
                  // this.arr.push(this.pdfDownload[i].documentName);
                   
              }
            }
          }
           
  if(this.pdfDownload.length>0){          
  this.pdfDownload=this.pdfDownload.reverse();
 if(this.pdfDownload.length>0){
 this.result.push(this.pdfDownload[0]);
 }
 console.log(this.result);

 for(var i=1;i<this.pdfDownload.length;i++){
   if(this.pdfDownload[i].documentName == this.pdfDownload[i-1].documentName)
   {
              continue;
   }
   else{
              this.result.push(this.pdfDownload[i]);
   }
 }
 //console.log(this.result);
 

 		var en_flag:boolean=false;	
     var it_flag:boolean=false;
     var nl_flag:boolean=false;
     var fr_flag:boolean=false;
     var de_flag:boolean=false;				
 for(var i=0;i<this.result.length;i++){
  if(this.result[i].documentName.search("EN.pdf") !== -1 && en_flag==false)
  {
    this.temp.push(this.result[i]);
    en_flag =true;
  }
  else if(this.result[i].documentName.search("IT.pdf") !== -1 && it_flag==false)
  {
    this.temp.push(this.result[i]);
    it_flag =true;

  }
  else if(this.result[i].documentName.search("NL.pdf") !== -1 && nl_flag==false)
  {
    this.temp.push(this.result[i]);
    nl_flag =true;

  }
  else if(this.result[i].documentName.search("FR.pdf") !== -1 && fr_flag==false)
  {
    this.temp.push(this.result[i]);
    fr_flag =true;

  }
  else if(this.result[i].documentName.search("DE.pdf") !== -1 && de_flag==false)
  {
    this.temp.push(this.result[i]);
    de_flag =true;

  }
  else{
    continue;
  }
}
console.log(this.temp); 
//this.reportURL=this.temp[0].urlMediaLink;


            
            this.isDoc = false;
            console.log(this.isDoc);
            var conformMsg = res['message'];
            setTimeout(() => {
              this.lightboxInit();
            }, 100);
          } else {
            this.isDoc = true;
            console.log(this.isDoc);
          }
          

        }
      },
      err => {
        
        this.showingMediaErrorMsg = "error in fetch image data";
        this.showingmediaError = true;
        this.spinner.hide();

      }
    );
  }

  downloadd(ind) {

    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%"+ind)
    for(var i=0;i<this.temp.length;i++){
  if(this.temp[i].documentName==ind){
    this.reportURL=this.temp[i].urlMediaLink;
    this.downloadReport();
  }
  }
  }
  
  downloadReport() { 
  window.open(this.reportURL);
}

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });    
    return blob;
  }
  
//scanner method

clearResult(): void {
  this.qrResultString = null;
}

onCamerasFound(devices: MediaDeviceInfo[]): void {
  this.availableDevices = devices;
  this.hasDevices = Boolean(devices && devices.length);
}

onCodeResult(resultString: string) {
  this.qrResultString = resultString;
  this.qrResults.push(this.qrResultString);
  let audio = new Audio();
  audio.play();
      audio.src = "assets/mp3/beep-02.mp3";
      audio.load();
      audio.play();
      this.spinner.show();
      setTimeout(()=>{
        this.spinner.hide();
      },2000)
}

onDeviceSelectChange(selected: string) {
  const device = this.availableDevices.find(x => x.deviceId === selected);
  this.currentDevice = device || null;
}

onHasPermission(has: boolean) {
  this.hasPermission = has;
}
onTorchCompatible(isCompatible: boolean): void {
  this.torchAvailable$.next(isCompatible || false);
}

toggleTorch(): void {
  this.torchEnabled = !this.torchEnabled;
}

toggleTryHarder(): void {
  this.tryHarder = !this.tryHarder;
}


closeScanner(){
  this.scanStart=false;
  this.insertScanSpares();
  this.helperService.hideModal("barcodeScan");
}


insertScanSpares(){
 // this.qrResults.push("00123456789101112133");
 // this.qrResults.push("00123456789101112133");
 // this.qrResults.push("w123");

  if(this.qrResults.length==0){
    return;
  }
  let postData = {
    "soc": this.sapResponse.essocdata.socid,
    "codes": this.qrResults
   
  };

  this.spinner.show();
    this.httpService.postRequest('refurbOperationApi/v1/scanBarCodes',postData).subscribe(
      res => {
        if (res['status'] == 'ERROR') {
          this.BarScanErrorMsg = res['response'];
          this.BarScanError = true;
          this.qrResults.length = 0;
          this.spinner.hide();
          this.helperService.openStaticModal('barcode_Dialog');
        } else {
          this.BarScanError = false;
          this.scanResult=res['response'];
          this.helperService.openStaticModal('barcode_Dialog');
          this.qrResults.length = 0;
          this.spinner.hide();
        }
      },
      err => {
        // console.log("error occured insert appliance bar codes");
        this.BarScanErrorMsg = "insert appliance bar codes";
        this.BarScanError = true;
        this.qrResults.length = 0;
        this.helperService.openStaticModal('barcode_Dialog');
        this.spinner.hide();
      }
    );
}
spareData(){
  this.spinner.show();
  this.httpService.getRequest("refurbOperationApi/v1/sparedetailsbysocno?socNo="+this.sapResponse.essocdata.socid).subscribe(
    res => {
      if(this.sapSpare){
        this.spares = this.sapSpare.concat(res['response']);
        }else{
          this.spares = res['response'];
        } 
     
      this.spareError = false;
     this.spinner.hide();

    },

    err => {
      this.spareErrorMsg = "error occured while getting soluionCode details";
      this.spareError = true;
      this.spinner.hide();
    }
  );
}

StopTimer(){
  this.spinner.show();
  if(!this.sapResponse){
    this.spinner.hide();
    return;
  }
  this.httpService.getRequest("refurbOperationApi/v1/timerStop?socNo="+this.sapResponse.essocdata.socid).subscribe(
    res => {
      this.spinner.hide();
      if (res['status'] == 'ERROR') {
        // console.log("timer Pause",res['status']);
      } else {
        // console.log("timer Pause",res['status']);
      }
    },
    err => {
      // console.log("error occured while close refurb");
      this.spinner.hide();
    }
  );

}


 spareCodeFlag:boolean = false;
SMcode:any;
getSpareCodeVerified(event){
   this.spareCodeFlag = event.isUserInput;

  }
  focusOutSpareCode(code){
    if(code != undefined){
      var codeFound =  this.productcode.find(x => x.spareName == code);
    if(codeFound != undefined && code == codeFound.spareName){
     this.spareCodeFlag = true;
    }
    else{
     this.spareCodeFlag = false;
    }
   }
    
  }

 changeFocus(event,index){
  if(index==1)
  this.el.nativeElement.focus();
  //this.open(this.el.nativeElement.nextsibling);
  if(index==2)
  this.el1.nativeElement.focus();
  if(index==3)
  this.el2.nativeElement.focus();
}
changeFocusInScrap(event,index){
  if(index==1)
  this.el3.nativeElement.focus();
  if(index==2)
  this.el4.nativeElement.focus();
  if(index==3)
  this.el5.nativeElement.focus();
  
}
spareCodeData(){
  this.httpService.getRequest("refurbOperationApi/v1/spareCode").subscribe(
    res => {
    this.productcode=res['response'];
     this.productcodeError = false;
     this.loadFilter();
    },

    err => {
      this.productcodeErrorMsg = "error occured while getting spare Codes details";
      this.productcodeError = true;
      // console.log("error occured while getting spare Codes details");
      this.spinner.hide();
    }
  );
}

onselectModelValue(desc){
  const d={'text': desc};
  this.textForm.patchValue(d);
  this.textForm.controls['text'].disable();
 }
        
 confromRefurbishment(){
  if (!this.refurbForm.valid) {
    this.helperService.makeFormDirty(this.refurbForm);
    return;
  }
  var msg='Do you confirm this item is refurbished?';
  this.openconfromationDialog(msg);
  this.dialogref.afterClosed().subscribe(result => {
    if(result){
      console.log(result);
      this.closeRefurb()
    } 
  });
 }
 confromScrap(action?){
  if (!this.scrabForm.valid) {
    this.helperService.makeFormDirty(this.scrabForm);
    return;
  }
  var msg='Do you confirm this item to be scrapped?';
  this.openconfromationDialog(msg);
  this.dialogref.afterClosed().subscribe(result => {
    if(result){
      console.log(result);
     this.scrabAppliance(action)
    } 
  });
 }
 onKeydownchoiceEvent(event: KeyboardEvent): void {
  if (event.keyCode === 13) {
    this.confromChoice();
  }
}
 confromChoice(){
  if (!this.choiceForm.valid) {
    this.helperService.makeFormDirty(this.choiceForm);
    return;
  }
  var msg='Do you confirm this item to be 1st Choice?';
  this.openconfromationDialog(msg);
  this.dialogref.afterClosed().subscribe(result => {
    if(result){
      console.log(result);
     this.choiceAppliance()
    } 
  });
 }
 openconfromationDialog(msg) {
  this.dialogref =   this.dialog.open(ConfirmationDialogComponent, {
    disableClose: true, 
    data: {
      message :msg
    },
    panelClass: ['no-padding', 'custom-openconfromDialog','custom-mat-dialog']
  });
}
openInformationDialog(msg){
  this.dialogref = this.dialog.open(ConfirmationDialogComponent)
  .addPanelClass(['no-padding', 'custom-openconfromDialog','custom-mat-dialog'] )
  this.dialogref.componentInstance.msg = msg;
  this.dialogref.componentInstance.isNotConformation = true;            
}

fetchMasterData(){
  this.getFamily();
  if(!this.productFamily){
    var msg = 'Failed to get product family in this request.';
    this.openInformationDialog(msg);
    this.mailMissingSpare();
  }
    this.productFamily=this.productFamily==undefined?"":this.productFamily;    
    this.componentCodeData(this.productFamily);
    this.defectCodeData(this.productFamily);
    this.SolutionCodeData();
  
//  this.exchangeCodeData();
}

  focusOutCommentCode(code) {
    if (code != undefined) {
      var codeFound = this.CommentCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.commentCodeFlag = true;
        if (codeFound.description == "Other") {
          this.showCommentBox = true;
        }else{
          this.showCommentBox = false;
        }
      }
      else {
        this.commentCodeFlag = false;
        this.showCommentBox = false;
      }
      console.log( "flag", this.commentCodeFlag )
    }
  }
  
  CommentCodeData(component) {
    this.spinner.show();
     this.httpService.getRequest("refurbOperationApi/v1/commentCode?modelNo="+this.productFamily+"&code="+component).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'SUCCESS') {
          this.CommentCodedata = res['response'];
          this.loadCommentFillter();
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      
      }
    );
  }
  
  loadCommentFillter(){
    this.closebCommentfiltered = this.refurbForm.get('comment').valueChanges
    .pipe(
      startWith(''),
      map(value => this.Commentfiltered(value))
    );
    this.scrapCommentfiltered = this.scrabForm.get('sComment').valueChanges
    .pipe(
      startWith(''),
      map(value => this.Commentfiltered(value))
    );
    this.choiceCommentfiltered = this.choiceForm.get('cComment').valueChanges
    .pipe(
      startWith(''),
      map(value => this.Commentfiltered(value))
    );
  }
  private Commentfiltered(value: string): string[] {
    if ((value === undefined || value === null) || (this.CommentCodedata === undefined || this.CommentCodedata === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.CommentCodedata.filter(option1 => String(option1.code).toLowerCase().indexOf(filterValue) > -1 ||
      option1.description.toLowerCase().indexOf(filterValue) > -1);
    return data
  
  }
  
  getCommentCodeVerified(event) {
   this.commentCodeFlag = event.isUserInput;
    if (event.isUserInput) {    
      this.focusOutCommentCode(event.source.value)
    }
  }
  
  pexfocusOutCommentCode(code) {
    if (code != undefined) {
      var codeFound = this.CommentCodedata.find(x => x.description == code);
      if (codeFound != undefined && code == codeFound.description) {
        this.scrapcommentCodeFlag = true;
        if (codeFound.description == "Other") {
          this.scrapshowCommentBox = true;
        }else{
          this.scrapshowCommentBox = false;
        }
      }
      else {
        this.scrapcommentCodeFlag = false;
        this.scrapshowCommentBox = false;
      }
    }
  }
  pexCommentCodeVerified(event) {
    this.scrapcommentCodeFlag = event.isUserInput;
     if (event.isUserInput) {    
       this.pexfocusOutCommentCode(event.source.value)
     }
   }
   getFamily(){
    console.log("Action"+this.productFamily);
    if( this.sapResponse && this.sapResponse.etadditionalfields && JSON.stringify( this.sapResponse).indexOf('FAMILY') > -1){
      for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
        if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FAMILY')
         this.productFamily  =this.sapResponse.etadditionalfields[i].value ;  
        console.log(this.productFamily);
        }
    } 
  } 
  cgetComponentCodeVerified(event){
    this.ccomponentCodeFlag = event.isUserInput;
       
  }
  cgetSolutionCodeVerified(event){
   this.csolutionCodeFlag = event.isUserInput;
 
  }
  cgetDefectCodeVerified(event){
   this.cdefectCodeFlag = event.isUserInput;
 
  }
  cfocusOutComponentCode(code){
    if(code != undefined){
    var codeFound =  this.componentCodedata.find(x => x.description == code);
    if(codeFound != undefined && code == codeFound.description){
     this.ccomponentCodeFlag = true;
     this.CommentCodeData(codeFound.code); 
   }
    else{
     this.ccomponentCodeFlag = false;
    }
   }
    
  }
  cfocusOutSolutionCode(code){
   if(code != undefined){
     var codeFound =  this.solutionCodedata.find(x => x.description == code);
     if(codeFound != undefined && code == codeFound.description){
      this.csolutionCodeFlag = true;
     }
     else{
      this.csolutionCodeFlag = false;
     }
    }
  }
  cfocusOutDefectCode(code){
   if(code != undefined){
     var codeFound =  this.defectCodedata.find(x => x.description == code);
     if(codeFound != undefined && code == codeFound.description){
      this.cdefectCodeFlag = true;
     }
     else{
      this.cdefectCodeFlag = false;
     }
    }
  }
  cCommentCodeVerified(event) {
    this.ccommentCodeFlag = event.isUserInput;
     if (event.isUserInput) {    
       this.cfocusOutCommentCode(event.source.value)
     }
   }
   
   cfocusOutCommentCode(code) {
     if (code != undefined) {
       var codeFound = this.CommentCodedata.find(x => x.description == code);
       if (codeFound != undefined && code == codeFound.description) {
         this.ccommentCodeFlag = true;
         if (codeFound.description == "Other") {
           this.cshowCommentBox = true;
         }else{
           this.cshowCommentBox = false;
         }
       }
       else {
         this.ccommentCodeFlag = false;
         this.cshowCommentBox = false;
       }
     }
   }
   choiceAppliance(){
    if (!this.choiceForm.valid) {
      this.helperService.makeFormDirty(this.choiceForm);
      return;
    }
    var choiceFormdata = this.choiceForm.value;
    this.action="Closed the Intervention";
    //if(refurbFormdata.checkox){
      var serviceType:any="001";
    // }else{
    //   if(this.sapResponse.etservicetype){
    //     var serviceType =this.sapResponse.etservicetype[0].servicetype;
    //     }
    // }
    
    let postData = {
      "componentCode": choiceFormdata.cComponentCode, "defectCode": choiceFormdata.cDefectCode, "solutionCode": choiceFormdata.cSolutionCode, "comment": choiceFormdata.cComment =="Other"?this.choicetextComment:choiceFormdata.cComment+"- "+this.choicetextComment, "socId": this.sapResponse.essocdata.socid, "action": "1CHO","serviceType":serviceType,"feedback": "12"
    };
    this.closeScrap(postData);
  
   }
   setAction(){
    console.log("Action"+this.actionPerformed);
    if( this.sapResponse && this.sapResponse.etadditionalfields && (JSON.stringify( this.sapResponse).indexOf('FLOW') > -1 || JSON.stringify( this.sapResponse).indexOf('HOLDER') > -1  || JSON.stringify( this.sapResponse).indexOf('PACKING') > -1)){
      for (var i = 0; i < this.sapResponse.etadditionalfields.length; i++) {
        if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'FLOW')
         this.actionPerformed  =this.sapResponse.etadditionalfields[i].value ;  
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'HOLDER')
         this.holder  =this.sapResponse.etadditionalfields[i].value ;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'PACKING')
         this.packing  =this.sapResponse.etadditionalfields[i].value ;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'IS_4TH')
         this.IS_4TH  =true ;
         if(this.sapResponse && this.sapResponse.etadditionalfields[i].key == 'IS_D2C')
         this.D2C  =true ;
        console.log(this.actionPerformed);
        }
    } 
    if(this.itemDetails && this.itemDetails.isD2C) {
      this.D2C  =true ;
    }
  }
  

  getImages(){
    this.showDocument=[];
    let postData = {
       "transactionId": this.sapResponse.essocdata.socid, //this.sapResponse.essocdata.socid
    };
    this.imageShow(postData);
  }


  deleteMedia(userId,ref,name){
    // let postData = {
    //   "userId": userId,
    //   "userName": name,
    //   "documentRef": ref
    // };
    var data = new FormData();
    data.append('userId',userId); 
    data.append('userName',name);
    data.append('documentRef', ref);
    console.log('data', data)
    this.spinner.show();
    this.httpService.uploadPostRequest('document/deleteDocument',data).subscribe(
    res => {
        this.spinner.hide();
        if (res['code'] == '200') {
          var msg = res['message'];
          this.openInformationDialog(msg);
          this.getImages();
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();

      }
    );
  }     

  mailMissingSpare() {
    this.spinner.show();
    this.httpService.getRequest("refurbOperationApi/v1/missingfamily?soc=" + this.sapResponse.essocdata.socid + "&productCode=" + this.productId).subscribe(
      res => {
        this.spinner.hide();
        console.log("response", res['status']);
        if (res['status'] == 'SUCCESS') {
          var response = res['response'];
          console.log("response", response);
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      }
    );
  }
  confromChangelocation(location, spareId) {
    var msg = 'Do you confirm you need to update spare part location?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
       this.changelocation(location, spareId);
      }
    });
  }
  
  changelocation(location, spareId) {
    if (spareId == '' || spareId == null || location == '' || location == null ) {
      return;
    }
    this.spinner.show();
    this.httpService.getRequest("refurbOperationApi/v1/updatesaprelocation?spareId=" + spareId + "&location=" + location).subscribe(
      res => {
        this.spinner.hide();
        if (res['status'] == 'SUCCESS') {
          this.spareData();
        } else {
          if (res['status'] == 'ERROR') {
            var msg = res['response'];
            this.openInformationDialog(msg);
          } else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
  
      }
    );
  }

  downloadImage() {  
    window.open(this.imageURL);
  }
  
  
   printInvoice(): void {
    this.printContents = document.getElementById("imageDiv").innerHTML;
    const originalContents = document.body.innerHTML;    
    this.printContents=this.printContents.replace('custom-width=""','width="750px" height="550px"');
    setTimeout(()=>{
      this.popupWin = window.open();
      var is_chrome = Boolean(this.popupWin.chrome);
      this.popupWin.document.open();
      this.popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="styles/main.css" /></head><body onload="">' + this.printContents + '</html>');
      if(is_chrome){
        setTimeout(()=>{
          this.popupWin.print();
          this.popupWin.document.close();
        },1000)
      }else{
              this.popupWin.print();
              this.popupWin.document.close();
        }
    })     
  }

  async printPDF(urlPdf) {
    this.spinner.show();
    let response = await fetch(urlPdf);
    let data = await response.blob();
    var blob = new Blob([data], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    // this.pdfURL= this.sanitizer.bypassSecurityTrustResourceUrl();
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
    this.spinner.hide();
  }
}








