import { Component, OnInit,ViewChild ,Inject,Input} from '@angular/core';
import { MatDialog ,MAT_DIALOG_DATA,MatDialogRef,MatCheckboxChange} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { APPCONFIG } from 'app/config';
import { ConfirmationDialogComponent } from 'app/dialog/dialog.component';
import { HelperService } from 'app/helper.service';
import { HttpService } from 'app/shared/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {PageEvent} from '@angular/material/paginator';
import { FormGroup, FormBuilder, Validators,NgModel } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { PermissionService } from 'app/shared/services/permission.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers:[DatePipe]

})
export class DashboardComponent implements OnInit {
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  
  title = "Repair/D2C Report";
  searchVal = '';
  displayedColumns: string[] = ['socNo','requestNo','createdOn','receivedBy', 'externalReference','flow','packing','pexNumber','productId','model','family','serialNumber','closedOn','history','timeDifference','country','repairedBy','d2c','packedBy','totalPackingTime',
     'trayNmuber','weekNumber','monthNumber','socCreationDate','socAccountingIndicator','customerName','customerNumber','customerAddress',
    'purchaseDate','category','pexReason','notes','technicalNotes','technician','monthlyLeadTime','problemDescriptionIPG','status','returnReason','soCallDate','holder','consumerOrDealer','creditNoteReference'];
  columnsToDisplay: string[] = ['socNo','requestNo','createdOn','receivedBy', 'externalReference','flow','packing','pexNumber','productId','model','family','serialNumber','closedOn','history','timeDifference','country','repairedBy','d2c','packedBy','totalPackingTime',
  'trayNmuber','weekNumber','monthNumber','socCreationDate','socAccountingIndicator','customerName','customerNumber','customerAddress',
  'purchaseDate','category','pexReason','notes','technicalNotes','technician','monthlyLeadTime','problemDescriptionIPG','status','returnReason','soCallDate','holder','consumerOrDealer','creditNoteReference'];
  columnsMapping = {
    "SOC Number": "socNo",
    "Technical Request/Return Number": "requestNo",
    "Receiving Date": "createdOn",
    "Receiving Person":"receivedBy",
    "External Reference": "externalReference",
    "FLOW": "flow",
    // "PEX/Refund": "pex",
    "Packing":"packing",
    "PEx Number": "pexNumber",
    "12NC": "productId",
    "Model": "model",
    "Family": "family",
    "Serial Number": "serialNumber",
    "Closing Date": "closedOn",
    "Appliance History": "history",
    "Repair Lead Time": "timeDifference",
    "Country": "country",
    "IS_D2C": "d2c",
    "TRI/Holder":"holder",
    "Repair Person":"repairedBy",
    "Packing Person":'packedBy',
    "Packing Lead Time":'totalPackingTime',
    'Status':'status',
    
    "Tray Number":'trayNmuber',
    "Week Number":'weekNumber',
    "Month Number":'monthNumber',
    "SOC Creation Date":'socCreationDate',
    "Accounting Indicator":'socAccountingIndicator',
    "Customer Name":'customerName',
    "Customer Number":'customerNumber',
    "Customer Address":'customerAddress',
    "Purchase Date":'purchaseDate',
    "Category":'category',
    "Pex Reason":'pexReason',
    "Notes":'notes',
    "Technical Note":'technicalNotes',
    "Technician":'technician',
    "Monthly Lead Time":'monthlyLeadTime',
    // "Repair Type":'repairType',
    "Problem Description":'problemDescriptionIPG',
    "Return Reason":'returnReason',
    // "Valuation Type":'valuationType',
    "SO Call Date":'soCallDate',
    "Consumer Or Dealer":'consumerOrDealer',
    "Credit Note Reference": "creditNoteReference"
  };
  dataSource : MatTableDataSource<any>;
  dialogref: any;
  data: any;
  zone: string;
  minDate = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  length = 100;
  pageSize = 50;
  latest_date: any;
  userLDapResonseRoles: any;
  flag:boolean=true;
  D2CFilter:String="All";
  PexNum: false;
  reportURL: string=APPCONFIG.REPORT_URL;
  repairURL: string=APPCONFIG.HOMEPAGE;
  viewMoreReports: boolean;
  applyFilter(filterValue: string) {
   // const filterValue = event.target.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  permissions =[];

  clearFilters(){
    this.searchVal='';
    this.dataSource.filter = '';
 }
 current_url = "";
  constructor(private permissionsService: PermissionService, private location: Location,private commonService: CommonService, private httpService: HttpService, private helperService: HelperService, private route: ActivatedRoute, private httpservice: HttpService, private spinner: NgxSpinnerService, private sanitizer:DomSanitizer,private router: Router,public dialog: MatDialog, private datePipe:DatePipe)  {
    this.minDate.setDate(this.minDate.getDate() - 30);
    this.current_url = this.router.url;
    if(this.current_url == "/return_dashboard"){
      let pexIndex = this.displayedColumns.indexOf("pexNumber" );
      if (pexIndex > -1) {
        this.displayedColumns.splice(pexIndex,1);
        this.columnsToDisplay.splice(pexIndex,1);
      }
      let pexReasonIndex = this.displayedColumns.indexOf("pexReason" );
      if (pexReasonIndex > -1) {
        this.displayedColumns.splice(pexReasonIndex,1);
        this.columnsToDisplay.splice(pexReasonIndex,1);
      }
      let statusIndex = this.displayedColumns.indexOf("status" );
      if (statusIndex > -1) {
        this.displayedColumns.splice(statusIndex,1);
        this.columnsToDisplay.splice(statusIndex,1);
      }
      let problemDescriptionIPGIndex = this.displayedColumns.indexOf("problemDescriptionIPG" );
      if (problemDescriptionIPGIndex > -1) {
        this.displayedColumns.splice(problemDescriptionIPGIndex,1);
        this.columnsToDisplay.splice(problemDescriptionIPGIndex,1);
      }

      /**commented below line for isa2022 enh*/
      // let idxd2c = this.displayedColumns.indexOf("d2c" );
      // if (idxd2c > -1) {
      //   this.displayedColumns.splice(idxd2c,1);
      //   this.columnsToDisplay.splice(idxd2c,1);
      // }
      delete this.columnsMapping['PEx Number'];
      delete this.columnsMapping['Pex Reason'];
      delete this.columnsMapping['Status'];
      delete this.columnsMapping['Problem Description'];

      // delete this.columnsMapping['IS_D2C'];      /**commented this for isa2022 enh*/
      this.title = "Return Report";
    }else if(this.current_url == "/dashboard"){
      let returnReasonIndex = this.displayedColumns.indexOf("returnReason" );
      if (returnReasonIndex > -1) {
        this.displayedColumns.splice(returnReasonIndex,1);
        this.columnsToDisplay.splice(returnReasonIndex,1);
      }
      delete this.columnsMapping['Return Reason'];
      let holderIndex = this.displayedColumns.indexOf("holder" );
      if (holderIndex > -1) {
        this.displayedColumns.splice(holderIndex,1);
        this.columnsToDisplay.splice(holderIndex,1);
      }
      delete this.columnsMapping['TRI/Holder'];

      let packingIndex = this.displayedColumns.indexOf("packing" );
      if (holderIndex > -1) {
        this.displayedColumns.splice(packingIndex,1);
        this.columnsToDisplay.splice(packingIndex,1);
      }
      delete this.columnsMapping['Packing'];
    }
    
    // let old_date =this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
      this.latest_date =this.datePipe.transform(this.maxDate, 'yyyy-MM-dd  h:mm:ss');
    this.bsRangeValue = [ this.minDate, this.maxDate];

    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));    
         
    // if(this.userLDapResonseRoles.rolesMap.code.includes('SA')  
    //  ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
    // ) 
    // {
    // }else{
      
    //   if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
    //   ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
      
    //   ){  
    //     this.router.navigate(['/repair']);
    //   } else if ( this.userLDapResonseRoles.rolesMap.code.includes('SP') ) {
    //     this.router.navigate(['/checkin']);
    //   } else {
    //     this.router.navigate(['/common']);
    //   }
       
    // } 

//     this.permissions = JSON.parse(localStorage.getItem('permissions'))
//     let permissionFlag;
//     if(this.permissions !== null){
//       this.permissions['dashboad.view_more_reports'].toLowerCase() == 'true'? this.viewMoreReports = true : this.viewMoreReports = false;
//       this.permissions['dashboad'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
//     }
//     if(!permissionFlag) {
// console.log("failed")
//       this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator1", "Close"); 
//       this.location.back();
//     }
    this.checkPermissions()

    if (this.httpService.loginCheckSession()) { }
    else {
        this.httpService.showSessionError();   
    } 
   }

   noSorting() {
    return 0;
  }
  
  ngOnInit() {
    let postData ={
      "startDate": this.bsRangeValue[0],
      "endDate":this.bsRangeValue[1],
      "soc":"",
      "filterD2C":this.D2CFilter,
      "technicalRequest":"",
      "serialNumber":"",
      "offSet":"0",
      "limit":"0"
      };
    this.fetchData(postData);
    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  async checkPermissions() {
    let permissionFlag = false;
    const resp = this.permissionsService.permissionData?this.permissionsService.permissionData : await this.permissionsService.checkPermissions();
    let permissions = resp['items']
    if(this.permissions !== null){
      permissions['dashboad.view_more_reports'].toLowerCase() == 'true'? this.viewMoreReports = true : this.viewMoreReports = false;
      this.current_url == "/dashboard"? permissions['dashboad.repair_d2c_reports'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false: '';
      this.current_url == "/return_dashboard"? permissions['dashboard.return_report'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false: '';
    }
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }

  }

  fetchData(data){
    console.log("Current URL = "+this.current_url);
    if(this.current_url == '/return_dashboard'){
      data['type']="RETURN";
    } else if(this.current_url == '/dashboard'){
      data['type']="REPAIR";
    }
    
    this.spinner.show();
    this.httpService.postRequest('checkInOperationApi/v1/listDashboard',data).subscribe(
    res => {
        this.spinner.hide();
        if (res['responseCode'] == '200') {
          this.data= res['items'];
          this.dataSource = new MatTableDataSource(this.data);
          this.dataSort()
          if (res['itemCount']==0) {
            var msg = "No Records were found for the selected date";
            this.openInformationDialog(msg);
          }
        } else {
          if (res['code'] == '400') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '500') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }else if (res['code'] == '600') {
            var msg = res['message'];
            this.openInformationDialog(msg);
          }
          else {
            var msg = 'Failed to process request. Please contact administrator.';
            this.openInformationDialog(msg);
          }
        }
      },
      err => {
        var errorMsg = err;
        this.spinner.hide();
      }
    );
  }     

  dataSort() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }

  openFilterDialog() {
    this.dialogref = this.dialog.open(FilterDialogComponent,{ panelClass: 'date-dialog-container' })
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
  // this.dialogref.componentInstance.coloumn = this.displayedColumns;
    this.dialogref.componentInstance.maxDate = this.maxDate;
    this.dialogref.componentInstance.minDate = this.minDate;
    this.dialogref.componentInstance.filter = this.D2CFilter;
    this.dialogref.componentInstance .pexNum = this.PexNum;

    this. dialogref.afterClosed().subscribe(res => {
      console.log(res.value);
     if(res&&res.value){
      this.fetchData(res.value);
       console.log(res.value.filterD2C)
       this.D2CFilter=res.value.filterD2C;
       this.bsRangeValue = [res.value.startDate, res.value.endDate];
       this.minDate=res.value.startDate;
       this.maxDate=res.value.endDate;
       this.PexNum=res.value.filterPEx;
     }
    })
  }

  setPageSizeOptions(event) {
  //  if (setPageSizeOptionsInput) {
   //   this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
 //   }
   console.log(event)
   console.log('coloumn',this.flag);
  let newOffset = event.pageSize * event.pageIndex;
  let postData ={
    "startDate": this.bsRangeValue[0],
    "endDate":this.bsRangeValue[1],
    "soc":"",
    "d2c":"",
    "filterD2C":this.D2CFilter,
    "technicalRequest":"",
    "serialNumber":"",
    "offSet":newOffset,
    "limit":"0"
    };
   
   if(this.flag){
     this.fetchData(postData);
    }
  }

  openDashboard() { 
    window.open( this.reportURL, "_blank");
}
routeSOC(){
  const link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: 6220293315 } }));
 window.open(link, '_blank');
}
getSOCInfo(soc) {
  if (!soc || (soc.toString().length != 10 )) {
    this.openInformationDialog("The SOC Number inserted is not valid, please proceed by inserting a valid one");
     return;
  }
  this.spinner.show();
  this.httpservice.getRequest('checkInOperationApi/v1/getSpareItemBySocNo?socNumber=' + soc)//+"&type="+this.type)
    .subscribe(res => {
      this.spinner.hide();
      const resp = res['response'];
      if (res['status'] == 'ERROR') {
        var msg = 'Failed to process request. Please contact administrator.';
        this.openInformationDialog(msg);
      }
      else {
        const sapRes = resp['sapResponse'];
        this.helperService.sapResponse = res;
        const sapResSerTypeDes = sapRes.etadditionalfields[0].value.toLowerCase();
        if (sapResSerTypeDes.includes('refurb')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['refurb-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['refurb-info'], { queryParams: { socId: soc } });

        }
        else if (sapResSerTypeDes.includes('repair')) {
          //  var link = this.router.serializeUrl(this.router.createUrlTree(['repair-info'], { queryParams: { socId: soc } }));
          //  link ='/#'+link;
          //   window.open(link, '_blank');
          this.router.navigate(['repair-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('healthcheck')) {
          // var link = this.router.serializeUrl(this.router.createUrlTree(['healthcheck-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['healthcheck-info'], { queryParams: { socId: soc } });
        }
        else if (sapResSerTypeDes.includes('kaextensive')) { //KACOMPLEXRE
          // var link = this.router.serializeUrl(this.router.createUrlTree(['extensiverepair-info'], { queryParams: { socId: soc } }));
          // link ='/#'+link;
          // window.open(link, '_blank');
          this.router.navigate(['extensiverepair-info'], { queryParams: { socId: soc } });
        }
        else {
          var msg = 'The Request type is invalid.Please contact administrator.';
          this.openInformationDialog(msg);
        }

      }
  
    }, err => {
      this.spinner.hide();
      if (err.status === 401) {
        this.openInformationDialog("Please login again")
      }
      else{
        var msg = 'Internal Issue.Please contact administrator.';
        this.openInformationDialog(msg);  
      }
    })
}

}


@Component({
  templateUrl: './filter.component.html',
  styleUrls: ['./dashboard.component.scss']

})
export class FilterDialogComponent implements OnInit {
  //coloumn: string[];
  filterForm:FormGroup;
  maxDate: any;
  minDate: any;
  bsRangeValue: any[];
  checked = false;
  filter:any;
  current_url="";

  constructor(@Inject(MAT_DIALOG_DATA) public info: any,public dialogRef: MatDialogRef<FilterDialogComponent>,private fb: FormBuilder, private router: Router) {
    this.current_url = this.router.url;
  }
  
  ngOnInit() {
    console.log(this.maxDate)
    console.log(this.minDate)
    this.bsRangeValue = [this.minDate, this.maxDate];
     this.filterForm = this.fb.group({
      technicalRequest: [''],
      soc: [''],
      filterD2C:[''],
      startDate:[this.minDate, [Validators.required]],
      endDate: [this.maxDate, [Validators.required]],
      serialNumber:[''],
      filterPEx:['']
    });  
  }

  onselectValue(){
    console.log('coloumn');
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  dateFilterChanged(bsRangeValue: string) {
    const d={'startDate': bsRangeValue[0],'endDate': bsRangeValue[1]};
    this.filterForm.patchValue(d);
  }

  close(){
    console.log('coloumn',this.filterForm.value);
    this.dialogRef.close(this.filterForm);
  }
}



@Component({
  selector: 'app-select-check-all',
  template: `
    <mat-checkbox class="mat-option"
                [disableRipple]="true"
                [indeterminate]="isIndeterminate()"
                [checked]="isChecked()"
                (click)="$event.stopPropagation()"
                (change)="toggleSelection($event)">
      {{text}}
    </mat-checkbox>
  `,
  styles: ['']
})
export class SelectCheckAllComponent implements OnInit {
  @Input() model: NgModel;
  @Input() values = [];
  @Input() text = 'Select All';

  constructor() { }

  ngOnInit() {
  }

  isChecked(): boolean {
    return this.model.value && this.values.length
      && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.model.value && this.values.length && this.model.value.length
      && this.model.value.length < this.values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.update.emit(this.values);
    } else {
      this.model.update.emit(['socNo']);
    }
  }
}
