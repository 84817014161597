import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from '../helper.service';
import { HttpService } from '../shared/services/http.service';
import { MatSnackBar } from '@angular/material';
import { APPCONFIG } from '../config';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent, WarningDialogComponent } from '../dialog/dialog.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.scss']
})
export class CheckOutComponent implements OnInit {
  dialogref: any;
  socNum: String = "";
  soNum: String = "";
  trayNo: String = "";
  showError: boolean = false;
  isError: boolean;
  isError1: boolean = false;
  isError2: boolean = false;
  isErrorMsg2: string = "";
  isErrorMsg1: string = "";
  disabledTab: boolean = false;
  trayNumberFlag: boolean = false;
  dsbTogl() {
    this.disabledTab = !this.disabledTab;
    this.step = 1;
    if (!this.disabledTab) {
      this.step = 0;
      //this.disabledTab = false ;
    }
  }
  favoriteSeason: string;

  seasons: string[] = ['SO Number', 'SOC Number'];
  step = 0;
  permissions = [];

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step = 0;
    this.disabledTab = !this.disabledTab;
  }

  userLDapResonseRoles: any;
  constructor(private location: Location,private commonService: CommonService, private _snackBar: MatSnackBar, private helperService: HelperService, private httpservice: HttpService,
    private route: Router, private spinner: NgxSpinnerService, public dialog: MatDialog) {
    /*
          this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles')); 
          if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')
            ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
            ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 
          {
          }else{
            // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
            // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
            // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
            // ){  
            //   this.route.navigate(['/scrap']);
            // }else{
              this.route.navigate(['/common']);
            // }
          }
    */
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['packing'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }
    
    if (this.httpservice.loginCheckSession()) { }
    else {
      this.httpservice.showSessionError();

    }
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 4000,
      data: {
        icon: 'Close'
      },
      panelClass: ['isa-snackbar']
    });
  }


  ngOnInit() {
    this.favoriteSeason = 'SO Number';
    if(this.permissions !== null)
    this.permissions['repaire/refurb-trayid'].toLowerCase() == 'true'? this.trayNumberFlag = true : this.trayNumberFlag = false;
    if (!this.trayNumberFlag) {
      console.log("here", this.trayNumberFlag);
      this.setStep(1);
      }
    }
  // checkTrayNO(){
  //   this.spinner.show();
  //   this.httpservice.getRequest('checkInOperationApi/v1/checkTrayStatus?trayId='+this.trayNo)
  //   .subscribe(res=>{
  //     const resp=res['response'];
  //       if(res['status']=='SUCCESS'){
  //         this.route.navigate(['checkout-report'],{queryParams:{trayId:this.trayNo,itemId:resp.itemId,type:resp.type}});
  //       }

  //       else{
  //         this.isError = true;
  //       }
  //       this.spinner.hide();

  //   },err=>{
  //     this.isError = true;
  //     this.spinner.hide();
  //   });

  // }

  checkTrayNO() {
    var self = this;
    if (this.trayNo.toString().length != 3) {
      this.isError = true;
      self.openSnackBar("The Tray Number inserted is not valid, please proceed by inserting a valid one", "Close")
      return;
    }
    this.spinner.show();
    this.httpservice.getRequest('checkOutOperationApi/v1/getDetailByTrayNO?trayNO=' + this.trayNo)//+"&type="+this.type)
      .subscribe(res => {
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          this.isError = true;
          if (res['response'] == 'Appliance is scrapped') {
            //  self.openSnackBar("The Tray  Designated for Scrapping", "Close")
            var msg = 'Tray ' + this.trayNo + ' Designated for Scrapping.';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Appliance is request pex') {
            //     self.openSnackBar("The Tray  Designated for Request PEX", "Close")
            var msg = 'Tray ' + this.trayNo + ' Designated for Request PEX.';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Tray Number:' + this.trayNo + 'is in process') {
            //  self.openSnackBar("The Tray Number is still in progress and cannot be used for Packing", "Close")
            var msg = 'Tray ' + this.trayNo + ' is Not Handled yet and cannot be used for Packing';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Appliance is scrapped') {
            var msg = 'Appliance is scrapped and cannot be used for Packing';
            this.openInformationDialog(msg);
          }
          else {
            //  self.openSnackBar("The Tray Number is free or not valid. Please contact the Support Team", "Close")
            var msg = 'Tray ' + this.trayNo + ' is free yet and cannot be used for Packing';
            this.openInformationDialog(msg);
          }

        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          this.route.navigate(['checkout-report'], { queryParams: { trayId: this.trayNo } });
        }
        this.spinner.hide();
      }, err => {
        // console.log('error');
        this.isError = true;
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        } else {
          //  self.openSnackBar("Internal Issue. Please contact the Support Team", "Close")
          var msg = 'Internal Issue. Please contact the Support Team';
          this.openInformationDialog(msg);

        }
        this.spinner.hide();
      })
  }


  getSOCInfo() {
    let self = this;
    if (!this.socNum || !(this.socNum.toString().length == 10)) {
      self.openSnackBar("The SOC Number inserted is not valid, please proceed by inserting a valid one", "Close")
      // console.log("test", this.socNum.toString().length)
      return;

    }
    this.spinner.show();
    this.httpservice.getRequest('checkOutOperationApi/v1/getSpareItemBySocNo?socNumber=' + this.socNum)//+"&type="+this.type)
      .subscribe(res => {
        this.helperService.hideModal('TextBoxDialog');
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          if (res['response'] == 'Appliance is scrapped') {
            var msg = 'SOC ' + this.socNum + 'Designated for Scrapping.';
            this.openInformationDialog(msg);
            //  self.openSnackBar("The Tray  Designated for Scrapping", "Close")
          }
          else if (res['response'] == 'Appliance is request pex') {
            //  self.openSnackBar("The Tray  Designated for Request PEX", "Close")
            var msg = 'SOC ' + this.socNum + ' Designated for Request PEX';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Soc Number:' + this.socNum + ' is in process') {
            // self.openSnackBar("The SOC Number is still in progress and cannot be used for Packing", "Close")
            var msg = 'SOC ' + this.socNum + ' is Not Handled yet and cannot be used for Packing';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Invalid SOC number') {
            // self.openSnackBar("The SOC Number is still in progress and cannot be used for Packing", "Close")
            var msg:string = res['response'] || ' Something went wrong! Please try again later.'
            this.openInformationDialog(msg);
          }

          else {
            //  self.openSnackBar("The SOC Number is in the process. Please contact the Support Team", "Close")
            var msg = 'SOC ' + this.socNum + ' is in the process. Please contact the Support Team';
            this.openInformationDialog(msg);
          }
          // this.isErrorMsg1 = 'The SOC Number is in the process. Please contact the administrator';
          this.isError1 = true;
          // this.helperService.openModal('conform_Dialog');
        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          this.route.navigate(['checkout-report'], { queryParams: { socId: this.socNum } });
        }
        this.spinner.hide();
      }, err => {
        this.isError1 = true;
        this.isErrorMsg1 = 'Internal Issue. Please contact the Support Team';
        // this.helperService.openModal('conform_Dialog');
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        }
        else {
          var msg = 'Internal Issue. Please contact the Support Team';
          this.openInformationDialog(msg);
        }
        // self.openSnackBar("Internal Issue. Please contact the Support Team", "Close")
        this.spinner.hide();
      })
  }

  getSOInfo() {
    let self = this;
    if (!this.soNum || !(this.soNum.toString().length == 10) || !(this.soNum.toString().startsWith('621'))) {
      self.openSnackBar("The SO Number inserted is not valid, please proceed by inserting a valid one", "Close")
      // console.log("test", this.soNum.toString().length)
      return;
    }
    this.spinner.show();
    this.httpservice.getRequest('checkOutOperationApi/v1/getSpareItemBySoNo?soNumber=' + this.soNum)//+"&type="+this.type)
      .subscribe(res => {
        this.helperService.hideModal('TextBoxDialog');
        const resp = res['response'];
        if (res['status'] == 'ERROR') {
          //    this.isErrorMsg2 = 'The SO Number is in the process. Please contact the administrator';
          this.isError2 = true;
          if (res['response'] == 'Appliance is scrapped') {
            //  self.openSnackBar("The Tray  Designated for Scrapping", "Close")
            var msg = 'SO Number ' + this.soNum + 'Designated for Request PEX';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'Appliance is request pex') {
            //  self.openSnackBar("The Tray  Designated for Request PEX", "Close")
            var msg = 'SO Number ' + this.soNum + 'Designated for Request PEX';
            this.openInformationDialog(msg);
          }
          else if (res['response'] == 'so Number:' + this.soNum + 'is in process') {
            //     self.openSnackBar("The SO Number is still in progress and cannot be used for Packing", "Close")
            var msg = 'SO Number ' + this.soNum + 'is Not Handled yet and cannot be used for Packing';
            this.openInformationDialog(msg);

          }
          else {
            //  self.openSnackBar("The SO Number is in the process. Please contact the Support Team", "Close")
            var msg = 'SO Number ' + this.soNum + 'is in the process. Please contact the Support Team';
            this.openInformationDialog(msg);
          }
          // this.helperService.openModal('conform_Dialog');
        }
        else {
          const sapRes = resp['sapResponse'];
          //setting response in helperservice class variable
          this.helperService.sapResponse = res;
          this.route.navigate(['checkout-report'], { queryParams: { soId: this.soNum } });
        }
        this.spinner.hide();
      }, err => {
        this.isError2 = true;
        // this.isErrorMsg2 = 'Internal Issue. Please contact the Support Team';
        // this.helperService.openModal('conform_Dialog');
        if (err.status === 401) {
          self.openSnackBar("Please login again", "Close")
        }
        else {
          var msg = 'Internal Issue. Please contact the Support Team';
          this.openInformationDialog(msg);
        }
        //  self.openSnackBar("Internal Issue. Please contact the Support Team", "Close")
        this.spinner.hide();
      })
  }



  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.checkTrayNO();
    }
  }
  onKeydownEventSo(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getSOInfo();

    }
  }
  onKeydownEventSoc(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getSOCInfo();

    }
  }

  openInformationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent)
      .addPanelClass(['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog'])
    this.dialogref.componentInstance.msg = msg;
    this.dialogref.componentInstance.isNotConformation = true;
  }

  checkLen() {
    if (this.trayNo.length == 3) return false;
  }

}
