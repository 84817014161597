import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { HelperService } from './../helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from '../shared/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { disableDebugTools } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent, ConfirmDialogComponent, ConfirmationDialogComponent, WarningDialogComponent } from '../dialog/dialog.component'
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { PermissionService } from 'app/shared/services/permission.service';

@Component({
  selector: 'app-check-in-refurb',
  templateUrl: './check-in-refurb.component.html',
  styleUrls: ['./check-in-refurb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckInRefurbComponent implements OnInit {
  matCheckUFCE = false;
  matCheckCR = false;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  @ViewChild('insform', { static: true }) form;
  @ViewChild(MatAutocompleteTrigger, { static: true }) inputAutoComplit: MatAutocompleteTrigger;

  @ViewChild('focusInput', { static: true }) focusInput: ElementRef;

  setTableRow: any = null;
  // SOCTypeDetail: any;/**Commented, becuase soc type is removed. */
  permissions = [];
  openPanel(evt): void {
    evt.stopPropagation();
    this.inputAutoComplit.openPanel();
  }
  entry: any = null;
  applianceForm: FormGroup;
  inspectionForm: FormGroup;
  applianceList: any = [];
  inspectionAppl: any;
  undoList: any = [];
  saveInspectionResponse: any;
  undoMap: Map<any, any> = new Map<any, any>();
  enableMultiplePutIn: boolean = false;
  enableUndoMultiple: boolean = false;
  selectedAppliances: any = [];
  otherSelectedAppliances: any = [];
  requestNo: String;
  serialNoPattern = "/^[W|w]{1}[A-Za-z0-9]{1}(([0-4]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}$/";
  itemId: String;
  applianceErrorMsg: String = "";
  isApplianceError: boolean = false;
  errorFetcheancode: boolean = false;
  inspectionErrorMsg: String = "";
  isInspectionError: boolean = false;
  pageLimit: number = 10;
  pageLimit1: number = 10;
  currentPage: number = 1;
  currentPage1: number = 1;
  currentStack: any = [];
  newProcessedItem = [];
  pageCount: number;
  pageCount1: number;
  searchText: String;
  entriesArray: any = [];
  entriesArray1: any = [];
  putinMultipleFirstChoiceFlag: boolean = false;
  undoMultipleFirstChoiceFlag: boolean = false;
  modelNumberFlag: boolean = false;
  EanCode: boolean = true;
  Mnumber: any;
  hiddenTrayId: boolean = false;
  testMap: any;
  lengthMap: any;
  dataEancode: any;
  dataEancodeFilter: any;
  dialogref: any;
  trayNumberFlag: boolean = false;
  holderNumberFlag: boolean = false;
  fp_nfp_flag: boolean = false;
  companyId='';

  //entriesArray1: any;
  userLDapResonseRoles: any;
  constructor(private locationFORURL: Location,private commonService: CommonService, private fb: FormBuilder, private helperService: HelperService,
    private httpservice: HttpService,
    private route: ActivatedRoute, private spinner: NgxSpinnerService, private httpService: HttpService, private router: Router, public dialog: MatDialog,
    private permissionsService: PermissionService,private hostElement: ElementRef) {

    // this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
    /*

    if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')

        ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')

        ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')) 

    {

    }else{

      

      // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              

      // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')

      // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')

      // ){  

      //   this.router.navigate(['/scrap']);

      // }else{

        this.router.navigate(['/common']);

      // }

    }

*/
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['checkInRefurb'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.locationFORURL.back();
    }

    if (this.httpservice.loginCheckSession()) { }
    else {
      this.httpservice.showSessionError();
    }
    this.route.queryParams.subscribe(params => {
      this.requestNo = params['requestNo'];
    });
    if ((this.requestNo == null || this.requestNo == undefined || this.requestNo == "")) {
      this.router.navigate(['checkin']);
      return;
    }
    this.companyId=this.permissionsService.companyId;

  }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    if (this.permissions !== null) {
      this.permissions['receiving.refurb.create_soc.tray_number'].toLowerCase() == 'true' ? this.trayNumberFlag = true : this.trayNumberFlag = false;
      this.permissions['receiving.refurb.create_soc.holder_number'].toLowerCase() == 'true' ? this.holderNumberFlag = true : this.holderNumberFlag = false;
      this.permissions['factory/non-factory-pack'].toLowerCase() == 'true' && this.companyId !='2002' ? this.fp_nfp_flag = true : this.fp_nfp_flag = false;
    }

    this.applianceForm = this.fb.group({
      modelNO: ['', [Validators.required]],
      item12NC: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      //  reason: ['', [Validators.required]]
    });

    this.inspectionForm = this.fb.group({
      serialNo: ['', [Validators.required, Validators.pattern(/^((229)$)|^((000)$)|(([y|Y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{2})|([W|w|Y|y]{1}[A-Za-z0-9]{1}(([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5})|([W|w|Y|y]{1}[A-Za-z0-9]{1}[A-Za-z0-9](([0-9]{1}[0-9]{1})|([5]{1}[0-3]{1}))\d{5}))$/)]],
      trayId: ['', [Validators.min(100), Validators.max(999)]],
    });

    if (this.fp_nfp_flag) {
      this.inspectionForm.addControl('factoryPack', new FormControl("", [Validators.required]));
      this.inspectionForm.controls['factoryPack'].updateValueAndValidity();
    } else
      this.inspectionForm.removeControl('factoryPack');

    if (this.holderNumberFlag) {
      this.inspectionForm.addControl('holderNumber', new FormControl("", [Validators.required, Validators.maxLength(9), Validators.pattern(/([S|s]{1}[T|t]{1}\d{6})/)]));
      this.inspectionForm.controls['holderNumber'].updateValueAndValidity();
    } else
      this.inspectionForm.removeControl('holderNumber');

    // if (this.trayNumberFlag) {
    //   this.inspectionForm.addControl('trayNumber', new FormControl("", [Validators.required, Validators.min(100), Validators.max(999), Validators.minLength(3), Validators.maxLength(3)]));
    //   this.inspectionForm.controls['trayNumber'].updateValueAndValidity();
    // } else
    //   this.inspectionForm.removeControl('trayNumber');
    this.inspectionForm.updateValueAndValidity();
    this.fetchReturnOrder();
    this.fetchEancode();

  }

  saveAppliance() {
    this.applianceForm.controls['item12NC'].enable();
    if (!this.applianceForm.valid) {
      this.helperService.makeFormDirty(this.applianceForm);
      return;
    }
    var appliance = this.applianceForm.value;
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/addAppliance?modelNO=' + appliance.modelNO + "&sapReturnOrderId=" + this.requestNo + "&item12NC=" + appliance.item12NC + "&quantity=" + appliance.quantity).subscribe(
      res => {
        this.applianceList = res;
        if (this.applianceList.quantity > 0) {
          this.entriesArray = Object.entries(this.applianceList.item12NCmap);

        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }

        this.isApplianceError = false;
        this.helperService.hideModal('Add-Appliance');
        this.setPage(this.currentPage);
        this.applianceForm.reset();
        this.spinner.hide();
      },
      err => {
        this.applianceErrorMsg = "some error occured while adding new appliance";
        this.isApplianceError = true;
        // console.log("error occured while adding new appliances");
        this.spinner.hide();
      }
    );
  }
  reduceCurrentStack(index, itemIndex) {
    var itemReduce = this.currentStack[index][1][itemIndex];
    var id = this.currentStack[index][0];
    var itemPushIndex = this.newProcessedItem.findIndex(n => n[0] == id);
    var count = this.pageLimit;
    for (var i = 1 - 1; i < 10; i++) {
      if (count == 0) {
        break;
      }
      if (this.currentStack != undefined) {
        if (id == this.currentStack[i][0]) {
          this.currentStack[index][1].splice(itemIndex, 1);
          break;
        }
        count--;
      }
    }
    this.newProcessedItem = [];
    var entriesArray1 = this.getFilteredArray1();
    var length1 = entriesArray1.length;
    var start1 = this.pageLimit1 * (1 - 1) + 1;
    var end1 = this.pageLimit1 * (1);
    var count1 = this.pageLimit1;
    this.pageCount1 = Math.ceil(length / this.pageLimit1);
    this.currentPage1 = 1;
    if (start1 > length1) {
      this.spinner.hide();
      return;
    }
    if ((end1 + 1) > length1) {
      end1 = length1;
    }

    for (var i = start1 - 1; i < end1; i++) {
      if (count1 == 0) {
        break;
      }
      if (entriesArray1 != undefined) {
        this.newProcessedItem.push(entriesArray1[i]);
        count--;
      }
    }

  }

  putinFirstChoice(item_ids, index, itemIndex, entryLength) {

    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/firstChoice?itemId=' + item_ids + "&sapReturnOrderId=" + this.requestNo).subscribe(
      res => {
        this.applianceList = res;
        if (this.applianceList.quantity > 0) {
          this.entriesArray = Object.entries(this.applianceList.item12NCmap);


        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }
        if (this.putinMultipleFirstChoiceFlag == true || entryLength[1].length == 1) {
          this.setPage(this.currentPage);
          this.putinMultipleFirstChoiceFlag = false;
        }
        else {
          this.reduceCurrentStack(index, itemIndex);
        }
        this.selectedAppliances = [];
        this.enableMultiplePutIn = false;
        //  this.setPage(this.currentPage);
        this.spinner.hide();
      },
      err => {
        alert("Invalid request");
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }


  checkLen() {
    if (this.inspectionForm.get('serialNo').value.length == 10) return false;
  }

  reducenewProcessedItem(index, itemIndex) {
    var itemReduce = this.newProcessedItem[index][1][itemIndex];
    var id = this.newProcessedItem[index][0];
    var itemPushIndex = this.currentStack.findIndex(n => n[0] == id);
    var count = this.pageLimit;
    for (var i = 1 - 1; i < 10; i++) {
      if (count == 0) {
        break;
      }
      if (this.newProcessedItem != undefined) {
        if (id == this.newProcessedItem[i][0]) {
          this.newProcessedItem[index][1].splice(itemIndex, 1);
          // this.currentStack[itemPushIndex][1].push(itemReduce);
          break;
        }
        count--;
      }
    }


    this.currentStack = []
    var entriesArray = this.getFilteredArray();
    var length = entriesArray.length;
    var start = this.pageLimit * (1 - 1) + 1;
    var end = this.pageLimit * (1);
    var count = this.pageLimit;
    this.pageCount = Math.ceil(length / this.pageLimit);
    this.currentPage = 1;
    if (start > length) {
      this.spinner.hide();
      return;
    }
    if ((end + 1) > length) {
      end = length;
    }
    for (var i = start - 1; i < end; i++) {
      if (count == 0) {
        break;
      }
      if (entriesArray != undefined) {
        this.currentStack.push(entriesArray[i]);
        count--;
      }
    }
  }


  undoPutIn(item_id, index, itemIndex, map) {


    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/undoFirstChoice?itemId=' + item_id + "&sapReturnOrderId=" + this.requestNo).subscribe(
      res => {
        this.applianceList = res;
        if (this.applianceList.quantity > 0) {
          this.entriesArray = Object.entries(this.applianceList.item12NCmap);

        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }
        this.otherSelectedAppliances = [];
        this.enableUndoMultiple = false;
        if (this.undoMultipleFirstChoiceFlag == true || map[1].length == 1) {
          this.setPage(this.currentPage);
          this.undoMultipleFirstChoiceFlag = false;
        }
        else {
          this.reducenewProcessedItem(index, itemIndex);
        }
        this.spinner.hide();
      },
      err => {
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }

  putinMultipleFirstChoice() {
    var item_ids = "";
    this.putinMultipleFirstChoiceFlag = true;



    for (let i = 0; i < this.selectedAppliances.length; i++) {
      if (item_ids == "") {
        item_ids = this.selectedAppliances[i].id;
      }
      else {
        item_ids = this.selectedAppliances[i].id + "," + item_ids;
      }
    }
    this.putinFirstChoice(item_ids, null, null, null);
  }
 //setInspectionAppliance(appliance,SOCtype){
  setInspectionAppliance(appliance){
    this.inspectionForm.reset(this.inspectionForm.value);
    // if ((SOCtype == null || SOCtype == undefined || SOCtype == "")) { /**Commented, becuase soc type is removed. */
    //   return;
    // }

    this.hiddenTrayId=false;
    const serialNo= appliance.serialNo && appliance.serialNo!='undefined' ?appliance.serialNo:'';
    this.inspectionForm.get('serialNo').setValue(serialNo);
    this.itemId=appliance.id;
    
    /**Commented, becuase soc type is removed. */
    //  if(SOCtype=='Direct Scrap'||(SOCtype=='1st Choice' && this.applianceList.reason.includes('commercial')))
    // {
    //   this.hiddenTrayId=true;
    //   this.inspectionForm.get('trayId').clearValidators();
    // }else{
    //   this.inspectionForm.get('trayId').setValidators([Validators.required, Validators.min(100), Validators.max(999)]);
    // }
    // this.inspectionForm.get('trayId').updateValueAndValidity();
    // this.SOCTypeDetail=SOCtype; 
    /**   --- -- ---    */
    this.helperService.openStaticModal('inspection-Dialog');
    setTimeout(()=>{
      this.focus()
    },1000)
  }

  clearInspectionForm() {
    this.inspectionAppl = null;
    this.inspectionForm.reset();
    this.form.resetForm();
    this.helperService.hideModal('inspection-Dialog');
  }

  saveInspection() {
    // if(!this.inspectionForm.valid){
    //   this.helperService.makeFormDirty(this.inspectionForm);
    //   return;
    // }
    this.spinner.show();
    var insp = this.inspectionForm.value;
    insp.requestId=this.requestNo;
    insp.itemId=this.itemId;
    insp.partnerId=this.applianceList.tradePartnerId;
    // insp.action=this.SOCTypeDetail; /**Commented, becuase soc type is removed. */
    insp.trayConfig = {};
    if (!this.trayNumberFlag)
      insp.trayConfig.trayIdFlag = this.trayNumberFlag;
    if (!this.holderNumberFlag)
      insp.trayConfig.holderNumberFlag = this.holderNumberFlag;
      
    this.httpService.postRequest('/checkInOperationApi/v1/requestInspection',insp)
    .subscribe(res=>{
      this.spinner.hide();
      if(res['status']=='ERROR'){
        this.inspectionErrorMsg= res['response'];
        this.isInspectionError =true;
        setTimeout(()=>{
          this.isInspectionError =false;
        },2000)
      }else{
        this.saveInspectionResponse=res['response'];
       this.saveInspectionResponse.serialNo=this.inspectionForm.get('serialNo').value
        
       this.helperService.openStaticModal('ConfirmDialog');
        this.fetchReturnOrder();
        this.isInspectionError =false;
        this.inspectionForm.reset();
        this.form.resetForm();
        this.helperService.hideModal('inspection-Dialog');
      }
    }, err => {
        this.spinner.hide();
      });
  }
  
  changeEvent(event, appliance) {


    if (event.target.checked) {
      this.selectedAppliances.push(appliance)
    }
    else {
      this.selectedAppliances.splice(this.selectedAppliances.indexOf(appliance), 1);
    }
    //enable/disable multiple put in button
    if (this.selectedAppliances.length > 1)
      this.enableMultiplePutIn = true;

    else
      this.enableMultiplePutIn = false;
  }

  undoFirstChoiceEvent(event, appliance) {
    if (event.target.checked) {
      this.otherSelectedAppliances.push(appliance)
    }
    else {
      this.otherSelectedAppliances.splice(this.otherSelectedAppliances.indexOf(appliance), 1);
    }
    //enable/disable multiple put in button
    if (this.otherSelectedAppliances.length > 1)
      this.enableUndoMultiple = true;
    else
      this.enableUndoMultiple = false;
  }

  undoMultipleFirstChoice() {
    this.undoMultipleFirstChoiceFlag = true
    var item_ids = "";
    for (let i = 0; i < this.otherSelectedAppliances.length; i++) {
      if (item_ids == "") {
        item_ids = this.otherSelectedAppliances[i].id;
      }
      else {
        item_ids = this.otherSelectedAppliances[i].id + "," + item_ids;
      }
    }
    this.undoPutIn(item_ids, null, null, null);
  }



  clearApplianceForm() {
    this.applianceForm.reset();
    this.helperService.hideModal('Add-Appliance');
    // this.dataEancode.length=0;
    this.dataEancodeFilter.length = 0;
  }

  redirect() {
    this.helperService.hideModal('ConfirmDialog');
  }

  fetchReturnOrder() {
    this.spinner.show();
    if (this.requestNo == undefined || this.requestNo == '') {
      this.applianceList = {};
      return;
    }
    this.httpService.getRequest('checkInOperationApi/v1/returnOrder?sapReturnOrderId=' + this.requestNo).subscribe(
      res => {
        this.applianceList = res;
        if (this.applianceList.quantity > 0) {
          this.entriesArray = Object.entries(this.applianceList.item12NCmap);

        }
        if (this.applianceList.processedItems) {
          this.entriesArray1 = Object.entries(this.applianceList.processedItems);
        }
        this.setPage(1);
        this.spinner.hide();

      },
      err => {
        alert("Invalid request");
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }
  setPage(pageNumber) {
    this.spinner.show();
    this.currentStack = [];
    this.newProcessedItem = [];

    if ((this.applianceList.quantity == 0) && !(this.applianceList.processedItems))
      return;

    var entriesArray = this.getFilteredArray();
    var entriesArray1 = this.getFilteredArray1();

    var length = entriesArray.length;
    var start = this.pageLimit * (pageNumber - 1) + 1;
    var end = this.pageLimit * (pageNumber);
    var count = this.pageLimit;
    this.pageCount = Math.ceil(length / this.pageLimit);
    this.currentPage = pageNumber;

    var length1 = entriesArray1.length;
    var start1 = this.pageLimit1 * (pageNumber - 1) + 1;

    if (start > length && start1 > length1) {
      this.spinner.hide();
      return;
    }
    if ((end + 1) > length) {
      end = length;
    }

    for (var i = start - 1; i < end; i++) {
      if (count == 0) {
        break;
      }
      if (entriesArray != undefined) {
        this.currentStack.push(entriesArray[i]);
        count--;
      }
    }


    var end1 = this.pageLimit1 * (pageNumber);
    var count1 = this.pageLimit1;
    this.pageCount1 = Math.ceil(length1 / this.pageLimit1);
    this.currentPage1 = pageNumber;
    if (start1 > length1) {
      this.spinner.hide();
      return;
    }
    if ((end1 + 1) > length1) {
      end1 = length1;
    }

    for (var i = start1 - 1; i < end1; i++) {
      if (count1 == 0) {
        break;
      }
      if (entriesArray1 != undefined) {
        this.newProcessedItem.push(entriesArray1[i]);
        count--;
      }
    }

    this.spinner.hide();
  }
  getFilteredArray1() {
    let searchText = this.searchText;
    if (searchText == undefined || searchText == '') {
      return this.entriesArray1;
    }

    var func = this.filterObject;
    var entriesArray1 = this.entriesArray1.filter(function (obj: any[]) {
      if (obj[0].toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      else if (obj[1].length == 0) {
        return false;
      } else {
        let items = obj[1].filter(function (o) {
          return func(o, searchText, func);
        });
        return items.length > 0;
      }
    });
    return entriesArray1;
  }



  getFilteredArray() {
    let searchText = this.searchText;
    if (searchText == undefined || searchText == '') {
      return this.entriesArray;
    }

    var func = this.filterObject;
    var entriesArray = this.entriesArray.filter(function (obj: any[]) {
      if (obj[0].toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      else if (obj[1].length == 0) {
        return false;
      } else {
        let items = obj[1].filter(function (o) {
          return func(o, searchText, func);
        });
        return items.length > 0;
      }
    });
    return entriesArray;
  }



  filterObject(object, searchText, func) {
    return Object.keys(object).some(function (k) {
      //bypass keys if not applicable for filter
      if (k == 'id' || k == 'quantity' || k == 'requestNo') {
        return false;
      }
      if (typeof object[k] == 'string') {
        return object[k].toLowerCase().includes(searchText.toLowerCase());
      } else if (typeof object[k] == 'number') {
        return object[k].toString().toLowerCase().includes(searchText.toLowerCase());
      } else if (typeof object[k] == 'object') {
        return func(object[k], searchText, func);
      }
    });
  }

  backButton() {
    window.location.href = '#/checkin';
  }
  fetchEancode() {
    this.errorFetcheancode = false;
    var modelNumber = this.applianceForm.get('modelNO').value;
    // if (modelNumber.toString().length == 0||modelNumber.toString().length == 2 ) {
    //   return;
    // }
    this.spinner.show();
    this.httpService.getRequest('checkInOperationApi/v1/getEanCodeModel?modelCode=' + modelNumber).subscribe(
      res => {
        this.spinner.hide();
        this.dataEancode = res['response']
        if (this.dataEancode.length > 0) {
          // const d={'item12NC': this.dataEancode.eancode};
          // this.applianceForm.patchValue(d);
          this.loadFilter();
        }
        else {
          this.errorFetcheancode = true;
        }
      },
      err => {
        alert("Invalid request");
        // console.log("error occured while fetching ean code");
        this.errorFetcheancode = true;
        this.spinner.hide();

      }
    );
  }
  loadFilter() {
    this.dataEancodeFilter = this.applianceForm.get('modelNO').valueChanges
      .pipe(
        startWith(''),
        map(val => this._filter(val))
      );
  }


  private _filter(value: string): string[] {
    if ((value === undefined || value === null) || (this.dataEancode === undefined || this.dataEancode === null))
      return;
    const filterValue = value.toLowerCase();
    var data = this.dataEancode.filter(option => String(option.matnr12nc).toLowerCase().indexOf(filterValue) > -1 ||
      option.commCode.toLowerCase().indexOf(filterValue) > -1);
    return data
  }

  // onselectEanValue(modelCode){
  //   console.log("===",modelCode)
  //   const d={'modelNO': modelCode};
  //  this.applianceForm.patchValue(d);
  // }

  onselectModelValue(event) {
    this.modelNumberFlag = event.option._selected;
    var codeFound = this.dataEancode.find(x => x.commCode == event.option.value);
    if (codeFound != undefined && event.option.value == codeFound.commCode) {
      const d = { 'item12NC': codeFound.matnr12nc };
      this.applianceForm.patchValue(d);
      this.applianceForm.controls['item12NC'].disable();
    }
  }

  focusOutModelNumber(code) {
    if (code == null || code == "") { code = undefined; }
    if (code != undefined) {
      var codeFound = this.dataEancode.find(x => x.commCode == code);
      if (codeFound != undefined && code == codeFound.commCode) {
        this.modelNumberFlag = true;
      }
      else {
        this.modelNumberFlag = false;
      }
    }
    //  else if (code == "" || code == null){this.modelNumberFlag = false;}

  }
  ngOnDestroy() {
    this.helperService.hideAllModal()
  }

  closeSearch() {
    this.searchText = '';
    this.setPage(1);
  }

  confromInspection() {
    if (!this.inspectionForm.valid) {
      this.helperService.makeFormDirty(this.inspectionForm);
      return;
    }
    // var msg = 'Do you confirm this item is '+this.SOCTypeDetail+'?';/**Commented, becuase soc type is removed */
    var msg = 'Do you confirm ?'
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.saveInspection();
      }
    });
  }

  openconfromationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        message: msg
      },
      panelClass: ['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog']
    });
  }

    focus(){
      this.focusInput.nativeElement.focus();
    }
}


