import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userProfileLDapResonse: any;
  userName: string="";
  email: string="example@gmail.com";
  role: string="System Admin"
  wpass: string="";
  lang:string="";
  permissions: any;

  constructor(private location: Location,private commonService: CommonService, private router: Router) {}

  ngOnInit() {
    this.userProfileLDapResonse = JSON.parse(localStorage.getItem('loggedUserResponse'));
    this.permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissionFlag = false;
    if(this.permissions !== null)
    this.permissions['profile'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
    if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }
    // console.log('this.userProfileLDapResonse::'+this.userProfileLDapResonse);   
    //var json = JSON.parse(this.userProfileLDapResonse);
    //console.log("json.userId::"+json.userId);
    //this.userName=json.userName;
    //this.wpass=json.userId;    
    //this.lang=json.language;
  }

}
