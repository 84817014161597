import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { HelperService } from './../helper.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-check-out-print',
  templateUrl: './check-out-print.component.html',
  styleUrls: ['./check-out-print.component.scss']
})
export class CheckOutPrintComponent implements OnInit {
  trayFreeErrorMsg: string='';
  confirmMsg: string='';
  trayFreeError: boolean=false;
  trayNo: any;
  permissions: any;

  constructor(private location: Location,private commonService: CommonService,  private httpService: HttpService,private router : Router, private helperService: HelperService,private spinner: NgxSpinnerService,private route: ActivatedRoute,) { 
    this.route.queryParams.subscribe(params => {
      this.trayNo = params['trayId'];
     });
     this.permissions = JSON.parse(localStorage.getItem('permissions'))
     let permissionFlag = false;
     if(this.permissions !== null)
     this.permissions['checkOutPrint'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
     if(!permissionFlag) {
      this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
      this.location.back();
    }
     
  }

  ngOnInit() {

  }
  // closePrint(){
  //   window.location.href="#/checkout";
  // }
  clearTray(){
    console.log('in the clearTray func');
    this.httpService.getRequest('checkInOperationApi/v1/clearTray?trayId='+this.trayNo)
   .subscribe(res=>{
    console.log(2);
    if(res['status']=='ERROR'){
      console.log(10);
      this.trayFreeErrorMsg= res['response'];
      this.confirmMsg=res['response'];
      this.helperService.openStaticModal('conform_Dialog');
    }else{
      this.spinner.hide();
        this.trayFreeError = false;
        this.helperService.openStaticModal('conform_Dialog');
        this.confirmMsg="Request Submitted Successfully ";

    }
    },err=>{
     this.spinner.hide();
    }) 
 
   }
 
   redirect(path){
    window.location.href = path;
  }
}
