import { Component, OnInit,ViewChild, Inject, ChangeDetectorRef, ElementRef} from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { NgxSpinnerService } from "ngx-spinner";
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'app/helper.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { debug } from 'util';
import { APPCONFIG } from '../config';
import {DialogComponent,ConfirmDialogComponent , ConfirmationDialogComponent,WarningDialogComponent} from '../dialog/dialog.component'
import { async } from '@angular/core/testing';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('focusInput', { static: true }) focusInput: ElementRef;

  userList: any=[];

  displayedColumns = [ 'userFullName', 'userName', 'companyId','Role','action'];
  // dataSource: MatTableDataSource<any>;
  dataSource: MatTableDataSource<UserDetailsInterface>;
  

  userLDapResonseRoles: any;
  isError:boolean;
  result: any;
  userDetailForm: FormGroup;
  userEditForm: FormGroup;
  @ViewChild('userDform', {static:true}) form;
  saveUserDetailFormResponse:any;
  newUser : boolean = true;
  userDetailErrorMsg:String = "";
  userEditErrorMsg:String = "";
  isUserDetailError:boolean = false;
  isUserEditError:boolean = false;
  userDetailAppl;
  userEditAppl;
  dialogref;
  UserrResponse:String = "";
  permissions: any;
  currentCompany;
  constructor(private location: Location,private commonService: CommonService, private helperservice: HelperService, private spinner: NgxSpinnerService, private httpService:HttpService, public dialog: MatDialog, private router : Router
  ,private fb: FormBuilder) {
      this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));    
      // this.permissionSrv.checkPermission( [PermissionEnum.tray])
      // .subscribe(res => {
      //     if(!res.get(PermissionEnum.tray)) {
      //       this.router.navigate(['/common']);
      //     }
      // });
      this.permissions = JSON.parse(localStorage.getItem('permissions'))
      let permissionFlag = false;
      if(this.permissions !== null)
      this.permissions['user-managment'].toLowerCase() == 'true'? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.location.back();
      }

      if (this.httpService.loginCheckSession()) { }
      else {
          this.httpService.showSessionError();
          
      }
  }

  ngOnInit() {
    this.userDetailForm = this.fb.group({
      userFullName: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      wpassId:['', [Validators.required]],         
    }); 

    this.userEditForm = this.fb.group({
      
      companyId: ['', [Validators.required]],
      wpassId:['', [Validators.required]]
              
    }); 

    this.fetchUserDetail();  //to display user details
  } 
  /*  *
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  setPaginator() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  
  fetchUserDetail() {
    this.spinner.show();
    this.httpService.getRequest('adminApi/v1/fetchAllUsers').subscribe(  
      res => {
        this.spinner.hide();
         this.userList= res['items'];
        //this.userList= res.response;
        console.log(this.userList);
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource<UserDetailsInterface>(this.userList);
      console.log(this.dataSource);
      this.setPaginator();
     },
      err => {
        alert("Internal issue to fetch the information");
        this.spinner.hide();
      } 
    ); 
  }

  openUserDailog() {
    this.getCurrentCompany();
    
     this.helperservice.openStaticModal('userDetailForm-Dialog');
     setTimeout(()=>{
      this.focus()
    },1000)
  }

  
  editUser(user) {
    console.log("In edit user function",user);
    this.userEditForm.setValue({
         'companyId' :  user.company.crmcompanyId,
         'wpassId' : user.userName 
    });
    this.helperservice.openStaticModal('userEditForm-Dialog');
  }
  confirmDelete(user) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        message: "Do you want to deactivate this user."
      },
      panelClass: ['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog']
    });
    this.dialogref.afterClosed().subscribe(res => {
      console.log(res);
      if(res && user.userName){
      this.deleteUser(user);
      }
    })
  }
  deleteUser(user){
   this.spinner.show();
   let data={"wpassId" : user.userName}
     this.httpService.postRequest('adminApi/v1/deleteUser',data)
     .subscribe(res=>{
      this.spinner.hide();
       if(res['status']=='ERROR' || res['responseCode']!=200) {
        this.UserrResponse="Something went wrong. Please Contact admin";
       } else {
         this.fetchUserDetail();
         this.UserrResponse="User Deleted Succesfully";
       }
       this.helperservice.openStaticModal('ConfirmDialog')
     },err=>{
       this.spinner.hide();
      this.UserrResponse="Something went wrong. Please Contact admin";
      this.helperservice.openStaticModal('ConfirmDialog')

     });
  }

  confirmUserForm() {
    if (!this.userDetailForm.valid) {
      this.helperservice.makeFormDirty(this.userDetailForm);
      return;
    }
    var msg = 'Do you confirm the new user Details ?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      this.saveNewUserDetails();
      }
    });
  }

  confirmEditForm() {
    if (!this.userEditForm.valid) {
      this.helperservice.makeFormDirty(this.userEditForm);
      return;
    }
    var msg = 'confirmed the new user Details ?';
    this.openconfromationDialog(msg);
    this.dialogref.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
      this.EditUserDetails();
      }
    });
    
  }

  openconfromationDialog(msg) {
    this.dialogref = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        message: msg
      },
      panelClass: ['no-padding', 'custom-openconfromDialog', 'custom-mat-dialog']
    });
  }

  clearUserForm(){
    this.userDetailAppl = null;
    this.userDetailForm.reset();
    this.form.resetForm();
    this.helperservice.hideModal('userDetailForm-Dialog');
  }

  clearUserEditForm(){
    this.userEditAppl = null;
    this.userEditForm.reset();
    this.form.resetForm();
    this.helperservice.hideModal('userEditForm-Dialog');
  }

  saveNewUserDetails() {
    if(!this.userDetailForm.valid) {
      this.helperservice.makeFormDirty(this.userDetailForm);
      return;
    }
   // this.spinner.show();
    var user = this.userDetailForm.value;
    console.log("User Details=",user);
     this.httpService.postRequest('adminApi/v1/addUser',user)
     .subscribe(res=>{
      //this.spinner.hide();
       if(res['status']=='ERROR'|| res['responseCode']!=200) {
        this.clearUserForm(); 
        this.UserrResponse="Something went wrong. Please Contact admin";
         this.isUserDetailError =true;
         setTimeout(()=>{
           this.isUserDetailError =false;
        },2000)
       } else {
        this.UserrResponse="User Saved Succesfully";
         console.log(this.UserrResponse);
         this.clearUserForm(); 
         this.fetchUserDetail();
       }
       this.helperservice.openStaticModal('ConfirmDialog')
     },err=>{
       //this.spinner.hide();
     });
  
  }
  redirect(){
    this.helperservice.hideModal('ConfirmDialog');
  }

  EditUserDetails() {
    if(!this.userEditForm.valid) {
      this.helperservice.makeFormDirty(this.userEditForm);
      return;
    }
   // this.spinner.show();
    var user = this.userEditForm.value;
    console.log("User Details=",user);
     this.httpService.postRequest('adminApi/v1/updateUserDetails',user)
     .subscribe(res=>{
      //this.spinner.hide();
       if(res['status']=='ERROR' || res['responseCode']!=200) {
        // this.UserrResponse= res['response'];
        this.UserrResponse="Something went wrong. Please Contact admin";
         this.isUserEditError =true;
         this.clearUserEditForm();

         setTimeout(()=>{
           this.isUserEditError =false;
        },2000)
       } else {
        //  this.UserrResponse=res['response'];
        this.UserrResponse="User updated succesfully";
         console.log(this.UserrResponse); 
         this.clearUserEditForm();
         this.fetchUserDetail();
       }
       this.helperservice.openStaticModal('ConfirmDialog')
     },err=>{
       //this.spinner.hide();
     });
  
  }

  getCurrentCompany() {
    let companyFromStore=null;
    if(localStorage.getItem('companyId')){
       companyFromStore = localStorage.getItem('companyId');
    }else{
      companyFromStore=JSON.parse(localStorage.getItem('loggedUserResponse')).company.id
    }
    let companyList= this.commonService.getCompamyList();
    if (companyFromStore !== '')
      companyList.forEach(ele => {
        if(ele.id.toString() == companyFromStore){
          this.currentCompany = ele;
        }
      });

    this.userDetailForm.get('companyId').setValue(this.currentCompany.crmcompanyId.trim())      
  }
  focus(){
    this.focusInput.nativeElement.focus();
  }
}


export  interface UserDetailsInterface {
  userFullName:string;
  userName:string;
  companyId:number;
  Role:[];
}
