import { Injectable } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as $AB from 'jquery';
import * as bootstrap from 'bootstrap';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  applianceList:any;
  sapResponse: any;

  constructor() { }

  makeFormDirty(form:FormGroup){
    form.markAllAsTouched()
  }

  openModal(id){
    $('#'+id).modal('show');
  }

  openStaticModal(id){
    $('#'+id).modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#'+id).modal('show');
  }

  hideModal(id){
    $('#'+id).modal('hide');
  }
  hideAllModal(){
    //$('.modal-backdrop').removeClass('modal-backdrop fade show');
 $('.modal').modal('hide');
  }

  
  drawer(){
    if($("#Left-Nav").hasClass("active")==true || $("#Left-Nav").hasClass("is-active")==true || $(".isa-theme-page-title-event").hasClass("active")==true){
      $('#main-event').addClass('active');
    }
  }

}

