import { HttpInterceptor, HttpRequest, HttpHandler,HttpResponse,HttpErrorResponse, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { HttpService } from "../shared/services/http.service";
import 'rxjs/add/operator/do';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import {DialogComponent} from '../dialog/dialog.component'
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';


@Injectable({
    providedIn: 'root'
  })
  
export class AuthInterceptor implements HttpInterceptor {
  dialogRef;
  count=0;  
  constructor(public spinner :NgxSpinnerService, private router :Router, private dialog:MatDialog,private _snackBar: MatSnackBar,){}
  intercept(req: HttpRequest<any>, next: HttpHandler,): Observable<any> {    
        let url = req.url;  
        if(req.headers.has('authentication')){
            return next.handle(req)
            .pipe(
              retry(1),
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                if(error.status==401){
                  localStorage.clear();
                  this.router.navigate(['/login']);
                  console.log(this.count);
                  if(this.count == 0){
                    this.openSnackBar("Session Expired, Please Login Again", "Close");
                  }
                  this.count++;
                  this.spinner.hide();
                  return EMPTY;  
                }
                return Observable.throw(error);
              })
            )
      }
    }
    openSnackBar(message, action) {
      const dialog = this._snackBar.open(message, action, {
        duration:5000,
        data: {
          icon: 'Close'
        },
        panelClass: ['isa-snackbar']
      });
      dialog.afterDismissed().subscribe(result=>{
        this.count = 0;
      })
    }


}

