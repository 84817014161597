import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { HelperService } from './../helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionService } from 'app/shared/services/permission.service';
import { CommonService } from 'app/shared/services/common.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {

  step = 0;
  refurbError: boolean = false;


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step = 0;
  }

  requestNo: string = "";
  showError: boolean = false;
  responseMsg: any;
  permissions = [];

  userLDapResonseRoles: any;
  constructor(private location: Location,private commonService: CommonService, private router: Router, private helperService: HelperService, private httpService: HttpService, private spinner: NgxSpinnerService, private permissionsService: PermissionService,
    private _snackBar: MatSnackBar,) {

    this.userLDapResonseRoles = JSON.parse(localStorage.getItem('loggedUserRoles'));
    /*
    if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')
      ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
      ||  this.userLDapResonseRoles.rolesMap.code.includes('BA') 
      ||  this.userLDapResonseRoles.rolesMap.code.includes('SP')) 
    {
    }else{
      
      // if(this.userLDapResonseRoles.rolesMap.code.includes('TECH')              
      // ||  this.userLDapResonseRoles.rolesMap.code.includes('SA')
      // ||  this.userLDapResonseRoles.rolesMap.code.includes('BA')
      // ){  
      //   this.router.navigate(['/scrap']);
      // }else{
        this.router.navigate(['/common']);
      // }
     
    }
*/
    // this.permissions = JSON.parse(localStorage.getItem('permissions'))
    this.checkPermissions()
    if (this.httpService.loginCheckSession()) { }
    else {
      this.httpService.showSessionError();

    }
  }

  @ViewChild('inputReqNo', { static: true }) inputReqNo: any;

  ngOnInit() {
    // this.inputReqNo.nativeElement.focus();
  }

  async checkPermissions() {
    let permissionFlag = false;
    const resp = this.permissionsService.permissionData?this.permissionsService.permissionData : await this.permissionsService.checkPermissions();
    let permissions = resp['items']
    if (this.permissions !== null)
      permissions['receiving'].toLowerCase() == 'true' ? permissionFlag = true : permissionFlag = false;
      if(!permissionFlag) {
        this.commonService.openSnackBar("Permission to access this page is restricted, contact Administrator", "Close"); 
        this.location.back();
      }
  }


  checkReuqestNo() {
    this.spinner.show();
    this.showError = false;
    if ((!this.requestNo.toString().startsWith('621') && !this.requestNo.toString().startsWith('624') && !this.requestNo.toString().startsWith('626')) || this.requestNo.toString().length != 10) {
      this.showError = true;
      this.spinner.hide();
      return;
    }
    if (this.requestNo.toString().startsWith('621')) {
      let postData = {
        "requestNo": this.requestNo, //this.soId
      };
      this.httpService.postRequest('checkInOperationApi/v1/retrieveSOCRequestFromSAP', postData).subscribe(
        res => {
          if (res['status'] == "SUCCESS") {
            this.spinner.hide();
            localStorage.setItem("reqInfo", JSON.stringify(res));
            window.location.href = "#/check-in-repair?requestNo=" + this.requestNo;
          }
          else {
            this.showError = true;
            this.spinner.hide();
            this.responseMsg = res['response'] || 'Something went wrong! Please try again later.';

            // this.helperService.openStaticModal('ConfirmDialog')
            
            this.openSnackBar(this.responseMsg, "Close")

          }
        },
        err => {
          this.showError = true;
          this.spinner.hide();
        }
      );

      return;
    }
    if (!this.requestNo.toString().startsWith('624') && !this.requestNo.toString().startsWith('626')) {
      this.showError = true;
      this.spinner.hide();
      return;
    }
    this.httpService.getRequest('checkInOperationApi/v1/returnOrder?sapReturnOrderId=' + this.requestNo).subscribe(
      res => {
        this.spinner.hide();
        window.location.href = "#/check-in-refurb?requestNo=" + res['requestNo'];
      },
      err => {
        this.showError = true;
        var error = err['error'];
        if (error.error.message == 'INVALID_RETURN_ORDER') {
          this.responseMsg = 'The return ' + this.requestNo + ' is not correct or was not found in the WRT. Check the Return Number or Proceed to manually create SOC’s ?';
          this.refurbError = true;
        } else {
          this.responseMsg = error.error.message || 'Something went wrong! Please try again later.';
        }
        this.helperService.openStaticModal('ConfirmDialog')
        // console.log("error occured while fetching appliances");
        this.spinner.hide();
      }
    );
  }

  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.checkReuqestNo();
    }
  }
  createSocManually() {
    this.helperService.hideModal('ConfirmDialog');
    this.router.navigate(['/soc-creation'], { queryParams: { requestNo: this.requestNo } });

  }
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 4000,
      data: {
        icon: 'Close'
      },
      panelClass: ['isa-snackbar']
    });

  }
}
