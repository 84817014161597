import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'app/dialog/dialog.component';
import { CommonService } from 'app/shared/services/common.service';
import { HttpService } from 'app/shared/services/http.service';
import * as $AB from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { PermissionService } from 'app/shared/services/permission.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  oldUser: string;
  company: any;
  companyList = [];
  isAdminFlag: boolean;
  userProfileLDapResonse:any;
  constructor(private locationForURL: Location, public activatedRoute: ActivatedRoute, public router: Router, public commonService: CommonService, private spinner: NgxSpinnerService, private httpService: HttpService,
    private permissionsService:PermissionService) { }
  // wasClicked = false;
  //   onClick() {
  //       this.wasClicked= !this.wasClicked;
  // }
  // drawTogl(){
  //   console.log("drawer work");
  //   if($("#Left-Nav").hasClass("active")==true || $("#Left-Nav").hasClass("is-active")==true){
  //     $('.Main_Tag').addClass('Main_Tag_Active');
  //   }
  //   $('.Main_Tag').addClass('Main_Tag_Active');
  // }

  ngOnInit() {
    this.userProfileLDapResonse = JSON.parse(localStorage.getItem('loggedUserResponse'));

    this.oldUser = localStorage.getItem('userId');
    let that = this;
    window.onstorage = function (e) {
      if (e != undefined && e.key == "userId" && e.oldValue == null) {
        if (e.newValue != that.oldUser) {
          window.location.reload();
        }
      }
    };
    let userRole = localStorage.getItem('UserRole');
    userRole == 'SA' ? this.isAdminFlag = true : this.isAdminFlag = false;
    // userRole == 'SA' ? this.loadCountryList() : "";
    this.loadCountryList();
    setTimeout(() => {
      this.scriptInit();
    }, 100);
  }

  ngAfterViewInit() { }

  drawInit() {
    $("#Left-Nav").stop().toggleClass("is-active");
    $('#main-event').stop().toggleClass('active');
    $('.isa-theme-page-title-event').stop().toggleClass('active');
    // $('.isa-theme-card-container').stop().toggleClass('Main_Tag_Active');
  }

  scriptInit() {
    // // cache the element
    // var $navBar = $('.header');
    // // find original navigation bar position
    // var navPos = $navBar.offset().top;
    // // on scroll
    // $(window).scroll(function() {
    //     // get scroll position from top of the page
    //     var scrollPos = $(this).scrollTop();
    //     // check if scroll position is >= the nav position
    //     if (scrollPos >= navPos) {
    //         $navBar.addClass('fixed');
    //     } else {
    //         $navBar.removeClass('fixed');
    //     }
    // });
  }

  drawMobInit() {
    $('#main-event').stop().toggleClass('active');
    $("#Left-Nav").stop().toggleClass("active");
    $('.sidenav-overlay').toggleClass('active');
  }

  logout(url) {
    if (url == 'login') {
      localStorage.clear();
      this.permissionsService.permissionData=null;
    }
    this.router.navigate([url]);
  }

  loadCountryList() {
    try {
      this.spinner.show();
      this.httpService.getCompanyList("loginOperationApi/v1/fetchAllCompany").subscribe(
        res => {
          if (res['status'] == 'SUCCESS') {
            this.companyList = res['response']
            this.setcompanyInHeader(this.companyList);
            this.commonService.setCompamyList(res['response']);
          } else {
            this.commonService.openSnackBar("Something went wrong, contact Administrator", "Close");
          }
        },
        err => {
          // alert("Internal issue header to fetch the information");
          this.spinner.hide();
        }
      );
    } catch (error) {
    }
  }

  setCompanyinStore() {
    this.permissionsService.permissionData=null;
    localStorage.setItem('companyId', this.company);
    let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
        this.permissionsService.getCompanyId();
      });  
  }

  setcompanyInHeader(companyList) {
    let companyFromStore = localStorage.getItem('companyId');
    if (companyFromStore !== '')
      companyList.forEach(ele => {
        if(ele.id.toString() == companyFromStore)
        this.company = ele.id;
      });
  }
}
