// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appAutofocus]'
// })
// export class AutofocusDirective {

//   constructor() { }

// }
import { OnInit, ElementRef, Renderer, Input, Directive, HostListener, AfterViewInit } from '@angular/core';

@Directive({ selector: '[focusMe]' })
export class AutofocusDirective implements OnInit,AfterViewInit {

    @Input('focusMe') isFocused: boolean;
    constructor(private hostElement: ElementRef, private renderer: Renderer) { }

    ngOnInit() {
      console.log("ngOnInit:::directive called")

      this.focus();
    }
    ngAfterViewInit() {
      console.log("ngAfterViewInit:::directive called")
      setTimeout(()=>{
        this.focus();
      },500)
    }


    focus(){
      console.log("isFocused::" +this.isFocused)
      console.log("isFocused::" +this.hostElement)
      if (this.isFocused) {
        this.hostElement.nativeElement.focus();
          // this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
      }
    }

  @HostListener('click', ['$event'])
  onClick(btn) {
    console.log('click', btn);
    this.focus();
  }
  // @HostListener('click', ['$event'])
  // onClick(btn) {
  //   console.log('click', btn);
  //   this.focus();
  // }
}